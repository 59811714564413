import React from 'react';

export default function NewUserSuccess() {

    return <>
        Thanks for letting us know!
    </>


}

