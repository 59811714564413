export function signupUser(id, userDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/signup/` + id, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userDetails),
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}


export function inviteUser(id, userDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/invite/` + id, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userDetails),
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}
