import React, { useEffect, useState } from 'react';
import { getFinances, submitBank} from '../../services/finances';
import Link from "./Link/Link";
import getSymbolFromCurrency from 'currency-symbol-map'
import {DotsHorizontalIcon} from "@heroicons/react/outline";
import ScaleLoader from "react-spinners/ScaleLoader";
import {submitNewPost} from "../../services/posts";
import Dropdown from "./ListBox/ListBox";
import { parseISO, format } from 'date-fns';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Finances({setErrorMessage, setMobileNavigation}) {
    const [finances, setFinances] = useState({
        canConnectAccount: false,
        data: {
            accounts:[],
            transactions: [],
        },
        banks: [],
    });
    const [loading, setLoading ] =useState(true)
    const [bank, setBank ] =useState("")
    setMobileNavigation("Finances")
    useEffect(() => {
        let mounted = true;
        setErrorMessage("")
        try {
            getFinances()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setFinances(resp.body);
                        setLoading(false)
                    }
                } else {
                    setErrorMessage("Unable to load finances");
                }
            })
        } catch (error) {
            setErrorMessage("Unable to load finances");
        }
        return () => mounted = false;
    }, [])




    return(
        loading ?
            <main className="lg:col-span-10 xl:col-span-7">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-center items-center mt-16">
                        <ScaleLoader
                            color={"#ff6600"}
                            loading={loading}
                            size={100}
                        />
                    </div>
                </div>
            </main>
        :
        finances.data.transactions.length > 0 ?
        <>
            <main className="lg:col-span-12 xl:col-span-7 mb-4 order-2 xl:order-1  mx-4 sm:mx-0">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col">
                        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle">
                                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                    <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                                        <thead className="bg-gray-50">
                                        <tr>
                                            {finances.data.accounts.length > 1 ?
                                                <th
                                                    scope="col"
                                                    className="hidden sm:block top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                                >
                                                    Account
                                                </th>
                                                :
                                                <></>
                                            }
                                            <th
                                                scope="col"
                                                className=" top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                            >
                                                Description
                                            </th>
                                            <th
                                                scope="col"
                                                className=" top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                            >
                                                Amount
                                            </th>
                                            <th
                                                scope="col"
                                                className="hidden sm:block top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                            >
                                                Date
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                        {finances.data.transactions.map((transaction, transactionIdx) => (

                                            Number(transaction.amount) < 0 ?
                                            <tr key={transactionIdx}  >
                                                {finances.data.accounts.length > 1 ?

                                                    <td
                                                        className={classNames(
                                                            'whitespace-nowrap hidden sm:block  py-4 pl-4 pr-3 text-sm font-medium text-gray-900'
                                                        )}
                                                    >
                                                        {transaction.account}
                                                    </td>
                                                    :
                                                    <></>
                                                }
                                                <td
                                                    className={classNames(
                                                        ' py-4 pl-4 pr-3 text-sm font-medium text-gray-900'
                                                    )}
                                                >
                                                    {transaction.description} {transaction.isPending ? <span className="text-orange-500">(Pending)</span> : <></>}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        'whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell'
                                                    )}
                                                >
                                                    {Number(transaction.amount) < 0 ?
                                                        <span className="text-gray-700">{(Number(transaction.amount)).toFixed(2)}</span>
                                                        :
                                                        <span className="font-bold text-green-700">+{Number(transaction.amount).toFixed(2)}</span>
                                                    }
                                                </td>
                                                <td
                                                    className={classNames(
                                                        'whitespace-nowrap  hidden sm:block px-3 py-4 text-sm text-gray-500'
                                                    )}
                                                >
                                                    {parseISO(transaction.date).toLocaleString(navigator.language)}
                                                </td>
                                            </tr>
                                                :
                                                <tr key={transactionIdx} className={"bg-green-50"}  >
                                                    {finances.data.accounts.length > 1 ?

                                                        <td
                                                            className={classNames(
                                                                'whitespace-nowrap hidden sm:block  py-4 pl-4 pr-3 text-sm font-medium text-gray-900'
                                                            )}
                                                        >
                                                            {transaction.account}
                                                        </td>
                                                        :
                                                        <></>
                                                    }
                                                    <td
                                                        className={classNames(
                                                            ' py-4 pl-4 pr-3 text-sm font-medium text-gray-900'
                                                        )}
                                                    >
                                                        {transaction.description} {transaction.isPending ? <span className="text-orange-500">(Pending)</span> : <></>}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            'whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell'
                                                        )}
                                                    >
                                                        {Number(transaction.amount) < 0 ?
                                                            <span className="text-gray-700">{(Number(transaction.amount)).toFixed(2)}</span>
                                                            :
                                                            <span className="font-bold text-green-700">+{Number(transaction.amount).toFixed(2)}</span>
                                                        }
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            'whitespace-nowrap  hidden sm:block px-3 py-4 text-sm text-gray-500'
                                                        )}
                                                    >
                                                        {parseISO(transaction.date).toLocaleString(navigator.language)}
                                                    </td>
                                                </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
                <aside className="lg:col-span-12 xl:col-span-3 order-1 xl:order-2 mb-4  mx-4 sm:mx-0" >
                    {finances.data.accounts.map((account, accountIdx) => (
                        <div key={accountIdx} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                            <dt className=" mb-4 font-medium text-gray-900 truncate">{account.name}</dt>
                            <span className="text-2xl  text-gray-900 float-left">{getSymbolFromCurrency(account.currency)}</span>
                            <dd className="text-2xl  text-gray-900">{Number(account.amount).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</dd>
                        </div>
                    ))}



                </aside>

        </>
            :

            finances.canConnectAccount === true ?
            <>
                <main className="lg:col-span-12 xl:col-span-8 mb-4  mx-4 sm:mx-0">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="flex flex-col">
                            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle">
                                    <Dropdown setErrorMessage={setErrorMessage}/>
                                </div>
                            </div>
                        </div>
                    </div>
        </main>
                </>
                :
                <>
                    <main className="lg:col-span-12 xl:col-span-8">
                        <div className="w-2/3 m-auto text-center items-center text-4xl mt-12">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="text-gray-300 w-32 m-auto">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            <div className={"mt-16 mb-32 text-gray-300 font-medium"}>
                                Nothing to see here
                            </div>
                        </div>
                    </main>
                </>
    )
}