import {useState} from 'react';

export default function useWizard() {
    const getWizard = () => {
        const wizardString = localStorage.getItem('wizard');
        try {
            const userWizard = JSON.parse(wizardString);
            return userWizard
        } catch (e) {
            return false
        }
    };

    const [wizard, setWizard] = useState(getWizard());

    const saveWizard = userWizard => {
        localStorage.setItem('wizard', JSON.stringify(userWizard));
        setWizard(userWizard);
    };

    return {
        setWizard: saveWizard,
        wizard
    }
}
