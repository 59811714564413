import React, {useEffect, useState} from 'react';
import {UserIcon} from "@heroicons/react/outline";
import {getPassword, submitPasswordChange} from "../../services/profile";


import bcrypt from "bcryptjs-react";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ChangePassword({setUser, setErrorMessage, setMobileNavigation}) {

    setMobileNavigation("Change Password")
    const [password, setPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatNewPassword, setRepeatNewPassword] = useState("");

    const [currentPasswordsMatch, setCurrentPasswordsMatch] = useState(false);
    const [newPasswordsMatch, setNewPasswordsMatch] = useState(false);

    useEffect(() => {
        let mounted = true;
        getPassword()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setPassword(resp.body.password)
                    }
                }
            });
        return () => mounted = false;
    }, []);


    const handleCurrentPasswordChange = async e => {
        setCurrentPassword(e.target.value);
        setCurrentPasswordsMatch(bcrypt.compareSync(e.target.value, password))
    };

    const handleNewPasswordChange = async e => {
        setNewPassword(e.target.value);
        setNewPasswordsMatch(e.target.value === repeatNewPassword)
    };

    const handleRepeatPasswordChange = async e => {
        setRepeatNewPassword(e.target.value);
        setNewPasswordsMatch(newPassword === e.target.value)
    };

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")

            const {status} = await submitPasswordChange({
                currentPassword,
                newPassword,
                repeatNewPassword,
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to update password")
            }
        } catch (error) {
            setErrorMessage("Unable to update password")
        }

    };

    return <main className="lg:col-span-9 xl:col-span-10">
        <div className="sticky top-4 space-y-4 ">
            <section aria-labelledby="trending-heading">
                <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                    <div className="p-4 sm:p-6">
                        <div id="trending-heading" className="hidden sm:block font-normal text-xl sm:font-medium text-gray-900">
                            <UserIcon
                                className={classNames(
                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                )}

                                aria-hidden="true"
                            />
                            <span className="truncate">Change Password</span>
                        </div>
                            <div className="space-y-6 sm:space-y-5 mt-4">
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="current"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Current password
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            onChange={handleCurrentPasswordChange}
                                            value={currentPassword}
                                            type="password"
                                            name="current"
                                            required={true}
                                            id="current"
                                            autocomplete="current-password"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="new"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        New password
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            onChange={handleNewPasswordChange}
                                            value={newPassword}
                                            type="password"
                                            required={true}
                                            name="new"
                                            id="new"
                                            autoComplete="new-password"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="repeat"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Repeat new password
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            onChange={handleRepeatPasswordChange}
                                            value={repeatNewPassword}
                                            type="password"
                                            required={true}
                                            name="repeat"
                                            id="repeat"
                                            autoComplete="new-password"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="submit" className="block text-sm font-medium text-gray-700">

                                    </label>
                                    <div className="group flex grid-cols-2 gap-2 mt-2">

                                    <PrimaryButton
                                            disabled={!currentPasswordsMatch || !newPasswordsMatch}
                                            onClick={e => handleSubmit(e)}
                                        >
                                            Save
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            {/*another section*/}
        </div>

    </main>;
}