import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, Transition} from "@headlessui/react";
import {LockClosedIcon, PhoneIcon} from "@heroicons/react/outline";
import { doConfirmRecoverMFA, recoverMFA} from "../../../services/profile";

async function loginMFAUser(credentials) {
    return fetch(`${process.env.REACT_APP_API}/v1/login/mfa`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export default function MFA({setToken, setGroups, setWizard, setNewUser, setName, setUser, setNavigation, setErrorMessage, setSteps, email, password, setRunTour, setCanRenew, setExpired, captchaRef, useCaptcha}) {
    const [code, setCode] = useState("");
    const [recoveryVisibility, setRecoveryVisibility] = useState(false)
    const [words, setWords] = useState(['', '', '', '', '', '', '', '', '', '', '', '']);
    const [qrCode, setQRCode] = useState("");
    const [recoveryPhrase, setRecoveryPhrase] = useState("");
    const [confirmMFA, setConfirmMFA] = useState("");
    const [canConfirmMFA, setCanConfirmMFA] = useState(false);

    function handleChange(event, index) {
        const newWords = [...words];
        newWords[index] = event.target.value.trim();
        setWords(newWords);
    }

    const handleConfirmMFAChange = async e => {
        setConfirmMFA(e.target.value);
        setCanConfirmMFA(e.target.value.length === 6)
    };


    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")

            if (useCaptcha) {
                let token = captchaRef.current.getValue();
                if(token) {
                    const {status, body} = await loginMFAUser({
                        email,
                        password,
                        code,
                        token,
                    });

                    if (status === 200) {
                        if (body.length === 1) {
                            setToken(body[0]);
                            setWizard(body[0].runWizard);
                            setNewUser(body[0].runNewUser);
                            setRunTour(body[0].runNewUser);
                            setName(body[0].managementGroupName);
                            setUser(body[0].user);
                            setNavigation(body[0].navigation);
                            setSteps(body[0].steps);
                            setExpired(body[0].expired);
                            setCanRenew(body[0].canRenew);
                        } else {
                            setGroups(body)
                        }
                    } else {
                        setErrorMessage("Invalid authentication code")
                    }
                }
            } else {
                const {status, body} = await loginMFAUser({
                    email,
                    password,
                    code,
                });

                if (status === 200) {
                    if (body.length === 1) {
                        setToken(body[0]);
                        setWizard(body[0].runWizard);
                        setNewUser(body[0].runNewUser);
                        setRunTour(body[0].runNewUser);
                        setName(body[0].managementGroupName);
                        setUser(body[0].user);
                        setNavigation(body[0].navigation);
                        setSteps(body[0].steps);
                        setExpired(body[0].expired);
                        setCanRenew(body[0].canRenew);
                    } else {
                        setGroups(body)
                    }
                } else {
                    setErrorMessage("Invalid authentication code")
                }
            }
        } catch(error) {
            setErrorMessage("Invalid authentication code")
        }
    };

    const handleMFAConfirm = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")

            const {status} = await doConfirmRecoverMFA({
                confirmMFA: confirmMFA,
                email: email,
                password: password,
            });

            if (status === 200) {
                setRecoveryVisibility(false)
            } else {
                setErrorMessage("Invalid authentication code")
            }
        } catch (error) {
            setErrorMessage("Unable to validate authentication code")
        }
    }

    const handleRecovery = async e => {
        e.preventDefault();
        const recoveryPhrase = words.join(' ')

        try {
            setErrorMessage("")

            const {status, body} = await recoverMFA({
                recoveryPhrase: recoveryPhrase,
                email: email,
                password: password,
            });

            if (status === 200) {
                setQRCode(body.qrCode)
                setRecoveryPhrase(body.recoveryPhrase)
            } else {
                setErrorMessage("Unable to validate recovery phrase")
            }
        } catch (error) {
            setErrorMessage("Unable to validate recovery phrase")
        }
    }

        return <>
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="text-orange-500 text-center" style={{fontSize: "4em", fontFamily: `'Fortika', sans-serif`}}>
                    votria
                </div>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <h2 className="text-center text-2xl font-normal tracking-tight text-gray-900 mb-8">Two-factor authentication</h2>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            Open your two-factor authentication app (TOTP) to view your authentication code
                        </div>
                        <div>
                            <div className="mt-1">
                                <input
                                    onChange={e => setCode(e.target.value)}
                                    id="code"
                                    name="code"
                                    type="text"
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">

                            <div className="text-sm">
                                <button onClick={() => {setRecoveryVisibility(true)}}
                                   className="font-medium text-orange-500 hover:text-orange-500 hover:cursor-pointer">
                                    Use a recovery code instead
                                </button>
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                            >
                                Verify
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>

        <Transition.Root show={recoveryVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setRecoveryVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 overflow-y-auto w-full">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                {qrCode === "" ?
                                    <>
                                        <div>
                                            <div
                                                className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                                <LockClosedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-5">
                                                <Dialog.Title as="h3"
                                                              className="text-lg font-medium leading-6 text-gray-900">
                                                    Enter your recovery phrase
                                                </Dialog.Title>
                                                <div className="mt-2 m-auto">
                                                    {words.map((word, index) =>
                                                        <input
                                                            type="text"
                                                            id={`word-${index}`}
                                                            key={index}
                                                            value={word}
                                                            onChange={(event) => handleChange(event, index)}
                                                            required
                                                            className="w-full mb-4 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm disabled:bg-orange-300"
                                                onClick={e => handleRecovery(e)}
                                            >
                                                Reset MFA
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                                onClick={e => setRecoveryVisibility(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div
                                            className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                            <PhoneIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                        </div>
                                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3">
                                            <div>Write down this recovery phrase, you will need if you lose access to your authenticator:</div>
                                            <div>{recoveryPhrase}</div>
                                            <div>Scan the QR code below into your authenticator app:</div>
                                            <img alt={""} className={"m-auto"} src={`data:image/png;base64,${qrCode}`}></img>
                                            <div>Enter the TOTP from your authenticator app to enable MFA:</div>
                                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3">
                                                <input
                                                    onChange={handleConfirmMFAChange}
                                                    value={confirmMFA}
                                                    type="text"
                                                    className="w-auto m-auto max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                />
                                            </div>
                                            <div
                                                className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                <button
                                                    type="button"
                                                    disabled={!canConfirmMFA}
                                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm disabled:bg-orange-300"
                                                    onClick={e => handleMFAConfirm(e)}
                                                >
                                                    Confirm
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                                    onClick={e => setRecoveryVisibility(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}


MFA.propTypes = {
    setToken: PropTypes.func.isRequired,
    setGroups: PropTypes.func.isRequired,
    setWizard: PropTypes.func.isRequired,
    setNewUser: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    setNavigation: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired
};
