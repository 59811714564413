import React, {Fragment, useState} from "react";
import {ExclamationCircleIcon, ThumbDownIcon, ThumbUpIcon, TrashIcon} from "@heroicons/react/outline";
import {deleteAgendaItem, voteAgendaItem} from "../../services/meetings";
import {Dialog, Transition} from "@headlessui/react";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function AgendaItem({post}) {

    const [deletePostVisibility, setDeletePostVisibility] = useState(false);

    const handleDeleteAgendaItem = async (e, postID) => {
        e.preventDefault();
        const {status} = await deleteAgendaItem(postID);

        if (status === 200) {
            setDeletePostVisibility(false);
            window.location.reload()
        }
    };

    const handleVote = async (e, score) => {
        e.preventDefault();
        const {status} = await voteAgendaItem(post.id, score);

        if (status === 200) {
            window.location.reload()
        }
    };

    return <div className="flow-root">
        <article aria-labelledby={'question-title-' + post.id} >
            {post.score > 0 ?
                <h1 id={post.id} className="text-md mt-2 font-medium text-gray-900 mb-4">
                    {post.title}<span className="ml-2 text-xs inline-flex items-center leading-sm px-2 py-1 bg-green-200 text-green-700 rounded-full mr-2">+{post.score}</span>
                </h1> :
                post.score < 0 ?
                    <h1 id={post.id} className="text-md mt-2 font-medium text-gray-900 mb-4">
                        {post.title}<span className="ml-2 text-xs inline-flex items-center leading-sm px-2 py-1 bg-red-200 text-red-700 rounded-full mr-2">-{post.score}</span>
                    </h1> :
                    <h1 id={post.id} className="text-md mt-2 font-medium text-gray-900 mb-4">
                        {post.title} <span className={"text-xs inline-flex items-center leading-sm px-2 py-1 bg-orange-200 text-orange-700 rounded-full mr-2"}>{post.score}</span>
                    </h1>
            }
            <p className="mt-4 text-md leading-8 text-gray-800">
                {post.content}
            </p>
            {post.canDelete && post.score <= 0 ?
                <>
                    <span className="inline-flex text-xs hover:cursor-pointer mt-3"
                          onClick={e => setDeletePostVisibility(!deletePostVisibility)}>
                        <TrashIcon className="h-4 w-4 text-gray-500 mr-2" aria-hidden="true"/>
                        Delete
                    </span>
                </> : <></>
            }

            {post.plusOne ?
                <span className="inline-flex text-xs hover:cursor-pointer mt-3 text-orange-700"
                      onClick={e => handleVote(e, 0)}>
                    <ThumbUpIcon
                        className={classNames(
                            "h-4 w-4 ml-2 mr-2 text-orange-700"
                        )}

                        aria-hidden="true"
                    />
            </span>
                :
                <span className="inline-flex text-xs hover:cursor-pointer mt-3" onClick={e => handleVote(e, 1)}>
                <ThumbUpIcon
                    className={classNames(
                        'h-4 w-4 text-gray-500 ml-2 mr-2'
                    )}

                    aria-hidden="true"
                />
            </span>
            }

            {post.minusOne ?

                <span className="inline-flex text-xs hover:cursor-pointer mt-3 text-orange-700"
                      onClick={e => handleVote(e, 0)}>
                    <ThumbDownIcon
                        className={classNames(
                            "h-4 w-4 text-gray-500 mr-2 text-orange-700"
                        )}

                        aria-hidden="true"
                    />
            </span>

                :
                <span className="inline-flex text-xs hover:cursor-pointer mt-3" onClick={e => handleVote(e, -1)}>
                    <ThumbDownIcon
                        className={classNames(
                            "h-4 w-4 text-gray-500 mr-2"
                        )}

                        aria-hidden="true"
                    />
            </span>

            }
        </article>

        <Transition.Root show={deletePostVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setDeletePostVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 overflow-y-auto w-full">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                        <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Delete agenda item?
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {post.content}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                        onClick={e => handleDeleteAgendaItem(e, post.id)}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={e => setDeletePostVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

    </div>
}