import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import MFA from "./MFA/MFA";
import {getLoginForm} from "../../services/signup";
import ReCAPTCHA from 'react-google-recaptcha';
import ScaleLoader from "react-spinners/ScaleLoader";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

async function loginUser(credentials) {
    return fetch(`${process.env.REACT_APP_API}/v1/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export default function Login({setToken, setGroups, setWizard, setNewUser, setName, setUser, setNavigation, setErrorMessage, setSteps, setRunTour, setExpired, setCanRenew}) {
    const [redirect, setRedirect] = useState(0);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(true);
    const [useCaptcha, setUseCaptcha] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const captchaRef = useRef(null);

    useEffect(() => {
        let mounted = true;
        getLoginForm()
            .then(resp => {
                if (mounted) {
                    if (resp.status === 423) {
                        setBlocked(true);
                    } else if (resp.status !== 200) {
                        setUseCaptcha(true);
                    }
                    setLoading(false);
                }
            });
        return () => mounted = false;
    }, []);

    const handleSubmit = async e => {
        e.preventDefault();
        if (useCaptcha) {
            let token = captchaRef.current.getValue();
            if(token) {
                try {
                    setErrorMessage("")

                    const {status, body} = await loginUser({
                        token,
                        email,
                        password
                    });

                    if (status === 200) {
                        if (body.length === 1) {
                            setToken(body[0]);
                            setWizard(body[0].runWizard);
                            setNewUser(body[0].runNewUser);
                            setRunTour(body[0].runNewUser);
                            setName(body[0].managementGroupName);
                            setUser(body[0].user);
                            setNavigation(body[0].navigation);
                            setSteps(body[0].steps);
                            setExpired(body[0].expired);
                            setCanRenew(body[0].canRenew);
                        } else {
                            setGroups(body)
                        }
                    } else if (status === 303) {
                        setRedirect(1)
                    } else {
                        setUseCaptcha(true);
                        setErrorMessage("Unable to log in")
                    }
                } catch (error) {
                    setErrorMessage("Unable to log in")
                }
            } else {
                setErrorMessage("Please complete the captcha")
            }
        } else {
            try {
                setErrorMessage("")

                const {status, body} = await loginUser({
                    email,
                    password
                });

                if (status === 200) {
                    if (body.length === 1) {
                        setToken(body[0]);
                        setWizard(body[0].runWizard);
                        setNewUser(body[0].runNewUser);
                        setRunTour(body[0].runNewUser);
                        setName(body[0].managementGroupName);
                        setUser(body[0].user);
                        setNavigation(body[0].navigation);
                        setSteps(body[0].steps);
                        setExpired(body[0].expired);
                        setCanRenew(body[0].canRenew);
                    } else {
                        setGroups(body)
                    }
                } else if (status === 303) {
                    setRedirect(1)
                } else {
                    setUseCaptcha(true);
                    setErrorMessage("Unable to log in")
                }
            } catch (error) {
                setErrorMessage("Unable to log in")
            }
        }

    };

    if (redirect === 1) {
        return <MFA setToken={setToken} setGroups={setGroups} setName={setName} setUser={setUser} setNewUser={setNewUser} setWizard={setWizard} setNavigation={setNavigation} setErrorMessage={setErrorMessage} setSteps={setSteps} setRunTour={setRunTour} setExpired={setExpired} setCanRenew={setCanRenew} email={email} password={password} useCaptcha={useCaptcha} captchaRef={captchaRef}/>
    }


    return loading ?
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </div>
        : blocked ?
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-center items-center mt-16">
                        Too many failed login attempts, try again later
                    </div>
                </div>
            </div>
            :
            <>
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="text-orange-500 text-center" style={{fontSize: "4em", fontFamily: `'Fortika', sans-serif`}}>
                    votria
                </div>

            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

                <div className="bg-white py-8 px-4 sm:shadow sm:rounded-lg sm:px-10 mx-4 sm:mx-0">
                    <form className="space-y-6" onSubmit={handleSubmit}>

                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setEmail(e.target.value)}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setPassword(e.target.value)}
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            {useCaptcha &&  <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef}/>}
                        </div>

                        <div className="flex items-center justify-between">

                            <div>
                                <a href="/forgot-password"
                                   className="text-sm font-medium text-orange-500 hover:text-orange-500">
                                    Forgot your password?
                                </a>
                            </div>
                        </div>

                        <div>
                            <PrimaryButton>
                                Sign in
                            </PrimaryButton>
                        </div>
                    </form>
                    <div className={"text-center mt-4 pb-4"}>
                        <a href={"/terms"} target={"_blank"} className="text-sm font-medium text-orange-500 hover:text-orange-500 float-left">Terms and conditions</a>
                        <a href={"/privacy"} target={"_blank"} className="text-sm font-medium text-orange-500 hover:text-orange-500 float-right">Privacy policy</a>
                    </div>
                </div>
            </div>
        </div>
    </>
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
    setGroups: PropTypes.func.isRequired,
    setWizard: PropTypes.func.isRequired,
    setNewUser: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    setNavigation: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired
};
