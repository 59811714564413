import {useState} from 'react';

export default function useExpired() {
    const getExpired = () => {
        const expiredString = localStorage.getItem('expired');
        try {
            const userExpired = JSON.parse(expiredString);
            return userExpired
        } catch (e) {
            return false
        }
    };

    const [expired, setExpired] = useState(getExpired());

    const saveExpired = userExpired => {
        localStorage.setItem('expired', JSON.stringify(userExpired));
        setExpired(userExpired);
    };

    return {
        setExpired: saveExpired,
        expired
    }
}
