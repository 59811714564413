import React, {useState, useEffect} from 'react';
import {
    QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import {contactUs, getHelp} from "../../services/posts";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Help({setErrorMessage, setMobileNavigation}) {
    const [content, setContent] = useState("");
    const [agree, setAgree] = useState(false);
    const [loading, setLoading ] =useState(true)
    const [canContact, setCanContact ] =useState(false)

    setMobileNavigation("Help")
    useEffect(() => {
        let mounted = true;
        getHelp()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setCanContact(resp.body.canContact);
                        setLoading(false)
                    }
                }
            });
        return () => mounted = false;
    }, []);

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")
            const {status} = await contactUs({
                message: content,
                timestamp: new Date().toISOString(),
            });

            if (status === 200) {
                window.location.href = "/help?success=true"
            } else {
                setErrorMessage("Unable to submit new post")
            }
        } catch (error) {
            setErrorMessage("Unable to submit new post")
        }
    };

    return(
        loading ?
            <main className="lg:col-span-12 xl:col-span-7">
                <div className="sticky top-4 space-y-4 ">
                    <section aria-labelledby="trending-heading">
                        <div className="bg-white rounded-lg shadow ">
                            <div className="p-6">
                                <div id="trending-heading" className="text-base font-medium text-gray-900">
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            :
        <main className="lg:col-span-12 xl:col-span-7">
            <div className="sticky top-4 space-y-4 ">
                <section aria-labelledby="trending-heading">
                    <div className="bg-white rounded-lg shadow  mx-4 sm:mx-0">
                        <div className="p-4 sm:p-6">
                            <div id="trending-heading" className="hidden sm:block font-normal text-xl sm:font-medium text-gray-900">
                                <QuestionMarkCircleIcon
                                    className={classNames(
                                        'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                    )}

                                    aria-hidden="true"
                                />
                                <span className="truncate">Help</span>
                            </div>

                            <article aria-labelledby={'question-title-help'}>
                                {canContact ?
                                    <>
                                    <h1 className="text-base font-medium text-gray-900 mt-2 sm:mt-6">
                                        Terms and conditions
                                    </h1>
                                    <p className="mt-6 text-gray-800">
                                        You can read our terms and conditions <a href={'/terms'} className={"text-orange-500"} target={"_blank"}>here</a>
                                    </p>
                                    <h1 className="text-base font-medium text-gray-900 mt-6">
                                        Privacy policy
                                    </h1>
                                    <p className="mt-6 text-gray-800">
                                        You can read our privacy policy <a href={'/privacy'} className={"text-orange-500"} target={"_blank"}>here</a>
                                    </p>
                                    <h1 className="text-base font-medium text-gray-900 mt-6">
                                        Contact us
                                    </h1>
                                    <p className="mt-6 text-gray-800">
                                        You can contact us with any queries or problems using the form below, and we'll
                                        get back to you via email as soon as possible.
                                    </p>
                                    <div className={"mt-4"}>
                                    <form onSubmit={handleSubmit}>
                                    <textarea
                                    rows={5}
                                    name="content"
                                    id="content"
                                    required={true}
                                    value={content}
                                    onChange={e => setContent(e.target.value)}
                                    className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                                    placeholder="Add your message..."
                                    />
                                    <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                    <input
                                    onChange={e => setAgree(e.target.checked)}
                                    id="agree"
                                    name="agree"
                                    type="checkbox"
                                    required
                                    className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                                    />
                                    <label htmlFor="agree" className="ml-2 block text-xs text-gray-900">
                                    I agree to the <a href={"/privacy"} target={"_blank"} className={"text-orange-700"}>privacy policy</a> and <a href={"/terms"} target={"_blank"} className={"text-orange-700"}>terms and conditions</a>
                                    </label>
                                    </div>
                                    </div>

                                        <div className={"mt-4"}><PrimaryButton
                                    disabled={!agree}>
                                    Send message
                                    </PrimaryButton></div>
                                    </form>
                                    </div>
                                    </>
                                    :
                                    <></>
                                }

                            </article>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    )
}