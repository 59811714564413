import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {listPreviousMeetings} from "../../../services/meetings";
import {ClockIcon} from "@heroicons/react/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ListPreviousMeetings({setMobileNavigation}) {
    const [meetings, setMeetings] = useState([]);
    setMobileNavigation("Previous Meetings")
    useEffect(() => {
        let mounted = true;
        listPreviousMeetings()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setMeetings(resp.body);
                    }
                } else {
                    window.location.href = "/"
                }
            });
        return () => mounted = false;
    }, []);

    return <main className="lg:col-span-12 xl:col-span-10">
        <div className="sticky top-4 space-y-4 ">
            <section aria-labelledby="trending-heading">
                <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                    <div className="sm:p-4 p-6">
                        <div id="trending-heading" className="text-base font-medium text-gray-900">
                            <ClockIcon
                                className={classNames(
                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                )}

                                aria-hidden="true"
                            />
                            <span className="truncate">Previous meetings</span>
                        </div>
                        <div className="mt-6 flow-root">
                            <ul className="space-y-6">
                                {meetings.map(meeting => <li key={meeting.id}>
                                    <NavLink to={"/meetings/view/" + meeting.id}
                                    >
                                        <article aria-labelledby={'question-title-' + meeting.id}>
                                            <div>
                                                <h2 id={'question-title-' + meeting.id}
                                                    className="mt-4 text-base font-medium text-gray-900">
                                                    {meeting.date}
                                                </h2>
                                            </div>
                                        </article>
                                    </NavLink>
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
}
