import {ExclamationCircleIcon, TrashIcon} from "@heroicons/react/outline";
import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useState} from "react";
import {deleteCaseUpdate} from "../../../services/cases";

export default function Comment({update, caseID, canCreate}) {
    const processString = require('react-process-string');

    const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [content, setContent] = useState(update.content);


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            let config = [{
                regex: /([\w.]+)@(\w+)\.(\w+)/gim,
                fn: (key, result) => <span key={key}>
                                 <a className={"text-blue-700"} target="_blank" rel={"noreferrer"}
                                    href={`mailto:${result[1]}@${result[2]}.${result[3]}`}>{result[1]}@{result[2]}.{result[3]}</a>
                             </span>
            },
                {
                    regex: /(http|https):\/\/(\S+)/gim,
                    fn: (key, result) => {
                        identifyContentType(result[0]);
                        if (isImage) {
                            return <span key={key}>
                      <img alt="" className="mt-4 mb-4" src={result[0]}/>
                    </span>
                        } else if (isVideo) {
                            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                            var match = result[0].match(regExp);
                            return <iframe
                                style={{
                                    left: 0,
                                    top: 0,
                                    width: "100%",
                                    aspectRatio: "16 / 9",
                                    position: "relative",
                                    marginTop: 8
                                }}
                                key={key}
                                src={`https://www.youtube.com/embed/` + match[7]}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            />
                        } else {
                            return <span key={key}>
                        <a className={"text-blue-700"} target="_blank" rel="noreferrer" href={result[0]}>{result[2]}</a>
                    </span>
                        }
                    }
                },
                {
                    regex: /(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
                    fn: (key, result) => <span key={key}>
                                 <a className={"text-blue-700"} target="_blank" rel={"noreferrer"}
                                    href={`//${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                             </span>
                }];

            setContent(processString(config)(update.content));
            // setContent(update.content);
        }
        return () => mounted = false;
    }, [setContent, processString, update.content, isImage, isVideo]);

    const identifyContentType = function (url) {
        const pURL = new URL(url);
        if (pURL.hostname.endsWith('youtube.com')) {
            setIsVideo(true)
        } else {
            fetch(url, {method: 'HEAD'}).then(res => {
                const contentType = res.headers.get('Content-Type');
                if (contentType.startsWith('image')) {
                    setIsImage(true)
                } else if (contentType.startsWith('video')) {
                    setIsVideo(true)
                }
            })
        }
    };

    const handleDeleteComment = async (e, commentID) => {
        e.preventDefault();
        const {status} = await deleteCaseUpdate(caseID, commentID);

        if (status === 200) {
            setDeleteModalVisibility(false);
            window.location.reload()
        }
    };

    return <li className="mb-10 ml-4">
        <div
            className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
        <time className="mb-4 text-sm font-normal leading-none text-gray-600">{update.time}</time>
        <p className="mb-4 text-base font-normal text-gray-900">{content}</p>

        {canCreate ?
            <span className="inline-flex text-xs hover:cursor-pointer mt-3"
                  onClick={e => setDeleteModalVisibility(!deleteModalVisibility)}>
                                                    <TrashIcon className="h-4 w-4 text-gray-500 mr-2 ml-4"
                                                               aria-hidden="true"/> Delete
                                                </span>
            :
            <></>
        }
        <Transition.Root show={deleteModalVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setDeleteModalVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 overflow-y-auto">>
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                        <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Delete comment?
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {content}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                        onClick={e => handleDeleteComment(e, update.id)}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={e => setDeleteModalVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </li>
}