import {useState} from 'react';

export default function useNewUser() {
    const getNewUser = () => {
        const newUserString = localStorage.getItem('newUser');
        try {
            const userNewUser = JSON.parse(newUserString);
            return userNewUser
        } catch (e) {
            return false
        }
    };

    const [newUser, setNewUser] = useState(getNewUser());

    const saveNewUser = userNewUser => {
        localStorage.setItem('newUser', JSON.stringify(userNewUser));
        setNewUser(userNewUser);
    };

    return {
        setNewUser: saveNewUser,
        newUser
    }
}
