import React, {useEffect, useState} from 'react';
import {approveUser, denyUser, getDashboard} from '../../services/dashboard';
import {NavLink} from 'react-router-dom';
import {
    BriefcaseIcon,
    CheckIcon,
    ClockIcon,
    DocumentAddIcon,
    DotsHorizontalIcon,
    ExclamationCircleIcon,
    HeartIcon,
    NewspaperIcon,
    PresentationChartLineIcon,
    SpeakerphoneIcon,
    UserGroupIcon
} from "@heroicons/react/outline";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import ScaleLoader from "react-spinners/ScaleLoader";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Dashboard({setErrorMessage, setMobileNavigation}) {

    const [dashboard, setDashboard] = useState({
        updates: [],
        announcements: [],
        approvals: [],
    });
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loading, setLoading ] =useState(true)
    setMobileNavigation("Home")

    useEffect(() => {
        let mounted = true;
        getDashboard()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setDashboard(resp.body);
                        setLoading(false);
                    }
                } else if (resp.status === 401) {
                    window.location.href = "/logout"
                }
            });
        return () => mounted = false;
    }, []);

    const handleApprove = async (e, id) => {
        e.preventDefault();

        try {
            setErrorMessage("")

            const {status} = await approveUser({
                id,
            });
            if (status === 200) {
                setCurrentSlide(currentSlide + 1)
            } else {
                setErrorMessage("Error encountered approving user")
            }
        } catch (error) {
            setErrorMessage("Error encountered approving user")
        }

    };

    const handleDeny = async (e, id) => {
        e.preventDefault();

        try {
            setErrorMessage("")
            const {status} = await denyUser({
                id,
            });

            if (status === 200) {
                setCurrentSlide(currentSlide + 1)
            } else {
                setErrorMessage("Error encountered rejecting user")
            }

        } catch (error) {
            setErrorMessage("Error encountered rejecting user")
        }
    };

    let slides = [];
    for (let i = 0; i < dashboard.approvals.length; i++) {
        let approval = dashboard.approvals[i];

        let properties = [];
        for (let j = 0; j < approval.propertyNames.length; j++) {
            properties.push(<li>{approval.propertyNames[j]}</li>)
        }

        let vehicles = [];
        for (let j = 0; j < approval.vehicleRegistrations.length; j++) {
            vehicles.push(<li>{approval.vehicleRegistrations[j]}</li>)
        }


        if (properties.length > 0 && vehicles.length === 0) {
            slides.push(<div>
                <div className={"list-disc p-6"}><b>{approval.firstName} {approval.lastName}</b> claims to own the
                    following
                    properties at <b>{approval.residenceName}</b>:
                </div>
                <ul>
                    {properties}
                </ul>
                <div className={"p-6"}>Does this look correct?</div>
                <div>
                    <button
                        onClick={e => handleApprove(e, approval.id)}
                        className="w-full px-4 py-2 mb-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                    >
                        Yes
                    </button>
                    <button
                        onClick={e => handleDeny(e, approval.id)}
                        className="w-full flex items-center justify-center  bg-white hover:bg-gray-100 text-gray-500 font-medium py-2 px-4 border border-gray-300 rounded">

                        No
                    </button>
                </div>
            </div>)
        } else if (properties.length === 0 && vehicles.length > 0) {
            slides.push(<div>
                <div className={"list-disc p-6"}><b>{approval.firstName} {approval.lastName}</b> claims to own the
                    following
                    vehicles at <b>{approval.residenceName}</b>:
                </div>
                <ul>
                    {vehicles}
                </ul>
                <div className={"p-6"}>Does this look correct?</div>
                <div>
                    <button
                        onClick={e => handleApprove(e, approval.id)}
                        className="w-full px-4 py-2 mb-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                    >
                        Yes
                    </button>
                    <button
                        onClick={e => handleDeny(e, approval.id)}
                        className="w-full px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                    >
                        No
                    </button>
                </div>
            </div>)
        } else if (properties.length > 0 && vehicles.length > 0) {
            slides.push(<div>
                <div className={"list-disc p-6"}><b>{approval.firstName} {approval.lastName}</b> claims to own the
                    following
                    properties and vehicles at <b>{approval.residenceName}</b>:
                </div>
                <ul>
                    {properties}
                </ul>
                <ul className={"mt-4"}>
                    {vehicles}
                </ul>
                <div className={"p-6"}>Does this look correct?</div>
                <div>
                    <button
                        onClick={e => handleApprove(e, approval.id)}
                        className="w-full px-4 py-2 mb-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                    >
                        Yes
                    </button>
                    <button
                        onClick={e => handleDeny(e, approval.id)}
                        className="w-full px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                    >
                        No
                    </button>
                </div>
            </div>)
        } else if (properties.length === 0 && vehicles.length === 0) {
            slides.push(<div>
                <div className={"list-disc p-6"}><b>{approval.firstName} {approval.lastName}</b> claims to not any properties or vehicles at <b>{approval.residenceName}</b></div>
                <div className={"p-6"}>Does this sound correct?</div>
                <div>
                    <button
                        onClick={e => handleApprove(e, approval.id)}
                        className="w-full px-4 py-2 mb-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                    >
                        Yes
                    </button>
                    <button
                        onClick={e => handleDeny(e, approval.id)}
                        className="w-full px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                    >
                        No
                    </button>
                </div>
            </div>)
        }
    }

    if (slides.length > 0) {
        slides.push(<div>
            <div className={"mt-8 text-3xl"}>&#127881; All set! &#127881;</div>
            <div className={"mt-8"}>&nbsp;</div>
        </div>)
    }

    return loading ?
        <main className="lg:col-span-10 xl:col-span-7">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :
        dashboard.updates.length > 0 ?

        <>

            <main className="lg:col-span-12 xl:col-span-6  order-2 xl:order-1 ">
                <div className="space-y-4">
                    <div className="bg-white rounded-lg shadow mb-2 sm:mb-4 mx-4 sm:mx-0">
                        <div className="p-4 sm:p-6">
                            <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                <ClockIcon
                                    className={classNames(
                                        'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                    )}

                                    aria-hidden="true"
                                />
                                <span className="truncate">Recent Activity</span>
                            </div>
                                <ul className="space-y-4 divide-y divide-white overflow-x-hidden">
                                    {dashboard.updates.map(update => <li key={update.id} className={"mb-4"}>

                                        <article aria-labelledby={'question-title-' + update.id}>
                                            <h1 id={'question-title-' + update.id}
                                                className="text-base font-medium text-gray-900 mt-6">
                                                {update.date}
                                            </h1>

                                            {update.items.map(d => d.type === "new_post" ?
                                                <NavLink
                                                    to={`/posts/`}
                                                    key={d.id}
                                                    className={'text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md  rounded-md mt-2 break-words'}
                                                >
                                                    <NewspaperIcon
                                                        className={classNames('text-gray-600',
                                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                        )}

                                                        aria-hidden="true"
                                                    />
                                                    <span className="overflow-x-hidden">{d.title}</span>
                                                </NavLink>
                                                :
                                                d.type === "new_announcement" ? <NavLink
                                                        to={d.url}
                                                        key={d.id}
                                                        className={`text-${d.color}-600 hover:bg-${d.color}-200 overflow-x-hidden group flex items-center px-3 py-2 text-sm font-medium rounded-md  bg-${d.color}-100 rounded-md mt-2 border-${d.color}-200`}
                                                    >
                                                        <SpeakerphoneIcon
                                                            className={classNames(`text-${d.color}-600`,
                                                                'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                            )}

                                                            aria-hidden="true"
                                                        />
                                                        <span className="overflow-x-hidden">{d.title}</span>
                                                    </NavLink>
                                                    :

                                                    d.type === "new_property_complaint" ? <NavLink
                                                            to={d.url}
                                                            key={d.id}
                                                            className={`text-${d.color}-600 hover:bg-${d.color}-200 group flex  break-words items-center px-3 py-2 text-sm font-medium rounded-md  bg-${d.color}-100 rounded-md mt-2 border-${d.color}-200`}
                                                        >
                                                            <ExclamationCircleIcon
                                                                className={classNames(`text-${d.color}-600`,
                                                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                                )}

                                                                aria-hidden="true"
                                                            />
                                                            <span className="overflow-x-hidden">{d.title}</span>
                                                        </NavLink>
                                                        :

                                                        d.type === "new_vehicle_complaint" ? <NavLink
                                                                to={d.url}
                                                                key={d.id}
                                                                className={`text-${d.color}-600 hover:bg-${d.color}-200 group flex  break-words items-center px-3 py-2 text-sm font-medium rounded-md  bg-${d.color}-100 rounded-md mt-2 border-${d.color}-200`}
                                                            >
                                                                <ExclamationCircleIcon
                                                                    className={classNames(`text-${d.color}-600`,
                                                                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                                    )}

                                                                    aria-hidden="true"
                                                                />
                                                                <span className="overflow-x-hidden">{d.title}</span>
                                                            </NavLink>
                                                            :


                                                            d.type === "complaint_resolved" ? <NavLink
                                                                    to={d.url}
                                                                    key={d.id}
                                                                    className={`text-${d.color}-600 hover:bg-${d.color}-200 group flex  break-words items-center px-3 py-2 text-sm font-medium rounded-md  bg-${d.color}-100 rounded-md mt-2 border-${d.color}-200`}
                                                                >
                                                                    <ExclamationCircleIcon
                                                                        className={classNames(`text-${d.color}-600`,
                                                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                                        )}

                                                                        aria-hidden="true"
                                                                    />
                                                                    <span
                                                                        className="overflow-x-hidden">{`${d.title} was resolved`}</span>
                                                                </NavLink>
                                                                :

                                                                d.type === "new_agenda_item" ? <NavLink
                                                                        to={d.url}
                                                                        key={d.id}
                                                                        className={`text-${d.color}-600 hover:bg-${d.color}-200 group flex  break-words items-center px-3 py-2 text-sm font-medium rounded-md  bg-${d.color}-100 rounded-md mt-2 border-${d.color}-200`}
                                                                    >
                                                                        <UserGroupIcon
                                                                            className={classNames(`text-${d.color}-600`,
                                                                                'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                                            )}

                                                                            aria-hidden="true"
                                                                        />
                                                                        <span
                                                                            className="overflow-x-hidden">{d.title}</span>
                                                                    </NavLink>
                                                                    :

                                                                    d.type === "new_case" ? <NavLink
                                                                            to={d.url}
                                                                            key={d.id}
                                                                            className={`text-${d.color}-600 hover:bg-${d.color}-200 group flex  break-words items-center px-3 py-2 text-sm font-medium rounded-md  bg-${d.color}-100 rounded-md mt-2 border-${d.color}-200`}
                                                                        >
                                                                            <BriefcaseIcon
                                                                                className={classNames(`text-${d.color}-600`,
                                                                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                                                )}

                                                                                aria-hidden="true"
                                                                            />
                                                                            <span
                                                                                className="overflow-x-hidden">{d.title}</span>
                                                                        </NavLink>
                                                                        :

                                                                        d.type === "new_case_update" ?
                                                                            <NavLink
                                                                                to={d.url}
                                                                                key={d.id}
                                                                                className={`text-${d.color}-600 hover:bg-${d.color}-200 group flex  break-words items-center px-3 py-2 text-sm font-medium rounded-md  bg-${d.color}-100 rounded-md mt-2 border-${d.color}-200`}
                                                                            >
                                                                                <DocumentAddIcon
                                                                                    className={classNames(`text-${d.color}-600`,
                                                                                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                                                    )}

                                                                                    aria-hidden="true"
                                                                                />
                                                                                <span
                                                                                    className="overflow-x-hidden">{d.title}</span>
                                                                            </NavLink>
                                                                            :

                                                                            d.type === "new_meeting_scheduled" ?
                                                                                <NavLink
                                                                                    to={d.url}
                                                                                    key={d.id}
                                                                                    className={`text-${d.color}-600 hover:bg-${d.color}-200 group flex  break-words items-center px-3 py-2 text-sm font-medium rounded-md  bg-${d.color}-100 rounded-md mt-2 border-${d.color}-200`}
                                                                                >
                                                                                    <UserGroupIcon
                                                                                        className={classNames(`text-${d.color}-600`,
                                                                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                                                        )}

                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    <span
                                                                                        className="overflow-x-hidden">{d.title}</span>
                                                                                </NavLink>
                                                                            :
                                                                            d.type === "finances_updated" ?
                                                                                <NavLink
                                                                                    to={d.url}
                                                                                    key={d.id}
                                                                                    className={`text-${d.color}-600 hover:bg-${d.color}-200 group flex  break-words items-center px-3 py-2 text-sm font-medium rounded-md  bg-${d.color}-100 rounded-md mt-2 border-${d.color}-200`}
                                                                                >
                                                                                    <PresentationChartLineIcon
                                                                                        className={classNames(`text-${d.color}-600`,
                                                                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                                                        )}

                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    <span
                                                                                        className="overflow-x-hidden">{d.title}</span>
                                                                                </NavLink>
                                                                                :
                                                                                <NavLink
                                                                                    to={"#"}
                                                                                    key={d.id}
                                                                                    className={'text-gray-600 hover:bg-gray-50 group flex  break-words items-center px-3 py-2 text-sm font-medium rounded-md rounded-md mt-2'}
                                                                                >
                                                                                    <UserGroupIcon
                                                                                        className={classNames('text-gray-600',
                                                                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                                                                        )}

                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    <span
                                                                                        className="overflow-x-hidden">{d.title}</span>
                                                                                </NavLink>)}

                                        </article>
                                    </li>)}
                                </ul>
                        </div>
                    </div>
                    {/*another section*/}
                </div>
            </main>


            <aside className="xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2 mb-2">
                <div className="sticky top-4 space-y-4 ">
                    {dashboard.approvals.length > 0 ?
                        <section aria-labelledby="trending-heading">
                            <div className="bg-white rounded-lg shadow mb-2 sm:mb-4 mx-4 sm:mx-0">
                                <div className="p-4 sm:p-6">
                                    <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                        <CheckIcon
                                            className={classNames(
                                                'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                            )}

                                            aria-hidden="true"
                                        />
                                        <span className="truncate">Awaiting Approval</span>
                                    </div>
                                    <Carousel showThumbs={false} showIndicators={false} showArrows={false}
                                              showStatus={false} selectedItem={currentSlide} dynamicHeight={true}>
                                        {slides}
                                    </Carousel>
                                </div>
                            </div>
                        </section>
                        :
                        <></>
                    }
                    {dashboard.announcements.length > 0 ?
                        <section aria-labelledby="trending-heading">
                            <div className="bg-white rounded-lg shadow mb-2 sm:mb-4 mx-4 sm:mx-0">
                                <div className="p-4 sm:p-6">
                                    <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                        <SpeakerphoneIcon
                                            className={classNames(
                                                'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                            )}

                                            aria-hidden="true"
                                        />
                                        <span className="truncate">Recent Announcements</span>
                                    </div>
                                    <div className="mt-4 flow-root">
                                        <NavLink to={"/announcements"}>

                                        <ul className="list-disc p-4 text-sm">
                                            {dashboard.announcements.map(post => <li key={post.id}>
                                                    <p className="text-sm text-gray-900">{post.title}</p>
                                            </li>)}
                                        </ul>
                                        </NavLink>
                                    </div>
                                    <div className="mt-4">
                                        <PrimaryButton to="/announcements">View all announcements</PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        <></>
                    }
                </div>
            </aside>

        </>
        :
            <main className="lg:col-span-12 xl:col-span-8">
                <div className="w-2/3 m-auto text-center items-center text-4xl mt-12">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="text-gray-300 w-32 m-auto">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div className={"mt-16 mb-32 text-gray-300 font-medium"}>
                        Nothing to see here
                    </div>
            </div>
        </main>
}

