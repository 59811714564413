import React, {useEffect, useState} from "react";
import {ExclamationCircleIcon} from "@heroicons/react/outline";
import {getNewComplaintForm, submitNewComplaint} from "../../../services/complaints";
import Select from "react-tailwindcss-select";
import {PrimaryButton} from "../../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NewComplaint({setErrorMessage, setMobileNavigation}) {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [complaintTarget, setComplaintTarget] = useState({});
    const [targets, setTargets] = useState([]);

    setMobileNavigation("New Complaint")
    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")

            const {status} = await submitNewComplaint({
                title,
                content,
                ownershipItem: complaintTarget.value.id,
                ownershipType: complaintTarget.value.type,
                timestamp: new Date().toISOString(),
            });

            if (status === 200) {
                window.location.href = "/complaints"
            } else {
                setErrorMessage("Unable to submit new complaint")
            }
        } catch (error) {
            setErrorMessage("Unable to submit new complaint")
        }
    };


    useEffect(() => {
        let mounted = true;
        getNewComplaintForm()
            .then(resp => {
                if (mounted) {
                    setTargets(resp.body.targets)
                }
            }).catch(error => {
        });
        return () => mounted = false;
    }, []);

    return <><main className="lg:col-span-12 xl:col-span-6 bg-white shadow p-4 sm:p-6 rounded-lg mx-4 sm:mx-0  order-2 xl:order-1">
    <div id="trending-heading" className="hidden sm:block font-normal text-xl sm:font-medium text-gray-900">
            <ExclamationCircleIcon
                className={classNames(
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                )}

                aria-hidden="true"
            />
            <span className="truncate">Lodge a complaint</span>
        </div>
        <div className="mt-2 sm:mt-6">
            <form onSubmit={handleSubmit}>
                <Select
                    name="complaintType"
                    value={complaintTarget}
                    isSearchable={true}
                    onChange={setComplaintTarget}
                    options={targets}
                    required={true}
                    placeholder="Lodge complaint against..."
                />
                <textarea
                    rows={1}
                    name="title"
                    id="title"
                    value={title}
                    required={true}
                    onChange={e => setTitle(e.target.value)}
                    className="shadow-sm mt-4 focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                    placeholder="Add your title..."
                />
                <textarea
                    rows={10}
                    name="content"
                    id="content"
                    value={content}
                    required={true}
                    onChange={e => setContent(e.target.value)}
                    className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                    placeholder="Add your content..."
                />
                <div className="mt-2 flex justify-end">
                    <PrimaryButton>
                        Lodge complaint
                    </PrimaryButton>
                </div>
            </form>
        </div>
    </main>
        <aside className="mb-2 xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2">
            <div className="sticky top-4 space-y-4">
                <section aria-labelledby="trending-heading">
                    <div className="bg-white rounded-lg shadow mb-2 sm:mb-4 mx-4 sm:mx-0">
                        <p className={"sm:p-4 p-6"}>
                            Users receiving complaints <b>will not</b> be aware of who made the complaint, unless you explicitly identify yourself in the complaint.
                        </p>
                    </div>
                </section>
            </div>
        </aside>
        </>
}
