import {useState} from 'react';

export default function useCanRenew() {
    const getCanRenew = () => {
        const canRenewString = localStorage.getItem('canRenew');
        try {
            const userCanRenew = JSON.parse(canRenewString);
            return userCanRenew
        } catch (e) {
            return false
        }
    };

    const [canRenew, setCanRenew] = useState(getCanRenew());

    const saveCanRenew = userCanRenew => {
        localStorage.setItem('canRenew', JSON.stringify(userCanRenew));
        setCanRenew(userCanRenew);
    };

    return {
        setCanRenew: saveCanRenew,
        canRenew
    }
}
