import React, {useEffect, useState} from 'react';
import {subscribe} from '../../services/subscribe';
import PropTypes from "prop-types";
import {populateSubscription} from "../../services/confirmSubscription";
import ScaleLoader from "react-spinners/ScaleLoader";

export default function Subscribe({setWizard, setErrorMessage}) {
    const [index, setIndex] = useState(0);
    const [shareholders, setShareholders] = useState(0);
    const [managementCompany, setManagementCompany] = useState("");
    const [loading, setLoading] = useState(true);
    const [steps] = useState([
        {name: 'Step 1'},
        {name: 'Step 2'},
    ]);
    useEffect(() => {
        let mounted = true;
        populateSubscription()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setManagementCompany(resp.body.name);
                        setShareholders(resp.body.shareholders)
                        setLoading(false);
                    }
                }
            });
        return () => mounted = false;
    }, []);


    const handleShareholderChange = event => {
        const result = event.target.value.replace(/\D/g, '');

        setShareholders(result);
    };

    const submitMonthly = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const quantity = Number(shareholders);

            const {status, body} = await subscribe({
                planType: "monthly",
                quantity,
            });

            if (status === 200) {
                window.location.href = body.url;
            } else {
                setErrorMessage("Unable to subscribe")
            }
        } catch (error) {
            setErrorMessage("Unable to subscribe")
        }
    };

    const submitAnnual = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const quantity = Number(shareholders);

            const {status, body} = await subscribe({
                planType: "annual",
                quantity,
            });

            if (status === 200) {
                window.location.href = body.url;
            } else {
                setErrorMessage("Unable to subscribe")
            }
        } catch (error) {
            setErrorMessage("Unable to subscribe")
        }
    };

    const submitTrial = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const quantity = Number(shareholders);

            const {status, body} = await subscribe({
                planType: "trial",
                quantity,
            });

            if (status === 200) {
                window.location.href = body.url;
            } else {
                setErrorMessage("Unable to subscribe")
            }
        } catch (error) {
            setErrorMessage("Unable to subscribe")
        }
    };


    const tiers = [
        {
            name: 'Annual',
            href: '#',
            priceMonthly: 0.79,
            description: 'All our features with a discount of almost 20% if you pay annually',
            includedFeatures: [
                "Posts", "Announcements", "Cases", "Complaints", "Finances", "Meetings", "Free tenant access"
            ],
            fn: submitAnnual,
        },
        {
            name: 'Monthly',
            href: '#',
            priceMonthly: 0.99,
            description: 'All our features at an affordable monthly rate',
            includedFeatures: [
                "Posts", "Announcements", "Cases", "Complaints", "Finances", "Meetings", "Free tenant access"
            ],
            fn: submitMonthly,
        },

    ];

    return loading ?
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </div>
        : <div className="mx-auto max-w-2xl px-6 lg:px-8">
        <h2 className="mb-16 text-center text-3xl font-normal tracking-tight text-gray-900 mt-16">Let's get started!</h2>

        {index === 0 ?

            <div>
                <h3 className="mb-8 text-lg">In total, how many directors, shareholders and agents make up <span
                    className={"font-bold"}>{managementCompany}</span>?</h3>
                <ol className={"list-disc mb-8"}>
                    <li>If a single person has multiple roles within the company, e.g. if they are both a director
                        and a shareholder, they should be counted as one individual
                    </li>
                    <li>You can increase or decrease this number within the app</li>
                </ol>
                <div className={"items-center content-center text-center justify-items-center"}>
                    <input
                        className="max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 mb-4"
                        type="number"
                        value={shareholders}
                        onChange={handleShareholderChange}
                    />
                    <button
                        onClick={e => setIndex(index + 1)}
                        className="m-auto block py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    >
                        Next
                    </button>
                </div>

            </div>

            :


            <div>
                <div
                    className="sm:grid sm:grid-cols-2 sm:gap-2 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-2">
                    {tiers.map(tier => <div key={tier.name}
                                            className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm">
                        <div className="p-6">
                            <h2 className="text-lg font-medium leading-6 text-gray-900">{tier.name}</h2>
                            <p className="mt-8">
                                <span
                                    className="text-4xl font-bold tracking-tight text-gray-900">£{tier.priceMonthly.toFixed(2)}</span>{' '}
                                <span className="text-base font-medium text-gray-500"> / user / month</span>

                                {
                                    tier.name === 'Monthly'
                                        ?
                                        <div
                                            className="text-sm font-normal tracking-tight text-gray-900 mt-4">£{(Number(shareholders) * tier.priceMonthly).toFixed(2)} billed
                                            monthly</div>

                                        :
                                        <div
                                            className="text-sm font-normal tracking-tight text-gray-900 mt-4">£{(Number(shareholders) * 12 * tier.priceMonthly).toFixed(2)} billed
                                            annually</div>

                                }
                                <div className="font-normal tracking-tight text-gray-900 mt-8">{tier.description}</div>
                            </p>
                            <form className="space-y-6" onSubmit={tier.fn}>
                                <button
                                    type="submit"
                                    className="mt-8 block w-full rounded-md bg-orange-500 py-2 text-center font-normal text-white hover:bg-orange-700"
                                >
                                    Buy {tier.name}
                                </button>
                            </form>
                        </div>
                    </div>)}
                </div>
                <div>
                    <form className="space-y-6" onSubmit={submitTrial}>
                        <button
                            type="submit"
                            className="flex mt-8 w-full justify-center py-2 px-4 border rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                        >
                            Just the free trial, please!
                        </button>
                    </form>
                </div>
                <ol className="mt-8 text-gray-500 list-disc">
                    <li>Both the annual and monthly plans include a 30-day free trial</li>
                    <li>For more information, please read our <a className={"text-orange-500 underline"} target="_blank" href={"/terms"}>terms and conditions</a> and <a className={"text-orange-500 underline"} target="_blank" href={"/privacy"}>privacy policy</a></li>
                </ol>
                <button
                    onClick={e => setIndex(index - 1)}
                    className="flex mt-8 justify-center py-2 px-4 border rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                    Go back
                </button>
            </div>
        }
    </div>
}

Subscribe.propTypes = {
    setToken: PropTypes.func.isRequired,
};