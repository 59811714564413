import React from 'react';
import {MailIcon} from "@heroicons/react/outline";

export default function SignupSuccess() {

    return <>
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="text-orange-500 text-center" style={{fontSize: "4em", fontFamily: `'Fortika', sans-serif`}}>
                    votria
                </div>

            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md text-center mt-8 text-xl">
                Hooray!
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md text-center mt-8">
                Email sent, please check your inbox.
            </div>
        </div>
    </>


}

