import getToken from './token'


export function inviteShareholders({names, emails}) {
    const payload = {
        names,
        emails,
    };
    return fetch(`${process.env.REACT_APP_API}/v1/invite-shareholders`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function inviteShareholder({firstName, lastName, email, role}) {
    const payload = {
        firstName,
        lastName,
        email,
        role,
    };
    return fetch(`${process.env.REACT_APP_API}/v1/shareholders`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}


export function inviteTenant({tenantFirstName, tenantLastName, tenantEmail, tenantProperty}) {
    const payload = {
        firstName: tenantFirstName,
        lastName: tenantLastName,
        email: tenantEmail,
        property: tenantProperty,
    };
    return fetch(`${process.env.REACT_APP_API}/v1/tenants`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function removeTenant(payload) {
    return fetch(`${process.env.REACT_APP_API}/v1/tenants`, {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function removeUser(id) {
    return fetch(`${process.env.REACT_APP_API}/v1/users/remove/`+id, {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function removeShareholder({id}) {
    const payload = {
        id,
    };
    return fetch(`${process.env.REACT_APP_API}/v1/shareholders`, {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function getInviteForm(id) {
    return fetch(`${process.env.REACT_APP_API}/v1/invite/` + id, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}
