import getToken from './token'


export function getFinances() {
    return fetch(`${process.env.REACT_APP_API}/v1/finances`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function getBanks(country) {
    return fetch(`${process.env.REACT_APP_API}/v1/finances/banks/list/`+country, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitBank(bank) {
    return fetch(`${process.env.REACT_APP_API}/v1/finances/token/create/`+bank, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function createLinkToken() {
    return fetch(`${process.env.REACT_APP_API}/v1/finances/token/create`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function exchangeLinkToken(publicToken) {
    return fetch(`${process.env.REACT_APP_API}/v1/finances/token/exchange`, {
        method: "POST",
        credentials: 'same-origin', // include, *same-origin, omit
        body: JSON.stringify({publicToken}),
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function revokeFinances(revokeDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/finances/revoke`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        body: JSON.stringify(revokeDetails),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

