import React from 'react';
import {BadgeCheckIcon} from "@heroicons/react/outline";

export default function InviteSuccess() {

    return <div className="w-1/6 m-auto mt-8 text-center items-center">
        <div>
            <BadgeCheckIcon className={"text-orange-500"}/>
        </div>
        <div className={"text-xl mt-6"}>
            Thanks for signing up!
        </div>
        <div className={"text-xl mt-8"}>
            You can now <a href={"/"} className={"text-orange-500 underline"}>login</a>!
        </div>
    </div>


}

