import React, {Fragment, useState} from 'react';
import './App.css';
import {BrowserRouter, NavLink, Route, Routes} from 'react-router-dom';
import Login from '../Login/Login';
import Logout from '../Logout/Logout';
import Dashboard from '../Dashboard/Dashboard';
import Complaints from '../Complaints/Complaints';
import Cases from '../Cases/Cases';
import Finances from '../Finances/Finances';
import Directory from '../Directory/Directory';
import Announcements from '../Announcements/Announcements';
import Profile from '../Profile/Profile';
import Posts from '../Posts/Posts';
import NewPost from '../Posts/New/NewPost';
import useToken from "./useToken";
import useGroups from "./useGroups";
import useWizard from "./useWizard";
import useNewUser from "./useNewUser";
import useName from "./useName";
import useUser from "./useUser";
import useNavigation from "./useNavigation";
import useRunTour from "./useRunTour";

import {Menu, Popover, Transition} from '@headlessui/react'
import {
    BookOpenIcon,
    BriefcaseIcon,
    ExclamationCircleIcon,
    HomeIcon,
    MenuIcon,
    NewspaperIcon,
    PresentationChartLineIcon,
    SpeakerphoneIcon,
    UserGroupIcon,
    XIcon,
    QuestionMarkCircleIcon
} from '@heroicons/react/outline'
import Meetings from "../Meetings/Meetings";
import NewAgendaItem from "../Meetings/New/NewAgendaItem";
import NewComplaint from "../Complaints/New/NewComplaint";
import NewCase from "../Cases/New/NewCase";
import ViewCase from "../Cases/View/ViewCase";
import ViewPost from "../Posts/View/ViewPost";
import ListPreviousMeetings from "../Meetings/List/ListPreviousMeetings";
import ViewMeeting from "../Meetings/View/ViewMeeting";
import Signup from "../Signup/Signup";
import SignupSuccess from "../Signup/Success/Success";
import SignupConfirmation from "../Signup/Confirmation/Confirmation";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ResetPassword from "../ResetPassword/ResetPassword";
import Subscribe from "../Subscribe/Subscribe";
import SubscribeSuccess from "../Subscribe/SubscribeSuccess/SubscribeSuccess";
import Invite from "../Invite/Invite";
import InviteSuccess from "../Invite/Success/Success";
import NewUser from "../NewUser/NewUser";
import NewUserSuccess from "../NewUser/Success/Success";
import GroupChooser from "../GroupChooser/GroupChooser";
import FileViewer from "../FileViewer/FileViewer";
import ViewComplaint from "../Complaints/View/ViewComplaint";
import Subscription from "../Directory/Subscription/Subscription";
import ChangePassword from "../ChangePassword/ChangePassword";
import NewAnnouncement from "../Announcements/New/NewAnnouncement";
import Error from "../Error/Error";
import ScriptBlock from "../ScriptBlock/ScriptBlock";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import useExpired from "./useExpired";
import useCanRenew from "./useCanRenew";
import ExpiredSubscription from "../ExpiredSubscription/ExpiredSubscription";
import RenewSubscription from "../ExpiredSubscription/RenewSubsciption/RenewSubscription";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import Terms from "../Terms/Terms";
import Privacy from "../Privacy/Privacy";
import Help from "../Help/Help";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function App() {
    const {token, setToken} = useToken();
    const {name, setName} = useName();
    const {groups, setGroups} = useGroups();
    const {wizard, setWizard} = useWizard();
    const {navigation, setNavigation} = useNavigation();
    const {newUser, setNewUser} = useNewUser();
    const {runTour, setRunTour} = useRunTour();
    const {expired, setExpired} = useExpired();
    const {canRenew, setCanRenew} = useCanRenew();
    const {user, setUser} = useUser();
    const [errorMessage, setErrorMessage] = useState('');
    const [steps, setSteps] = useState([]);
    const [stepIndex, setStepIndex] = useState(0)
    const [mobileNavigation, setMobileNavigation] = useState("")

    if (window.location.pathname === "/terms") {
        return <Terms/>
    } else if (window.location.pathname === "/privacy") {
        return <Privacy/>
    } else if (window.location.pathname.startsWith("/signup/")) {
        return <>
            <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <BrowserRouter>
                <Routes>
                    <Route path="/signup/:id" element={<Signup setErrorMessage={setErrorMessage}/>}/>
                    <Route path="/signup/:id/success" element={<SignupSuccess />}/>
                    <Route path="/signup/confirm/:id/:hash" element={<SignupConfirmation/>}/>
                </Routes>
            </BrowserRouter>
        </>
    } else if (window.location.pathname.startsWith("/invite")) {
        return <>
            <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <BrowserRouter>
                <Routes>
                    <Route path="/invite/:id" element={<Invite setErrorMessage={setErrorMessage}/>}/>
                    <Route path="/invite/:id/success" element={<InviteSuccess/>}/>
                </Routes>
            </BrowserRouter>
        </>
    } else if (window.location.pathname.startsWith("/forgot-password")) {
        return <>
            <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <BrowserRouter>
                <Routes>
                    <Route path="/forgot-password" element={<ForgotPassword setErrorMessage={setErrorMessage}/>}/>
                    <Route path="/forgot-password/reset/:token" element={<ResetPassword setErrorMessage={setErrorMessage}/>}/>
                </Routes>
            </BrowserRouter>
        </>
    }

    if (window.location.pathname.startsWith("/setup/success")) {
        return <>
            <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <BrowserRouter>
                <Routes>
                    <Route path="/setup" element={<Subscribe setErrorMessage={setErrorMessage}/>}/>
                    <Route path="/setup/success/:session" element={<SubscribeSuccess setWizard={setWizard} setExpired={setExpired}/>}/>
                </Routes>
            </BrowserRouter>
        </>
    } else if (window.location.pathname.startsWith("/new-user")) {
        return <>
            <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <BrowserRouter>
                <Routes>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/new-user" element={<NewUser setToken={setToken} setErrorMessage={setErrorMessage}/>}/>
                    <Route path="/new-user/success" element={<NewUserSuccess setToken={setToken}/>}/>
                </Routes>
            </BrowserRouter>
        </>
    }

    // IMPORTANT do not change the order of these components

    // if more than one group exists, trigger the group chooser
    if (groups !== undefined && groups.length > 1 && (!token || token === "")) {
        return <>
            <GroupChooser setToken={setToken} setWizard={setWizard} setNewUser={setNewUser} groups={groups}
                             setName={setName} setUser={setUser} setNavigation={setNavigation} setSteps={setSteps} setRunTour={setRunTour} setCanRenew={setCanRenew} setExpired={setExpired}></GroupChooser>
        </>
    }

    const userNavigation = [
        {
            name: 'Your Profile', href: '/users/me', onclick: () => {
            }, disabled: false
        },
        {
            name: 'Sign out', href: '/logout', onclick: () => {
            }, disabled: false
        },
    ];

    const workspaceNavigation = []

    if (groups.length > 1) {
        // userNavigation.push({
        //     name: 'Switch workspace', href: '#', onclick: () => {
        //     }, disabled: true
        // });

        for (let i = 0; i < groups.length; i++) {
            if (token !== groups[i].token) {
                workspaceNavigation.push({
                    name: groups[i].managementGroupName, href: "#", onclick: () => {
                        setToken(groups[i]);
                        setWizard(groups[i].runWizard);
                        setNewUser(groups[i].runNewUser);
                        setRunTour(groups[i].runNewUser);
                        setSteps(groups[i].steps);
                        setExpired(groups[i].expired);
                        setCanRenew(groups[i].canRenew);
                        setName(groups[i].managementGroupName);
                        setUser(groups[i].user);
                        setNavigation(groups[i].navigation);
                        window.location.reload()
                    }, disabled: false
                });
            }
        }
    }

    // if no token exists, trigger the login
    if (!token || token === "") {
        return <>
            <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <Login setToken={setToken} setGroups={setGroups} setWizard={setWizard} setNewUser={setNewUser}
                      setName={setName} setUser={setUser} setNavigation={setNavigation} setErrorMessage={setErrorMessage} setSteps={setSteps} setRunTour={setRunTour}  setCanRenew={setCanRenew} setExpired={setExpired}/>
            </>
    }

    if (!window.location.pathname.startsWith("/logout") && expired === true) {
        if (canRenew === true) {
            return <RenewSubscription setErrorMessage={setErrorMessage}/>
        } else {
            return <ExpiredSubscription/>
        }
    }

    // END IMPORTANT do not change the order of these components

    if (!window.location.pathname.startsWith("/logout") && wizard === true) {
        return <>
            <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <Subscribe setToken={setToken} setWizard={setWizard} setErrorMessage={setErrorMessage}/>
            </>
    }

    if (!window.location.pathname.startsWith("/logout") && newUser === true) {
        return <>
            <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <NewUser setToken={setToken} setNewUser={setNewUser} setErrorMessage={setErrorMessage} setRunTour={setRunTour}/>
        </>
    }

    if (window.location.pathname.startsWith("/files")) {
        return <>
            <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <BrowserRouter>
                <Routes>
                    <Route path="/files/muploads/:meetingID/:fileID/:fileName" element={<FileViewer/>}/>
                </Routes>
            </BrowserRouter>
        </>
    }

    let navlinks = [];

    const components = {
        HomeIcon,
        SpeakerphoneIcon,
        NewspaperIcon,
        UserGroupIcon,
        ExclamationCircleIcon,
        BriefcaseIcon,
        PresentationChartLineIcon,
        BookOpenIcon,
        QuestionMarkCircleIcon,
    };


    for (let i = 0; i < navigation.length; i++) {
        var item = navigation[i];
        const SpecificStory = components[item.icon];
        const className = `${item.name.toLowerCase()}-nav`;
        navlinks.push(
            <NavLink
                key={item.name}
                to={item.path}
                className={({isActive}) => isActive ? `${className} bg-gray-200 text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md` : `${className} text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md`}
                aria-current={({isActive}) => isActive ? 'page' : undefined}
            >

                <SpecificStory
                    className={classNames(
                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                    )}

                    aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
            </NavLink>
        )
    }

    const handleJoyrideCallback = (data) => {
        const { action, index, type, status } = data;

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update the current step based on user action
            setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        }

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            // End the tour if the user finishes or skips the tour
            setRunTour(false);
        }
    };


    return <>
        <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>

        <BrowserRouter>


            <div className="min-h-full">
                {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
                <Popover
                    as="header"
                    className={({open}) =>
                        classNames(
                            open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
                            'bg-white sm:shadow-sm xl:static xl:overflow-y-visible'
                        )
                    }
                >
                    {({open, close}) => <>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-gray-50 sm:bg-white  ">
                            <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8 bg-gray-50 sm:bg-white  ">
                                <div className="min-w-0 flex-1 px-0 xl:col-span-8 p-2 bg-gray-50 sm:bg-white  ">
                                    <div
                                        className="flex items-center bg-gray-50 sm:bg-white px-0 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                                        <div className="w-full ">
                                                {/*<OfficeBuildingIcon*/}
                                                {/*    className={"text-orange-500 w-12 pt-2 mr-4 float-left"}/>*/}
                                                <h2 className="bg-white hidden sm:block text-orange-500 text-3xl md:text-4xl xl:text-5x" style={{fontFamily: `'Fortika', sans-serif`}}>
                                                    votria
                                                </h2>
                                                <h2 className="bg-gray-50 sm:bg-white ml-2 sm:ml-0 block sm:hidden font-normal text-gray-900 text-3xl md:text-4xl xl:text-5x">
                                                    {mobileNavigation}
                                                </h2>
                                                <h1 className="text-sm sm:text-white md:text-gray-700 hidden xl:block">
                                                    {name}
                                                </h1>
                                                {/*<h1 className="sm:block md:hidden">*/}
                                                {/*    sm*/}
                                                {/*</h1>*/}
                                                {/*<h1 className="sm:hidden md:block lg:hidden">*/}
                                                {/*    md*/}
                                                {/*</h1>*/}
                                                {/*<h1 className="sm:hidden md:hidden lg:block xl:hidden">*/}
                                                {/*    lg*/}
                                                {/*</h1>*/}
                                                {/*<h1 className="sm:hidden md:hidden lg:hidden xl:block 2xl:hidden">*/}
                                                {/*    xl*/}
                                                {/*</h1>*/}
                                                {/*<h1 className="sm:hidden md:hidden lg:hidden xl:hidden 2xl:block">*/}
                                                {/*    2xl*/}
                                                {/*</h1>*/}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="flex items-center lg:absolute lg:right-0 lg:inset-y-0 xl:hidden">
                                    {/* Mobile menu button */}
                                    <Popover.Button
                                        className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-orange-500 xl:text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                                        <span className="sr-only">Open menu</span>
                                        {open ? <XIcon className="block h-6 w-6" aria-hidden="true"/> : <MenuIcon className="block h-6 w-6" aria-hidden="true"/>}
                                    </Popover.Button>
                                </div>
                                <div className="hidden xl:flex xl:items-center xl:justify-end xl:col-span-4">

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="flex-shrink-0 relative ml-5">
                                        <div>
                                            <Menu.Button
                                                className="profile-nav bg-white rounded-full flex focus:outline-none ring-2 ring-offset-2 ring-orange-500">
                                                <span className="sr-only">Open user menu</span>
                                                {user.imageURL !== "" ?
                                                    <img className="h-12 w-12 rounded-full ring-2 ring-offset-2 ring-orange-500" src={user.imageURL}
                                                         alt=""/>
                                                    :
                                                    <span
                                                        className="h-12 w-12 overflow-hidden rounded-full bg-gray-100 ring-2 ring-offset-2 ring-orange-500">
                                                            <svg className="h-full w-full text-gray-300 ring-2 ring-offset-2 ring-orange-500"
                                                                 fill="currentColor" viewBox="0 0 24 24">
                                                              <path
                                                                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                            </svg>
                                                      </span>
                                                }

                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                                                {userNavigation.map(item =>
                                                    <Menu.Item key={item.name} disabled={item.disabled}>

                                                    {({active}) => <NavLink
                                                        to={item.href}
                                                        onClick={item.onclick}
                                                        className={classNames(
                                                            item.disabled ? 'bg-gray-100 font-bold' : '',
                                                            'block py-2 px-4 text-sm text-gray-700 hover:bg-gray-50'
                                                        )}
                                                    >
                                                        {item.name}
                                                    </NavLink>}
                                                </Menu.Item>)}

                                                {workspaceNavigation.length > 0 ?
                                                    <div className="border-t border-gray-200">

                                                        {workspaceNavigation.map(item =>
                                                            <Menu.Item key={item.name} disabled={item.disabled}>

                                                                {({active}) => <NavLink
                                                                    to={item.href}
                                                                    onClick={item.onclick}
                                                                    className={classNames(
                                                                        item.disabled ? 'bg-gray-100 font-bold' : '',
                                                                        'block py-2 px-4 text-sm text-gray-700 hover:bg-gray-50'
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </NavLink>}
                                                            </Menu.Item>)}
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </Menu.Items>

                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>

                        <Popover.Panel as="nav" className="xl:hidden" aria-label="Global">
                            <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                                {navigation.map(item =>
                                        <NavLink
                                            key={item.name}
                                            to={item.path}
                                            onClick={close}
                                            className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </NavLink>
                                )}
                            </div>
                            <div className="border-t border-gray-200 pt-4">
                                <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                                        {user.imageURL !== "" ?
                                            <img className="h-12 w-12 rounded-full ring-2 ring-offset-2 ring-orange-500" src={user.imageURL}
                                                 alt=""/>
                                            :
                                            <span
                                                className="h-12 w-12 overflow-hidden rounded-full bg-gray-100 ring-2 ring-offset-2 ring-orange-500">
                                                        <svg className="h-full w-full text-gray-300"
                                                             fill="currentColor" viewBox="0 0 24 24">
                                                          <path
                                                              d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                        </svg>
                                                      </span>
                                        }
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-gray-800">{user.name}</div>
                                        <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                    </div>
                                </div>
                                <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                                    {userNavigation.map(item => <NavLink
                                        key={item.name}
                                        to={item.href}
                                        onClick={close}
                                        // onClick={item.onclick}
                                        className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                        {item.name}
                                    </NavLink>)}
                                </div>
                            </div>
                            <div className="border-t border-gray-200 pt-4 mt-4">
                                <div className="max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                                    {workspaceNavigation.map(item => <NavLink
                                        key={item.name}
                                        to={item.href}
                                        onClick={close}
                                        className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                        {item.name}
                                    </NavLink>)}
                                </div>
                            </div>
                        </Popover.Panel>
                    </>}
                </Popover>

                <Joyride
                    steps={steps}
                    runTour={runTour} // Set this state to true when you want to start the tour
                    stepIndex={stepIndex}
                    callback={handleJoyrideCallback}
                    continuous // Optional, to allow for continuous tour navigation
                    showProgress // Optional, to show progress in the tooltip
                    showSkipButton // Optional, to show a skip button in the tooltip
                    styles={{
                        options: {
                            // Customize the tooltip appearance here
                            zIndex: 10000, // Make sure tooltips appear on top of your app elements
                        },
                    }}
                />
                <div className="py-2 sm:py-10">
                    <div
                        className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 grid lg:grid-cols-12 lg:gap-8">
                        <div className="hidden xl:block xl:col-span-2">
                            <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-gray-300">
                                <div className="pb-8 space-y-1">
                                    {navlinks}
                                </div>
                            </nav>
                        </div>

                        <Routes>
                            <Route path="/" element={<Dashboard setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/logout"
                                   element={<Logout setToken={setToken} setGroups={setGroups} setWizard={setWizard}
                                                    setNewUser={setNewUser}  setRunTour={setRunTour} setUser={setUser} setName={setName} setNavigation={setNavigation} setErrorMessage={setErrorMessage} setSteps={setSteps}/>}/>
                            <Route path="/announcements" element={<Announcements setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/announcements/new" element={<NewAnnouncement setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/posts" element={<Posts setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/posts/new" element={<NewPost setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/posts/:id" element={<ViewPost setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/meetings" element={<Meetings setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/meetings/new-agenda-item" element={<NewAgendaItem setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/meetings/view-all" element={<ListPreviousMeetings setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/meetings/view/:id" element={<ViewMeeting setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/complaints" element={<Complaints setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/complaints/new" element={<NewComplaint setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/complaints/:id" element={<ViewComplaint setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/cases" element={<Cases setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/cases/new" element={<NewCase setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/cases/:id" element={<ViewCase setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/finances" element={<Finances setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/directory" element={<Directory setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/directory/subscription" element={<Subscription setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/help" element={<Help setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/users/:id" element={<Profile user={user} setUser={setUser} setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                            <Route path="/change-password" element={<ChangePassword setErrorMessage={setErrorMessage} setMobileNavigation={setMobileNavigation}/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </BrowserRouter>

    </>
}

export default App;