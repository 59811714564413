import {NavLink} from "react-router-dom";
import {DocumentAddIcon, ExclamationCircleIcon, XCircleIcon, XIcon} from "@heroicons/react/outline";
import React, {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {removeShareholder} from "../../../services/invite";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DirectoryRow({person, isAdmin}) {


    return <tr>
        <td
            className={classNames(
                "whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 lg:pl-8"
            )}
        >
            {person.firstName}
        </td>
        <td
            className={classNames(
                "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
            )}
        >
            {person.lastName}
        </td>
        <td
            className={classNames(
                "px-2 py-4  hidden sm:block text-sm text-gray-500 sm:table-cell"
            )}
        >
            {person.isDirector ? <div
                className="text-xs inline-flex items-center leading-sm px-2 py-1 bg-red-200 text-red-700 rounded-full mr-2 mb-2"
            >Director</div> : <></>}
            {person.isAgent ? <div
                className="text-xs inline-flex items-center leading-sm px-2 py-1 bg-orange-200 text-orange-700 rounded-full mr-2"
            >Agent</div> : <></>}
            {person.isShareholder ? <div
                className="text-xs inline-flex items-center leading-sm px-2 py-1 bg-blue-200 text-blue-700 rounded-full mr-2"
            >Shareholder</div> : <></>}
            {person.isTenant ? <div
                className="text-xs inline-flex items-center leading-sm px-2 py-1 bg-green-200 text-green-700 rounded-full mr-2"
            >Tenant</div> : <></>}
            {!person.accountActivated ? <div
                className="text-xs inline-flex items-center leading-sm px-2 py-1 bg-gray-200 text-gray-700 rounded-full mr-2"
            >Pending</div> : <></>}
        </td>
        <td
            className={classNames(
                "whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"
            )}
        >
            {person.accountActivated ?
                <>
                <NavLink to={`/users/${person.id}`}>
                    Profile
                </NavLink>

                </>
                :
                <></>
            }
        </td>
    </tr>;
}