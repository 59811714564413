import React, {useEffect, useState} from 'react';
import {getComplaints} from '../../services/complaints';
import {NavLink} from 'react-router-dom';
import {
    ChatAltIcon,
    DotsHorizontalIcon,
    ExclamationCircleIcon,
    OfficeBuildingIcon,
    TruckIcon
} from "@heroicons/react/outline";
import ScaleLoader from "react-spinners/ScaleLoader";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Complaints({setMobileNavigation}) {
    const [complaints, setComplaints] = useState([]);
    const [loading, setLoading] = useState(true);

    setMobileNavigation("Complaints")
    useEffect(() => {
        let mounted = true;
        getComplaints()
            .then(resp => {
                if (mounted) {
                    setComplaints(resp.body);
                    setLoading(false);
                }
            });
        return () => mounted = false;
    }, []);


    return loading ?
        <main className="lg:col-span-7 xl:col-span-7">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :complaints.length > 0 ?
        <>
            <main className="lg:col-span-12 xl:col-span-6 order-2 xl:order-1">
                <div>
                    <ul className="space-y-4">
                        {complaints.map(complaint => complaint.directedAtMe ?
                            <li key={complaint.id}
                                className="bg-white px-4 py-6 px-4 sm:p-6 rounded-lg border-2 border-red-300 mx-4 sm:mx-0"
                                style={{"box-shadow": "0px 0px 10px 5px #fcc"}}>
                                <NavLink to={"/complaints/" + complaint.id}
                                >
                                    <article aria-labelledby={'question-title-' + complaint.id}>
                                        <div>
                                            <div className="flex space-x-3 text-sm">

                                                {complaint.isResolved ?
                                                    <>
                                                        <div
                                                            className="text-xs inline-flex items-center leading-sm px-3 py-1 bg-green-200 text-green-700 rounded-full"
                                                        >Complaint resolved
                                                        </div>
                                                        {complaint.complaintType === "property" ? <>
                                                            <OfficeBuildingIcon
                                                                className="flex-shrink-0 h-4 mt-1 w-4"/><span
                                                            className="ml-2">{complaint.label}</span></> : <>
                                                            <TruckIcon
                                                                className="flex-shrink-0 h-4 mt-1 w-4"/><span
                                                            className="ml-2">{complaint.label}</span></>}
                                                    </>
                                                    :

                                                    <>
                                                        <ExclamationCircleIcon
                                                            className="flex-shrink-0 -ml-1 h-6 w-6 bg- text-red-700"
                                                        />
                                                        <div
                                                            className="text-xs inline-flex items-center leading-sm px-3 py-1 bg-red-200 text-red-700 rounded-full"
                                                        >Complaint received
                                                        </div>
                                                        {complaint.complaintType === "property" ? <>
                                                            <OfficeBuildingIcon
                                                                className="flex-shrink-0 mt-1 h-4 w-4"/><span
                                                            className="ml-2">{complaint.label}</span></> : <>
                                                            <TruckIcon
                                                                className="flex-shrink-0 mt-1 h-4 w-4"/><span
                                                            className="ml-2">{complaint.label}</span></>}
                                                    </>
                                                }

                                            </div>
                                            <h2 id={'question-title-' + complaint.id}
                                                className="mt-4 text-base font-medium text-gray-900">
                                                {complaint.title}
                                            </h2>
                                        </div>
                                        <div
                                            className="mt-2 text-sm text-gray-700 space-y-4"
                                            dangerouslySetInnerHTML={{__html: complaint.content}}
                                        />
                                        <div className="mt-6 flex justify-between space-x-8">
                                            <div className="flex space-x-6">

                                    <span className="inline-flex items-center text-sm">
                                        <ChatAltIcon className="h-5 w-5 text-gray-500 mr-2" aria-hidden="true"/>
                                        <span
                                            className="font-medium text-gray-500">{complaint.complaintCommentCount}</span>
                                    </span>
                                            </div>
                                        </div>
                                    </article>
                                </NavLink>
                            </li>

                            :

                            <li key={complaint.id} className="bg-white px-4 py-6 shadow p-4 sm:p-6 rounded-lg mx-4 sm:mx-0">
                                <NavLink to={"/complaints/" + complaint.id}
                                >
                                    <article aria-labelledby={'question-title-' + complaint.id}>
                                        <div>
                                            <div className="flex space-x-3 text-sm">
                                                {complaint.isResolved ?
                                                    <>
                                                        <div
                                                            className="text-xs inline-flex items-center leading-sm px-3 py-1 bg-green-200 text-green-700 rounded-full"
                                                        >Complaint resolved
                                                        </div>

                                                        {complaint.complaintType === "property" ? <>
                                                            <OfficeBuildingIcon
                                                                className="flex-shrink-0 mt-1 h-4 w-4"/><span
                                                            className="ml-2">{complaint.label}</span></> : <>
                                                            <TruckIcon
                                                                className="flex-shrink-0 mt-1 h-4 w-4"/><span
                                                            className="ml-2">{complaint.label}</span></>}
                                                    </>
                                                    :

                                                    <>
                                                        <div
                                                            className="text-xs inline-flex items-center leading-sm px-3 py-1 bg-blue-200 text-blue-700 rounded-full"
                                                        >Complaint lodged
                                                        </div>

                                                        {complaint.complaintType === "property" ? <>
                                                            <OfficeBuildingIcon
                                                                className="flex-shrink-0 mt-1 h-4 w-4"/><span
                                                            className="ml-2">{complaint.label}</span></> : <>
                                                            <TruckIcon
                                                                className="flex-shrink-0 mt-1 h-4 w-4"/><span
                                                            className="ml-2">{complaint.label}</span></>}
                                                    </>
                                                }
                                            </div>
                                            <h2 id={'question-title-' + complaint.id}
                                                className="mt-4 text-base font-medium text-gray-900">
                                                {complaint.title}
                                            </h2>
                                        </div>
                                        <div
                                            className="mt-2 text-sm text-gray-700 space-y-4"
                                            dangerouslySetInnerHTML={{__html: complaint.content}}
                                        />
                                        <div className="mt-6 flex justify-between space-x-8">
                                            <div className="flex space-x-6">

                                    <span className="inline-flex items-center text-sm">
                                        <ChatAltIcon className="h-5 w-5 text-gray-500 mr-2" aria-hidden="true"/>
                                        <span
                                            className="font-medium text-gray-500">{complaint.complaintCommentCount}</span>
                                    </span>
                                            </div>
                                        </div>
                                    </article>
                                </NavLink>
                            </li>)}
                    </ul>
                </div>
            </main>
            <aside className="mb-4 lg:mt-0 xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2">
                <div className="sticky top-4 space-y-4">
                    <section aria-labelledby="trending-heading">
                        <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">

                            <div className="sm:p-4 p-6 ">
                                <PrimaryButton to={"/complaints/new"}>
                                    Lodge a complaint
                                </PrimaryButton>
                            </div>
                        </div>
                    </section>
                    {/*another section*/}
                </div>
            </aside>
        </>

        :

            <main className="lg:col-span-12 xl:col-span-8">
                <div className="w-2/3 m-auto text-center items-center text-4xl mt-12">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="text-gray-300 w-32 m-auto">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div className={"mt-16 mb-32 text-gray-300 font-medium"}>
                        Nothing to see here
                    </div>
                <PrimaryButton to={"/complaints/new"}>
                    Make a complaint
                </PrimaryButton>
            </div>
        </main>
}

