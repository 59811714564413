import getToken from "./token";

export function getPassword() {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/password`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function getProfile(id) {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/users/` + id, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitProfile(id, postDetails) {
    const formData = new FormData();
    formData.append('selectedFile', postDetails.selectedFile);
    formData.append('confirmPassword', postDetails.confirmPassword);
    formData.append('firstName', postDetails.firstName);
    formData.append('lastName', postDetails.lastName);
    formData.append('email', postDetails.email);
    formData.append('emailVisible', postDetails.emailVisible);

    return fetch(`${process.env.REACT_APP_API}/v1/directory/users/` + id, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        },
        body: formData,
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitPasswordChange(passwordDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/password`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(passwordDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function deleteAccount(deleteDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/users/delete`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        body: JSON.stringify(deleteDetails),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function leaveGroup(deleteDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/users/leave`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        body: JSON.stringify(deleteDetails),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function stepDown(stepDownDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/users/step-down`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        body: JSON.stringify(stepDownDetails),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function promote(promoteDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/users/promote`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        body: JSON.stringify(promoteDetails),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function revoke(revokeDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/users/revoke`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        body: JSON.stringify(revokeDetails),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function requestMFA({password}) {
    const payload = {
        password,
    };
    return fetch(`${process.env.REACT_APP_API}/v1/mfa/request`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function recoverMFA({recoveryPhrase, email, password}) {
    const payload = {
        recoveryPhrase,
        email,
        password,
    };
    return fetch(`${process.env.REACT_APP_API}/v1/mfa/recover`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function doConfirmMFA({confirmMFA}) {
    const payload = {
        confirmMFA,
    };
    return fetch(`${process.env.REACT_APP_API}/v1/mfa/confirm`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function doConfirmRecoverMFA({confirmMFA, email, password}) {
    const payload = {
        confirmMFA,
        email,
        password,
    };
    return fetch(`${process.env.REACT_APP_API}/v1/mfa/recover/confirm`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function disableMFA({password}) {
    const payload = {
        password,
    };
    return fetch(`${process.env.REACT_APP_API}/v1/mfa/disable`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(payload),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}