import React, { useEffect, useRef } from 'react';

const ScriptBlock = ({ children }) => {
    const scriptRef = useRef(null);

    useEffect(() => {
        // Load the script
        const script = document.createElement('script');
        script.innerHTML = scriptRef.current.innerHTML;
        document.body.appendChild(script);

        // Remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div style={{ display: 'none' }} ref={scriptRef}>{children}</div>;
};

export default ScriptBlock;
