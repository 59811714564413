import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {getNewUserForm, setupNewUser} from "../../services/new-user";
import Select from "react-tailwindcss-select";
import {Switch} from '@headlessui/react'
import ScaleLoader from "react-spinners/ScaleLoader";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NewUser({setToken, setNewUser, setErrorMessage}) {

    const [hasProperties, setHasProperties] = useState(false);
    const [hasNoProperties, setHasNoProperties] = useState(false);
    const [properties, setProperties] = useState([]);
    const [hasCars, setHasCars] = useState(false);
    const [hasNoCars, setHasNoCars] = useState(false);
    const [residenceName, setResidenceName] = useState();
    const [registration, setRegistration] = useState("");
    const [registrations, setRegistrations] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [canAddProperties, setCanAddProperties] = useState(false);
    const [loading, setLoading ] =useState(true)


    useEffect(() => {
        let mounted = true;
        getNewUserForm()
            .then(resp => {
                if (mounted) {
                    setProperties(resp.body.properties);
                    setResidenceName(resp.body.residenceName);
                    setCanAddProperties(resp.body.canAddProperties)
                    setLoading(false)
                }
            }).catch(error => {
        });
        return () => mounted = false;
    }, []);

    const handleAdd = async e => {
        setRegistrations([...registrations, registration]);
        setRegistration("")
    };

    const removeTags = index => setRegistrations([...registrations.filter(tag => registrations.indexOf(tag) !== index)]);

    const handleSubmit = async e => {
        e.preventDefault();
        try {

            setErrorMessage("")
            const {status} = await setupNewUser({
                hasProperties,
                hasNoProperties,
                hasCars,
                hasNoCars,
                selectedProperties,
                registrations,
            });

            if (status === 200) {
                setNewUser(false);
                return <Navigate to={"/dashboard"}/>
            } else {
                setErrorMessage("Unable to setup new user")
            }
        } catch (error) {
            setErrorMessage("Unable to setup new user")
        }
    };

    const registrationTiles = [];
    for (let i = 0; i < registrations.length; i++) {
        // note: we are adding a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        const reg = registrations[i];
        registrationTiles.push(
            <div className="mt-4">
                <div className="bg-yellow-300 font-bold text-5xl text-black text-center p-4 rounded-md" key={i}>
                    {reg}
                </div>
                <PrimaryButton
                    onClick={() => removeTags(i)}
                >
                    Remove
                </PrimaryButton>
            </div>
        );
    }

    return loading ?
        <main className="lg:col-span-10 xl:col-span-7">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :<>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div className={"text-3xl text-align-center"}>Tell us about yourself</div>
                        {canAddProperties ?
                            <>
                                <h1>Properties</h1>
                                {
                                    !hasNoProperties ?
                                        <div>
                                            <label htmlFor="hasProperties"
                                                   className="block text-sm font-medium text-gray-700 mb-4">
                                                I own one or more properties at {residenceName}
                                            </label>
                                            <Switch
                                                id={"hasProperties"}
                                                name="hasProperties"
                                                checked={hasProperties}
                                                onChange={setHasProperties}
                                                disabled={hasNoProperties}
                                                className={classNames(
                                                    hasProperties ? 'bg-orange-500' : 'bg-gray-200',
                                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
                                                )}
                                            >
                                                <span className="sr-only">Use setting</span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        hasProperties ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                    )}
                                                />
                                            </Switch>
                                        </div>
                                        :
                                        <div></div>
                                }

                                {
                                    hasProperties ?
                                        <div>
                                            <label htmlFor="properties"
                                                   className="block text-sm font-medium text-gray-700  mb-4">
                                                Please select the properties that you own
                                            </label>
                                            <Select
                                                name="properties"
                                                value={selectedProperties}
                                                isMultiple={true}
                                                isSearchable={true}
                                                onChange={setSelectedProperties}
                                                options={properties}
                                            />
                                        </div>
                                        :
                                        <div></div>
                                }

                                {!hasProperties ?
                                    <div>
                                        <label htmlFor="firstName"
                                               className="block text-sm font-medium text-gray-700  mb-4">
                                            I do not own any properties at {residenceName}
                                        </label>
                                        <Switch
                                            id={"hasNoProperties"}
                                            checked={hasNoProperties}
                                            disabled={hasProperties}
                                            onChange={setHasNoProperties}
                                            className={classNames(
                                                hasNoProperties ? 'bg-orange-500' : 'bg-gray-200',
                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
                                            )}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    hasNoProperties ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                )}
                                            />
                                        </Switch>
                                    </div>
                                    :
                                    <div></div>}
                            </>
                            :

                            <></>
                        }

                        <hr/>
                        <h1>Vehicles</h1>

                        {!hasNoCars ?

                            <div>
                                <label htmlFor="hasCars" className="block text-sm font-medium text-gray-700  mb-4">
                                    I park one or more vehicles at {residenceName}
                                </label>
                                <Switch
                                    id={"hasCars"}
                                    name="hasCars"
                                    checked={hasCars}
                                    onChange={setHasCars}
                                    disabled={hasNoCars}
                                    className={classNames(
                                        hasCars ? 'bg-orange-500' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            hasCars ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                            </div>

                            :
                            <div></div>}

                        {
                            hasCars ?
                                <div>
                                    <label htmlFor="registration"
                                           className="block text-sm font-medium text-gray-700">
                                        Add the registrations of all the vehicles you park at {residenceName}
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                            <input
                                                type="text"
                                                onChange={e => setRegistration(e.target.value)}
                                                value={registration}
                                                name="registration"
                                                id="registration"
                                                className="block w-full rounded-none rounded-l-md border-gray-300 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            disabled={registration.length === 0}
                                            onClick={handleAdd}
                                            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500 disabled:bg-gray-200"
                                        >
                                            <span>Add</span>
                                        </button>
                                    </div>
                                    {registrationTiles}

                                </div>
                                :
                                <div></div>
                        }

                        {!hasCars ?

                            <div>
                                <label htmlFor="hasNoCars" className="block text-sm font-medium text-gray-700 mb-4">
                                    I do not park any vehicles at {residenceName}
                                </label>
                                <Switch
                                    id={"hasNoCars"}
                                    name="hasNoCars"
                                    checked={hasNoCars}
                                    onChange={setHasNoCars}
                                    disabled={hasCars}
                                    className={classNames(
                                        hasNoCars ? 'bg-orange-500' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            hasNoCars ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                            </div>

                            :
                            <div></div>}

                        <div>
                            <PrimaryButton
                                disabled={!((!canAddProperties || (canAddProperties && (hasProperties || hasNoProperties))) && (hasCars || hasNoCars))}
                                className="flex w-full justify-center rounded-md border border-transparent bg-orange-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:bg-orange-300"
                            >
                                Confirm
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>


}

