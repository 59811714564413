import React, {useEffect, useState} from 'react';
import {
    cancelSubscription,
    getSubscription,
    reactivateSubscription,
    updateSubscription
} from '../../../services/directory';
import {CashIcon} from "@heroicons/react/outline";
import ScaleLoader from "react-spinners/ScaleLoader";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Subscription({setErrorMessage, setMobileNavigation}) {
    setMobileNavigation("Subscription")
    const [subscription, setSubscription] = useState(0);
    const [users, setUsers] = useState(0);
    const [status, setStatus] = useState("");
    const [periodEnd, setPeriodEnd] = useState("");
    const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(false);
    const [originalSubscription, setOriginalSubscription] = useState(0);
    useEffect(() => {
        let mounted = true;
        getSubscription()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setSubscription(resp.body.quantity);
                        setStatus(resp.body.status);
                        setPeriodEnd(resp.body.periodEnd);
                        setUsers(resp.body.users);
                        setCancelAtPeriodEnd(resp.body.cancelAtPeriodEnd);
                        setOriginalSubscription(resp.body.quantity);
                        setLoading(false);
                    }
                } else {
                    window.location.href = '/directory'
                }
            });
        return () => mounted = false;
    }, []);
    const [loading, setLoading] = useState(true);


    const handleSubscriptionChange = newValue => {
        let licenseCount = newValue.target.value;

        if (licenseCount < users) {
            licenseCount = users
        }
        setSubscription(licenseCount);
    };

    const handleUpdate = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")

            const number = Number(subscription);
            const {status} = await updateSubscription({
                subscription: number,
            });

            if (status === 200) {
                window.location.href = "/directory/subscription"
            } else {
                setErrorMessage("Unable to update subscription")
            }
        } catch (error) {
            setErrorMessage("Unable to update subscription")
        }
    };

    const handleCancel = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await cancelSubscription();

            if (status === 200) {
                window.location.href = "/directory/subscription"
            } else {
                setErrorMessage("Unable to cancel subscription")
            }
        } catch (error) {
            setErrorMessage("Unable to cancel subscription")
        }
    };

    const handleReactivate = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await reactivateSubscription();

            if (status === 200) {
                window.location.href = "/directory/subscription"
            } else {
                setErrorMessage("Unable to reactivate subscription")
            }
        } catch (error) {
            setErrorMessage("Unable to reactivate subscription")
        }

    };

    let diff = subscription - originalSubscription;

    return loading ?
        <main className="lg:col-span-7 xl:col-span-7">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :<main className="lg:col-span-12 xl:col-span-10">
        <div className="sticky top-4 space-y-4 ">
            <section aria-labelledby="trending-heading">
                <div className="bg-white rounded-lg shadow">
                    <div className="p-6">
                        <div id="trending-heading" className="text-base font-medium text-gray-900">
                            <CashIcon
                                className={classNames(
                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                )}

                                aria-hidden="true"
                            />
                            <span className="truncate">Subscription</span>
                            {cancelAtPeriodEnd  ?
                                <>
                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="status"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Subscription status
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={`${status} (expiring)`}
                                                type="text"
                                                disabled={true}
                                                name="status"
                                                id="status"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="renews"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Expires on
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={periodEnd}
                                                type="text"
                                                disabled={true}
                                                name="renews"
                                                id="renews"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <form onSubmit={handleReactivate}>
                                        <div>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <button
                                                        type="submit"
                                                        className="inline-flex items-center justify-center  bg-white hover:bg-gray-100 text-gray-500 font-medium py-2 px-4 border border-gray-300 rounded">

                                                        Reactivate subscription
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </>
                                :
                                <>
                                    <form onSubmit={handleUpdate}>
                                        <div className="space-y-6 sm:space-y-5 mt-4">
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="license-count"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    License count
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <input
                                                        onChange={handleSubscriptionChange}
                                                        value={subscription}
                                                        type="number"
                                                        name="license-count"
                                                        id="license-count"
                                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="user-count"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Current user count
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <input
                                                        value={users}
                                                        type="text"
                                                        disabled={true}
                                                        name="user-count"
                                                        id="user-count"
                                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="status"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Subscription status
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <input
                                                        value={status.charAt(0).toUpperCase() + status.slice(1)}
                                                        type="text"
                                                        disabled={true}
                                                        name="status"
                                                        id="status"
                                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="renews"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    { status === "trialing" ? "Expires on" :  "Renews on" }
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <input
                                                        value={periodEnd}
                                                        type="text"
                                                        disabled={true}
                                                        name="renews"
                                                        id="renews"
                                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5 justify-items-end">
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <button
                                                        disabled={diff === 0}
                                                        type="submit"
                                                        className="inline-flex disabled:bg-orange-300 items-center px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                                                    >
                                                        {diff === 0 ? "Update subscription" : diff > 0 ? `Update subscription (+${diff})` : `Update subscription (${diff})`}

                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <form onSubmit={handleCancel}>
                                        <div>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5 justify-items-end">
                                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    <button
                                                        type="submit"
                                                        className="inline-flex items-center justify-center  bg-white hover:bg-gray-100 text-gray-500 font-medium py-2 px-4 border border-gray-300 rounded">

                                                        Cancel subscription
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </section>
            {/*another section*/}
        </div>
    </main>
}

