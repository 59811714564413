import React, {useEffect, useState} from 'react';
import {getDirectory} from '../../services/directory';
import {NavLink} from 'react-router-dom';
import {BadgeCheckIcon, MailIcon, UploadIcon} from "@heroicons/react/outline";
import {inviteShareholder} from "../../services/invite";
import DirectoryRow from "./DirectoryRow/DirectoryRow";
import ScaleLoader from "react-spinners/ScaleLoader";
import {uploadContactSheet, uploadFile} from "../../services/meetings";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Directory({setErrorMessage, setMobileNavigation}) {
    const [directory, setDirectory] = useState({
        users: [],
    });
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [role, setRole] = useState("shareholder");
    const [loading, setLoading ] =useState(true)
    const [selectedFile, setSelectedFile] = useState(null);
    setMobileNavigation("Directory")
    useEffect(() => {
        let mounted = true;
        setErrorMessage("")
        try {
            getDirectory()
                .then(resp => {
                    if (resp.status === 200) {
                        if (mounted) {
                            setDirectory(resp.body);
                            setLoading(false)
                        }
                    } else {
                        setErrorMessage("Unable to load directory");
                    }
                });
        } catch (error) {
            setErrorMessage("Unable to load directory");
        }
        return () => mounted = false;
    }, []);

    const handleUpload = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {status} = await uploadContactSheet({
                file: selectedFile,
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to upload file")
            }
        } catch (error) {
            setErrorMessage("Unable to upload file")
        }
    };

    const handleInviteShareholder = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {status} = await inviteShareholder({
                firstName,
                lastName,
                email,
                role,
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to invite shareholder")
            }
        } catch (error) {
            setErrorMessage("Unable to invite shareholder")
        }

    };


    return loading ?
        <main className="lg:col-span-12 xl:col-span-7">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :
    <>
        <main className="lg:col-span-12 xl:col-span-7 mb-4 order-2 xl:order-1">
            <div className="px-4 sm:px-6 lg:px-8 mx-4 sm:mx-0 mt-4 sm:mt-0">
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                <table className="min-w-full border-separate" style={{borderSpacing: 0}}>
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="sticky col-span-3 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                        >
                                            First Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky col-span-3 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                        >
                                            Last Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky hidden sm:block col-span-3 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                        >
                                            Role
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky  col-span-3 top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                    {directory.users.map((person, personIdx) => <DirectoryRow key={personIdx} person={person} isAdmin={directory.isAdmin}/>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        {directory.isAdmin ?
            <aside className="sm:mt-4 xl:mt-0 xl:block xl:col-span-3 lg:col-span-12 lg:mt-0  order-1 xl:order-2">

                {directory.usedLicenses >= directory.totalLicenses ?
                    <div className="sticky top-4 space-y-4">

                        <section aria-labelledby="trending-heading">
                            <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                <div className="p-4 sm:p-6">
                                    <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                        <BadgeCheckIcon
                                            className={classNames(
                                                'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                            )}

                                            aria-hidden="true"
                                        />
                                        <span className="truncate">Invite Shareholders</span>
                                    </div>


                                    <div className="group gap-2 mt-6 text-center">
                                        <dd className="mb-4 text-4xl font-semibold text-gray-900">{directory.usedLicenses} / {directory.totalLicenses}</dd>
                                    </div>
                                    <div className="groupgap-2 mt-2 text-center">
                                        <dt className="font-medium text-gray-500 truncate">Licenses used</dt>
                                    </div>
                                    <div className="group flex grid-cols-2 gap-2 mt-2">
                                        <NavLink to={"/directory/subscription"}
                                                 className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700">
                                            View subscription
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    :
                    <div className="sticky top-4 space-y-4">

                        <section aria-labelledby="trending-heading">
                            <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                <div className="p-4 sm:p-6">
                                    <form onSubmit={handleInviteShareholder}>
                                        <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                            <MailIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Invite Shareholders</span>
                                        </div>


                                        <div className="group gap-2 mt-6 text-center">
                                            <dd className="mb-4 text-7xl md:text-5xl font-semibold text-gray-900">{directory.usedLicenses} / {directory.totalLicenses}</dd>
                                        </div>
                                        <div className="groupgap-2 mt-2 text-center">
                                            <dt className="font-medium text-gray-500 truncate">Licenses used</dt>
                                        </div>
                                        <div className="group flex grid-cols-2 gap-2 mt-6">
                                            <input
                                                onChange={e => setFirstName(e.target.value)}
                                                value={firstName}
                                                type="text"
                                                name="email"
                                                placeholder="First name"
                                                required={true}
                                                id="email"
                                                autoComplete="email"
                                                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                            <input
                                                onChange={e => setLastName(e.target.value)}
                                                value={lastName}
                                                type="text"
                                                name="email"
                                                placeholder="Last name"
                                                required={true}
                                                id="email"
                                                autoComplete="email"
                                                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                            <input
                                                onChange={e => setEmail(e.target.value)}
                                                value={email}
                                                type="text"
                                                name="email"
                                                required={true}
                                                placeholder="Email"
                                                id="email"
                                                autoComplete="email"
                                                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                            <select
                                                id="role"
                                                name="role"
                                                value={role}
                                                required={true}
                                                onChange={e => setRole(e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                            >
                                                <option value={"shareholder"}>Shareholder</option>
                                                <option value={"agent"}>Agent</option>
                                            </select>
                                        </div>
                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                            <button type={"submit"}
                                                    className="w-full flex items-center justify-center px-4 py-2 border border-2 border-orange-500 text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                                            >
                                                Invite
                                            </button>
                                        </div>

                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                            <NavLink to={"/directory/subscription"}
                                                     className="w-full flex items-center justify-center  bg-white hover:bg-gray-100 text-gray-500 font-medium py-2 px-4 border border-gray-300 rounded">
                                                View subscription
                                            </NavLink>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                        {directory.canUploadContactSheets ?
                            <section>
                                <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                    <div className="p-4 sm:p-6">
                                        <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                            <UploadIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Upload Contacts</span>
                                        </div>
                                    </div>
                                    <div className="pl-6 pr-6 pb-6">
                                        <div>
                                            <p className="text-sm mb-4">
                                                Upload a contact sheet with the names and email addresses of the shareholders and agents you want to invite, and we'll invite them for you!
                                            </p>
                                        </div>
                                        <form onSubmit={handleUpload}>
                                            <input onChange={e => setSelectedFile(e.target.files[0])} type="file" required={true}
                                                   className="file:mr-4 file:py-2 file:px-4 bg-white border rounded-br-md rounded-tr-md file:rounded-bl-md file:rounded-tl-md file:border-0 file:font-medium file:bg-orange-500 file:text-white file:hover:cursor-pointer hover:file:bg-orange-600 file-input file-input-bordered file-input-accent w-full max-w-xs"/>
                                            <button disabled={selectedFile === null} type="submit"
                                                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700 mt-6">
                                                Upload
                                            </button>
                                        </form>
                                    </div>

                                </div>
                            </section>
                            :
                            <section>
                                <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                    <div className="p-4 sm:p-6">
                                        <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                            <UploadIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Upload Contacts</span>
                                        </div>
                                    </div>
                                    <div className="pl-6 pr-6 pb-6">
                                        <div>
                                            <p className="text-sm mb-4">
                                                Your contact sheet has been uploaded; if we have any questions we'll reach out to you.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        }
                    </div>
                }
            </aside>

            :

            <></>}

    </>
}

