import {useState} from 'react';

export default function useUser() {
    const getUser = () => {
        const userString = localStorage.getItem('user');
        try {
            const userUser = JSON.parse(userString);
            return userUser
        } catch (e) {
            return false
        }
    };

    const [user, setUser] = useState(getUser());

    const saveUser = userUser => {
        localStorage.setItem('user', JSON.stringify(userUser));
        setUser(userUser);
    };

    return {
        setUser: saveUser,
        user
    }
}
