import getToken from './token'

export function getPosts() {
    return fetch(`${process.env.REACT_APP_API}/v1/post`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function getPost(id) {
    return fetch(`${process.env.REACT_APP_API}/v1/post/` + id, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitNewPost(postDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/post`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(postDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}


export function getHelp() {
    return fetch(`${process.env.REACT_APP_API}/v1/help`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function contactUs(postDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/contact`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(postDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitNewAnnouncement(postDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/announcements/new`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(postDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitNewComment(id, commentDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/post/` + id + '/comment', {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(commentDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function editComment(id, commentDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/post/` + id + '/comment/' + commentDetails.commentID, {
        method: 'PATCH',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(commentDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function deleteComment(id, commentID) {
    return fetch(`${process.env.REACT_APP_API}/v1/post/` + id + '/comment/' + commentID, {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function editPost(id, content) {
    return fetch(`${process.env.REACT_APP_API}/v1/post/` + id, {
        method: 'PATCH',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(content),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}


export function deletePost(id) {
    return fetch(`${process.env.REACT_APP_API}/v1/post/` + id, {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

