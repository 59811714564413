import {useState} from 'react';

export default function useNavigation() {
    const getNavigation = () => {
        const navigationString = localStorage.getItem('navigation');
        try {
            const userNavigation = JSON.parse(navigationString);
            return userNavigation
        } catch (e) {
            return []
        }
    };

    const [navigation, setNavigation] = useState(getNavigation());

    const saveNavigation = userNavigation => {
        localStorage.setItem('navigation', JSON.stringify(userNavigation));
        setNavigation(userNavigation);
    };

    return {
        setNavigation: saveNavigation,
        navigation
    }
}
