import React, {useState} from "react";
import {submitNewPost} from "../../../services/posts";
import {NewspaperIcon} from "@heroicons/react/outline";
import {PrimaryButton} from "../../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NewPost({setErrorMessage, setMobileNavigation}) {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    setMobileNavigation("New Post")
    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")
            const {status} = await submitNewPost({
                title: title,
                content: content,
                timestamp: new Date().toISOString(),
            });

            if (status === 200) {
                window.location.href = "/posts"
            } else {
                setErrorMessage("Unable to submit new post")
            }
        } catch (error) {
            setErrorMessage("Unable to submit new post")
        }
    };

    return         <><main className="lg:col-span-12 xl:col-span-6 bg-white shadow p-4 sm:p-6 rounded-lg mx-4 sm:mx-0 order-2 xl:order-1">
    <div id="trending-heading" className="hidden sm:block font-normal text-xl sm:font-medium text-gray-900">
            <NewspaperIcon
                className={classNames(
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                )}

                aria-hidden="true"
            />
            <span className="truncate">Create a new post</span>
        </div>
        <div className="mt-2 sm:mt-6">
            <form onSubmit={handleSubmit}>
                <textarea
                    rows={1}
                    name="title"
                    id="title"
                    value={title}
                    required={true}
                    onChange={e => setTitle(e.target.value)}
                    className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                    placeholder="Add your title..."
                />
                <textarea
                    rows={10}
                    name="content"
                    id="content"
                    value={content}
                    required={true}
                    onChange={e => setContent(e.target.value)}
                    className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                    placeholder="Add your content..."
                />
                <div className="mt-2 flex justify-end">


                    <PrimaryButton>Create new post</PrimaryButton>

                </div>
            </form>
        </div>
    </main>
        <aside className="mb-2 xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2">
            <div className="sticky top-4 space-y-4">
                <section aria-labelledby="trending-heading">
                    <div className="bg-white rounded-lg shadow mb-2 sm:mb-4 mx-4 sm:mx-0">
                        <p className={"sm:p-4 p-6"}>
                            Posts are viewable by everyone in the management company, including tenants
                        </p>
                    </div>
                </section>
            </div>
        </aside>
    </>
}
