export default function Privacy() {
    return <div className="m-8">
        <h1 className="text-4xl mb-10 font-bold tracking-tight text-gray-900 sm:text-6xl">Privacy policy</h1>
        <p className="relative mt-6 mb-6 text-lg leading-8 text-gray-600">
            Last updated: 24.08.23
        </p>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Important Information and who we are
        </h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We value your privacy very highly. Please read this privacy policy carefully before using the Votria website (Website) as this privacy policy contains important information regarding your privacy and how we may use the information we collect about you.
            </p>
            <p className="mt-6">
                By accessing or using the Website, you agree to be bound by this privacy policy. If you disagree with any part of the privacy policy, then you must not use or access the Website.
            </p>
            <p className="mt-6">
                STDIN Ltd, a private limited company incorporated in Northern Ireland, with a registration number of NI687258 (referred to hereinafter as us, we or our) is the controller and responsible for your personal data
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            How we collect your personal data
        </h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">

            <p className="relative mt-6 mb-6 text-lg leading-8 text-gray-600">
                We use different methods to collect data from and about you including through:
            </p>

            <p><b>Direct interactions.</b> You may give us identity, contact and financial data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you
            <ul className="list-disc ml-6">
                <li>apply for our products or services;</li>
                <li>create an account on our website;</li>
                <li>subscribe to our service; and</li>
                <li>give us feedback or contact us.</li>
            </ul>

            </p>
            <p className="mt-6">
                <b>Automated technologies or interactions.</b> As you interact with our website, we will automatically collect technical data about your equipment, browsing actions and patterns. We collect this personal data by using server logs and other similar technologies.
            </p>
            <p className="mt-6">
                <b>Third parties or publicly available sources.</b> We will receive personal data about you from various third parties and public sources as set out below:
                <ul className="list-disc ml-6">
                    <li>Companies House used to get director names and correspondence addresses;</li>
                    <li>Ideal postcodes used to fetch a list of property addresses for a given postcode;</li>
                    <li>Directors provide names and email addresses os shareholders and managing agents; and</li>
                    <li>Shareholders provide names and email addresses of their tenants.</li>
                </ul>
            </p>
            <p className="mt-6">
                <b>Technical Data from the following parties:</b>
                <ul className="list-disc ml-6">
                    <li>Analytics providers such as honeycomb.io;</li>
                    <li>Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as gocardless.com; and</li>
                    <li>Identity and Contact Data from publicly available sources such as Companies House and the Electoral Register based inside the UK.</li>
                </ul>
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">What information we collect, how we use it, what happens if we don't have it, and the legal basis for processing this information</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We will only use your personal data when the law allows us to.  Most commonly, we will use your personal data in the following circumstances.
            </p>
                <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                    <li>
                        Where we need to perform the contract we are about to enter into or have entered into with you.
                    </li>
                    <li>
                        Where it is necessary for our legitimate interests (or those of a third party) and your interest and fundamental rights do not override those interests.
                    </li>
                    <li>
                        Where we need to comply with a legal obligation
                    </li>
                </ul>
            <p className="mt-6">
                Generally, we do not rely on consent as a legal basis for processing your personal data, although we may ask for your consent before performing certain actions. You have the right to withdraw consent at any time by contacting us.
            </p>
            <p className="mt-6">
                We may collect, store and transfer different kinds of personal data about you which we have grouped together as follows:
            </p>
        </div>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">

            <table className="table-auto">
                <tbody>

                <tr className="border-b">
                    <td>
                        <b>
                            Category of information
                        </b>
                    </td>
                    <td>
                        <b>
                            Type of Data
                        </b>
                    </td>
                    <td>
                        <b>
                            Legal basis for processing this information
                        </b>
                    </td>
                    <td>
                        <b>
                            How we use this information
                        </b>
                    </td>
                    <td>
                        <b>
                            What happens if we don't have this information
                        </b>
                    </td>
                </tr>
                <tr className="border-b">
                    <td>
                        Identifying Information
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>Name</li>
                            <li>Contact Details (email and telephone)</li>
                            <li>IP Address</li>
                        </ul>
                    </td>
                    <td>
                        Performance of a contract with you
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>Registration of customer account</li>
                                <li>Performing  services;</li>
                                <li>Processing payments;</li>
                                <li>Providing customer service;</li>
                                <li>Verifying customer information;</li>
                                <li>Customer Service</li>
                        </ul>
                    </td>
                    <td>
                        We may not be able to perform the contract we have or are trying to enter into with you
                    </td>
                </tr>
                <tr className="border-b">
                    <td>
                        Transactional / Financial Information
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>Billing address</li>
                                <li>Credit card or debit card number</li>
                                    <li> Card expiration date</li>
                                        <li>Card CVV (security code)</li>
                                            <li> Details about payments to and from you and other details of products and services you have purchased from us</li>
                        </ul>
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>Performance of a contract with you</li>
                            <li>Necessary for our legitimate interests (to recover debts due to us)</li>
                        </ul>
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>Performing services;</li>
                            <li>Processing payments;</li>
                            <li>Providing customer service;</li>
                            <li>Collection and recovery of sums due to us</li>
                        </ul>
                    </td>
                    <td>
                        We may not be able to perform the contract we have or are trying to enter into with you
                    </td>
                </tr>
                <tr className="border-b">
                    <td>
                        Technical Information
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>IP address,</li>
                            <li>login data</li>
                            <li>browser type and version,</li>
                            <li>time zone setting and location</li>
                            <li>browser plug-in types and versions</li>
                            <li>operating system and platform, and other technology on the devices you use to access our services</li>
                        </ul>
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</li>
                            <li>Necessary to comply with a legal obligation</li>
                        </ul>
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>To administer and protect our business (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</li>
                            <li>Detecting security incidents</li>
                            <li>Protecting against malicious, deceptive, fraudulent or illegal activity, and prosecuting those responsible for such activities.</li>
                        </ul>
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>We may not be able to perform the contract we have or are trying to enter into with you</li>
                            <li>Our use of your data for detecting security incidents will not be possible;</li>
                            <li>Our use of your data for protecting against malicious, deceptive, fraudulent or illegal activity will not be possible.</li>
                        </ul>
                    </td>
                </tr>
                <tr className="border-b">
                    <td>
                        Usage Information
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>Username and Password</li>
                                <li>Records of  orders made by you</li>
                                    <li>Information about how you use our website, products and services</li>
                        </ul>
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our services updated and relevant and to develop our business.</li>
                        </ul>
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>To administer and protect our business</li>
                            <li>To use data analytics to improve our website, products/services & customer relationships</li>
                        </ul>
                    </td>
                    <td>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>We may not be able to perform the contract we have or are trying to enter into with you</li>
                            <li>Use of data analytics to improve our website, products/services & customer relationships will not be possible</li>
                        </ul>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Location of data processing</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                All data processing activities undertaken by us take place in United Kingdom and the Netherlands.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Data security</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">

        <p className="mt-6">
            We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
        </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Data retention</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.
            </p>
            <p className="mt-6">
                To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Third Parties</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We may share the following personal information with the following categories of third parties for the purposes set out in the table below. Third parties are also those to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy:
            </p>
        </div>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <div className="mt-10 mb-10 text-base leading-7 text-gray-700">

                <table className="table-auto">
                    <tbody>

                    <tr className="border-b">
                        <td>
                            <b>
                                Category of personal information shared
                            </b>
                        </td>
                        <td>
                            <b>
                                Speciﬁc piece of personal information shared
                            </b>
                        </td>
                        <td>
                            <b>
                                Categories of third parties with whom personal information is/was shared
                            </b>
                        </td>
                    </tr>
                    <tr className="border-b">
                        <td>
                            Identifying information
                        </td>
                        <td>
                            Billing address
                        </td>
                        <td>
                            Financial transactions processors
                        </td>
                    </tr>
                    <tr className="border-b">
                        <td>
                            Financial information
                        </td>
                        <td>
                            Credit card or debit card number
                        </td>
                        <td>
                            Financial transactions processors
                        </td>
                    </tr>
                    <tr className="border-b">
                        <td>
                            Financial information
                        </td>
                        <td>
                            Card expiration date
                        </td>
                        <td>
                            Financial transactions processors
                        </td>
                    </tr>
                    <tr className="border-b">
                        <td>
                            Financial information
                        </td>
                        <td>
                            Card CVV (security code)
                        </td>
                        <td>
                            Financial transactions processors
                        </td>
                    </tr>
                    <tr className="border-b">
                        <td>
                            Internet or other electronic activity
                        </td>
                        <td>
                            Information regarding your interaction with our website or application
                        </td>
                        <td>
                            <ul className="list-disc ml-6 space-y-4">
                                <li>Customer management systems; and</li>
                                <li>Data analytics providers.</li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.
            </p>
            <p className="mt-6">
                We may share your personal data within Digital Ocean EU BV. This will involve transferring your data outside the UK. Whenever we transfer your personal data out of the UK, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
            </p>
            <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                <li>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data.</li>
                <li>Where we use certain service providers, we may use specific contracts approved for use in the UK which give personal data the same protection it has in the UK.</li>
            </ul>
            <p className="mt-6">
                Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the UK.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Opting Out</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                You can ask us or third parties to stop sending you messages at any time by contacting us or the relevant third party at any time. You may contact us at support@votria.social.
            </p>
            <p className="mt-6">
                Where you opt out of receiving messages, this will not apply to personal data provided to us as a result of a product/service purchase, product/service experience or other transactions.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Change of purpose</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.
            </p>
            <p className="mt-6">
                If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
            </p>
            <p className="mt-6">
                Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Your Legal Rights</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                You have the right to:
            </p>
            <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                <li>Request access to your personal data (commonly known as a data subject access request). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
                <li>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                <li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing, where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
                <li>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
                <li>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:</li>
                <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                    <li>If you want us to establish the data's accuracy.</li>
                    <li>Where our use of the data is unlawful but you do not want us to erase it.</li>
                    <li>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</li>
                    <li>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                </ul>
                <li>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
                <li>Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
            </ul>
            <p className="mt-6">
                You may exercise the rights speciﬁed above by submitting a consumer request to:
            </p>
            <p className="mt-6">
                Consumer Requests<br/>
                support@stdin.ltd<br/>
                +442890135200<br/>
                Unit 824<br/>
                Moat House<br/>
                54 Bloomﬁeld Avenue<br/>
                Belfast<br/>
                BT5 5AD<br/>
                United Kingdom<br/>
            </p>
            <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                <li>
                    We will need to verify your identity prior to effectuating your request and may ask you to provide personal information to do so.
                </li>
                <li>
                    We will respond to most consumer requests within 30 days of receipt, depending upon where you reside. However, some requests may take longer. We will notify you in writing if we need more time to respond. We have the ability to deny your request(s) if certain exceptions in the law apply. If we do deny your request, we will provide you with the reasons for such denial.
                </li>
                <li>
                    You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.
                </li>
                <li>
                    We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
                </li>
            </ul>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Questions</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                If you have any questions about this privacy policy, please contact us at support@stdin.ltd.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Glossary</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                <b>Comply with a legal obligation</b> means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.
            </p>
            <p className="mt-6">
                <b>Legitimate Interest</b> means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.
            </p>
            <p className="mt-6">
                <b>Performance of Contract</b> means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.
            </p>
            <p className="mt-6">
                <b>Personal data</b> means any information about an individual from which that person can be identified.  It does not include data where the identity has been removed (anonymous data).
            </p>
        </div>
    </div>

}

