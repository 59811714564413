import {useState} from 'react';

export default function useRunTour() {
    const getRunTour = () => {
        const runTourString = localStorage.getItem('runTour');
        try {
            const userRunTour = JSON.parse(runTourString);
            return userRunTour
        } catch (e) {
            return false
        }
    };

    const [runTour, setRunTour] = useState(getRunTour());

    const saveRunTour = userRunTour => {
        localStorage.setItem('runTour', JSON.stringify(userRunTour));
        setRunTour(userRunTour);
    };

    return {
        setRunTour: saveRunTour,
        runTour
    }
}
