import PropTypes from "prop-types";
import React from "react";

export default function GroupChooser({setToken, groups, setWizard, setNewUser, setNavigation, setSteps, setRunTour, setCanRenew, setExpired, setName, setUser}) {
    const entries = [];

    const chooseGroup = function (i) {
        setToken(groups[i]);
        setWizard(groups[i].runWizard);
        setNewUser(groups[i].runNewUser);
        setRunTour(groups[i].runNewUser);
        setSteps(groups[i].steps);
        setExpired(groups[i].expired);
        setCanRenew(groups[i].canRenew);
        setName(groups[i].managementGroupName);
        setUser(groups[i].user);
        setNavigation(groups[i].navigation);
    };

    for (let i = 0; i < groups.length; i++) {
        entries.push(
            <button onClick={() => chooseGroup([i])}>

            <li key={[i]} className="col-span-1 divide-y divide-gray-200 mx-4 rounded-lg bg-blue-50 shadow">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                                <div className="flex text-lg">
                                    <p className="truncate text-blue-900 font-normal text-gray-900 break-after-auto whitespace-pre-wrap">{groups[i].managementGroupName}</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </li>
            </button>

        )
    }

    return <>
        <div className="min-h-full flex flex-col justify-center py-6 sm:py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="sm:mt-6 ml-4 sm:text-center text-3xl font-semibold tracking-tight text-gray-900">Select a group</h2>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

                    <ul role="list" className="grid grid-cols-1 gap-6">
                        {entries}
                    </ul>
                </div>
            </div>
        </div>
    </>
}

GroupChooser.propTypes = {
    setToken: PropTypes.func.isRequired,
    setWizard: PropTypes.func.isRequired,
    setNewUser: PropTypes.func.isRequired,
};