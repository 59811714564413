export default function ExpiredSubscription() {
    return <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-normal tracking-tight text-gray-900">Subscription expired</h2>
            <p className="mt-6 text-center text-sm font-normal tracking-tight text-gray-900">The subscription for this
            management group has expired. Until the subscription is renewed by a director, you will be unable to proceed.</p>
            <p className="mt-6 text-center text-sm font-normal tracking-tight text-orange-600"><a href={"/logout"}>Logout</a></p>
        </div>
    </div>
}

