import React, { useState, useEffect } from "react";
import {getFinances} from "../../../services/finances";

const tokenURL = `${process.env.REACT_APP_API}/v1/finances/token/create`;
const sendTokenURL = `${process.env.REACT_APP_API}/v1/finances/token/exchange`;

const  Link = () => {
    const [banks, setBanks ] =useState([])

    useEffect(() => {
        let mounted = true;
        getFinances()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setBanks(resp.body.banks);
                    }
                }
            });
        return () => mounted = false;
    }, []);

    return  <div>
            <ul>
                {banks.map(bank => (
                    <li key={bank.id}>{bank.name}</li>
                ))}
            </ul>
        </div>;
}

export default Link;