/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useState} from "react";
import {deleteFile} from "../../../services/meetings";
import {ExclamationCircleIcon, TrashIcon} from "@heroicons/react/outline";

export default function FileListing({id, file, canUpload}) {
    const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);


    const handleDeleteFile = async e => {
        e.preventDefault();
        const {status} = await deleteFile(id, file.id);

        if (status === 200) {
            setDeleteModalVisibility(false);
            window.location.reload()
        }
    };

    return <>
        <li className="flex py-4 space-x-3">
            <a href={file.url} target="_blank" rel={"noreferrer"}>
                    <p className="text-sm font-medium text-gray-800 break-all">{file.name}</p>
            </a>
            {canUpload ?
                <span className="inline-flex text-xs hover:cursor-pointer mt-1"
                      onClick={e => setDeleteModalVisibility(!deleteModalVisibility)}>
                    <TrashIcon className="h-4 w-4 text-gray-500 mr-2" aria-hidden="true"/> Delete
                </span>
                :
                <></>
            }
        </li>
        <Transition.Root show={deleteModalVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setDeleteModalVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 overflow-y-auto">>
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                        <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Delete file?
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {file.name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                        onClick={e => handleDeleteFile(e)}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={e => setDeleteModalVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>
}
