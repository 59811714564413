import {useState} from 'react';

export default function useGroups() {
    const getGroups = () => {
        const groupsString = localStorage.getItem('groups');
        if (groupsString === null) {
            return []
        }
        try {
            const userGroups = JSON.parse(groupsString);
            return userGroups
        } catch (e) {
            return []
        }
    };

    const [groups, setGroups] = useState(getGroups());

    const saveGroups = userGroups => {
        localStorage.setItem('groups', JSON.stringify(userGroups));
        setGroups(userGroups);
    };

    return {
        setGroups: saveGroups,
        groups
    }
}
