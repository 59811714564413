import React, {useEffect, useState} from 'react';
import {getAnnouncements} from '../../services/announcements';
import {DotsHorizontalIcon, SpeakerphoneIcon, UserGroupIcon,} from "@heroicons/react/outline";
import {NavLink} from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Announcements({setMobileNavigation}) {
    const [announcements, setAnnouncements] = useState({
        announcements: [],
        canAnnounce: false,
    });
    const [loading, setLoading ] =useState(true)

    setMobileNavigation("Announcements")
    useEffect(() => {
        let mounted = true;
        getAnnouncements()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setAnnouncements(resp.body);
                        setLoading(false);
                    }
                }
            });
        return () => mounted = false;
    }, []);

    return loading ?
        <main className="lg:col-span-10 xl:col-span-7">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :
        <>
        {announcements.announcements.length > 0 ?
            <>
            <main className="lg:col-span-12 xl:col-span-6 mb-4  order-2 xl:order-1">


                    <ul className="space-y-4">
                        {announcements.announcements.map((update, idx) =>  <li key={idx.id} >
                            <div className="bg-white rounded-lg shadow mb-2 sm:mb-4 mx-4 sm:mx-0">
                                <div className="p-4 sm:p-6">
                                <article aria-labelledby={'question-title-' + idx} className={"border-1 border-gray-300"}>
                                    <div>


                            <p className="text-base font-bold mb-2 text-gray-900">
                                    {update.title}
                                </p>

                                        <div className="flex space-x-3">
                                            <div className="min-w-0 flex-1">
                                                <p className="text-sm text-gray-500">
                                                    {new Date(update.date).toLocaleString()}
                                                </p>
                                            </div>
                                        </div>
                                <p className="mt-6 text-base text-gray-800 whitespace-pre-wrap">
                                    {update.content}
                                </p>
                                    </div>
                            </article>
                                </div>
                            </div>
                        </li>)}
                    </ul>

                </main>
                {announcements.canAnnounce ?
                    <aside className="mb-2 xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2">
                        <div className="sticky top-4 space-y-4">
                            <section aria-labelledby="trending-heading">
                                <div className="bg-white rounded-lg shadow mb-2 sm:mb-4 mx-4 sm:mx-0">
                                    <div className="p-4 sm:p-6">
                                        <PrimaryButton to="/announcements/new">New announcement</PrimaryButton>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </aside>
                    :
                    <></>
                }
            </>
            :
                <main className="lg:col-span-12 xl:col-span-8">
                    <div className="w-2/3 m-auto text-center items-center text-4xl mt-12">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="text-gray-300 w-32 m-auto">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div className={"mt-16 mb-32 text-gray-300 font-medium"}>
                            Nothing to see here
                        </div>
                    {announcements.canAnnounce ?
                        <PrimaryButton to={"/announcements/new"}>
                            Make an announcement
                        </PrimaryButton>
                        :
                        <></>
                    }
                </div>
            </main>
        }
    </>
}

