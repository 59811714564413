import React, {useState} from 'react';

async function requestPasswordReset(credentials) {
    return fetch(`${process.env.REACT_APP_API}/v1/request-password-reset`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
}

export default function ForgotPassword({setErrorMessage}) {
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {status} = await requestPasswordReset({
                email,
            });
            if (status === 200) {
                setSuccess(true);
            } else {
                setErrorMessage("Unable to request password reset")
            }
        } catch (error) {
            setErrorMessage("Unable to request password reset")
        }
    };

    return <>
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="text-orange-500 text-center" style={{fontSize: "4em", fontFamily: `'Fortika', sans-serif`}}>
                        votria
                    </div>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <h2 className="text-center text-2xl font-normal tracking-tight text-gray-900 mb-8">Reset your password</h2>

                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setEmail(e.target.value)}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={success}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                            >
                                Reset password
                            </button>
                        </div>

                        {success ?
                            <div
                                className="flex items-center rounded-md border border-blue-900 text-blue-900 bg-blue-100 text-sm px-4 py-3"
                                role="alert">
                                <p>If an account with this email address exists, a link to reset your password will
                                    be on its way to your inbox!</p>
                            </div> : <div></div>}
                    </form>

                </div>
            </div>
        </div>
    </>
}

