import {Listbox, Transition} from '@headlessui/react'
import {Fragment, useState, React} from 'react'
import Flag from 'react-world-flags'
import countries from './countries.json'
import {getBanks, getFinances, submitBank} from "../../../services/finances";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Dropdown({setErrorMessage}) {
    const [selectedCountry, setSelectedCountry] = useState(countries[0])
    const [selectedBank, setSelectedBank] = useState({})
    const [code, setCode] = useState("")
    const [banks, setBanks] = useState([])

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")
            const {status, body} = await submitBank(
                selectedBank.id,
            )


            if (status === 200) {
                window.location.href = body.redirect
            } else {
                setErrorMessage("Unable to connect bank")
            }
        } catch (error) {
            setErrorMessage("Unable to connect bank")
        }
    };

    const handleBankChange = (bank) => {
        bank.preventDefault()
        setSelectedBank(JSON.parse(bank.target.value))
    }
    const handleCountryChange = (event) => {
        event.preventDefault()

        let c = JSON.parse(event.target.value); //object

        setSelectedCountry(c);


        setCode(c.code);
        setBanks([]);


        if (c.code !== null && c.code !== undefined && c.code !== "") {
            getBanks(c.code)
                .then(resp => {
                    if (resp.status === 200) {
                        setBanks(resp.body.banks);
                        setSelectedBank(resp.body.banks[0]);
                    }
                })
        }
    }

    return (<>
        <div className="lg:col-span-12 xl:col-span-10 bg-white shadow p-6 sm:rounded-lg">
<div id="trending-heading" className="text-base font-medium text-gray-900">
            <span className="truncate">Connect the company bank account</span>
</div>
            <div className="mt-6 flow-root">
                <form onSubmit={handleSubmit}>
                    <p className="mb-6 text-gray-800">
                        Connections remain active for 90 days. After 90 days, you will need to reconnect your bank account.
                    </p>
                    <div className="flex flex-col">
                        <select
                            id="country"
                            name="contry"
                            value={selectedCountry ? JSON.stringify(selectedCountry) : ''}
                            onChange={handleCountryChange}
                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                        >
                            {countries.map((country) => (
                                <option key={country.code} value={JSON.stringify(country)}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {banks.length > 0 ?
                        <div className="flex flex-col">
                            <select
                                id="bank"
                                name="bank"
                                value={selectedBank ? JSON.stringify(selectedBank) : ''}
                                onChange={handleBankChange}
                                className="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                            >
                                {banks.map((bank) => (
                                    <option key={bank.id} value={JSON.stringify(bank)}>
                                        {bank.name}
                                    </option>
                                ))}
                            </select>
                            <button
                                className="mt-4 xl:w-auto xl:float-right flex items-center justify-center px-4 py-2 border border-2 border-orange-500 text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                                onClick={handleSubmit}>Connect bank</button>

                        </div>
                        :
                        <></>
                    }
                </form>
            </div>
        </div>
    </>
    )
}
