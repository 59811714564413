import {ExclamationCircleIcon, PencilIcon, ReplyIcon, TrashIcon} from "@heroicons/react/outline";
import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useState} from "react";
import {deleteComment, editComment, submitNewComment} from "../../../services/posts";
import {NavLink} from "react-router-dom";
import {PrimaryButton} from "../../PrimaryButton/PrimaryButton";

export default function Comment({comment, postID}) {
    const processString = require('react-process-string');

    const [commentBoxVisibility, setCommentBoxVisibility] = useState(false);
    const [editBoxVisibility, setEditBoxVisibility] = useState(false);
    const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [content, setContent] = useState(comment.content);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            let config = [{
                regex: /([\w.]+)@(\w+)\.(\w+)/gim,
                fn: (key, result) => <span key={key}>
                                 <a className={"text-blue-700"} target="_blank" rel={"noreferrer"}
                                    href={`mailto:${result[1]}@${result[2]}.${result[3]}`}>{result[1]}@{result[2]}.{result[3]}</a>
                             </span>
            },
                {
                    regex: /(http|https):\/\/(\S+)/gim,
                    fn: (key, result) => {
                        identifyContentType(result[0]);
                        if (isImage) {
                            return <span key={key}>
                      <img alt="" className="mt-4 mb-4" src={result[0]}/>
                    </span>
                        } else if (isVideo) {
                            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                            var match = result[0].match(regExp);
                            return <iframe
                                style={{
                                    left: 0,
                                    top: 0,
                                    width: "100%",
                                    aspectRatio: "16 / 9",
                                    position: "relative",
                                    marginTop: 8
                                }}
                                key={key}
                                src={`https://www.youtube.com/embed/` + match[7]}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            />
                        } else {
                            return <span key={key}>
                        <a className={"text-blue-700"} target="_blank" href={result[0]} rel={"noreferrer"}>{result[2]}</a>
                    </span>
                        }
                    }
                },
                {
                    regex: /(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
                    fn: (key, result) => <span key={key}>
                                 <a className={"text-blue-700"} rel="noreferrer" target="_blank"
                                    href={`//${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                             </span>
                }];

            setContent(processString(config)(comment.content));
        }
        return () => mounted = false;
    }, [setContent, processString, comment.content, isImage, isVideo]);

    const identifyContentType = function (url) {
        const pURL = new URL(url);
        if (pURL.hostname.endsWith('youtube.com')) {
            setIsVideo(true)
        } else {
            fetch(url, {method: 'HEAD'}).then(res => {
                const contentType = res.headers.get('Content-Type');
                if (contentType.startsWith('image')) {
                    setIsImage(true)
                } else if (contentType.startsWith('video')) {
                    setIsVideo(true)
                }
            })
        }
    };


    const handleEdit = async e => {
        e.preventDefault();
        const {status} = await editComment(postID, {
            commentID: e.target.elements.commentID.value,
            content: e.target.elements.content.value,
            timestamp: new Date().toISOString(),
        });

        if (status === 200) {
            window.location.reload()
        }
    };

    const handleDeleteComment = async (e, commentID) => {
        e.preventDefault();
        const {status} = await deleteComment(postID, commentID);

        if (status === 200) {
            setDeleteModalVisibility(false);
            window.location.reload()
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const {status} = await submitNewComment(postID, {
            commentID: e.target.elements.commentID.value,
            content: e.target.elements.content.value,
            timestamp: new Date().toISOString(),
        });

        if (status === 200) {
            window.location.reload()
        }
    };

    const localDate = new Date(comment.time);

    return <div style={{marginLeft: `${comment.indentation}em`}}>
        <li className={`bg-white shadow p-4 sm:p-6 rounded-lg mx-4 sm:mx-0`}>

            {comment.isDeleted ? <div className="message-body bg-red-50 p-2 text-red-700">
                    Comment deleted by author.
                </div>
                :
                <div>
                    <NavLink to={`/users/${comment.author.id}`}>

                    <h1 className="text-sm font-normal leading-tight text-gray-900 mb-4">
                        <b>{comment.author.firstName} {comment.author.lastName}</b>
                        <span className={"inline-flex items-center ml-2"}>{localDate.toLocaleString()}</span>
                    </h1>
                    </NavLink>
                    {editBoxVisibility ?
                        <form onSubmit={handleEdit} className="mt-8">
                                                    <textarea
                                                        rows={5}
                                                        name="content"
                                                        required={true}
                                                        id="content"
                                                        className="whitespace-pre-wrap shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                                                        defaultValue={comment.content}
                                                    />
                            <input type="hidden" id="commentID" name="commentID"
                                   value={comment.id}/>
                            <PrimaryButton>
                                Update
                            </PrimaryButton>
                        </form>
                        : <p className="subtitle whitespace-pre-wrap">
                            {content}
                        </p>}


                    <span className="inline-flex text-xs hover:cursor-pointer mt-3"
                          onClick={e => setCommentBoxVisibility(!commentBoxVisibility)}>
                                                    <ReplyIcon className="h-4 w-4 text-gray-500 mr-2"
                                                               aria-hidden="true"/> Reply
                                                </span>

                    {comment.isAuthor ?
                        <>
                                                    <span className="inline-flex text-xs hover:cursor-pointer mt-3"
                                                          onClick={e => setEditBoxVisibility(!editBoxVisibility)}>
                                                    <PencilIcon className="h-4 w-4 text-gray-500 mr-2 ml-4"
                                                                aria-hidden="true"/> Edit
                                                </span>
                            <span className="inline-flex text-xs hover:cursor-pointer mt-3"
                                  onClick={e => setDeleteModalVisibility(!deleteModalVisibility)}>
                                                    <TrashIcon className="h-4 w-4 text-gray-500 mr-2 ml-4"
                                                               aria-hidden="true"/> Delete
                                                </span>
                        </>
                        :
                        <></>}

                    {commentBoxVisibility ?
                        <div className="mb-14">
                        <form onSubmit={handleSubmit} className="mt-8">
                                                    <textarea
                                                        rows={5}
                                                        name="content"
                                                        id="content"
                                                        required={true}
                                                        className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                                                        placeholder="Add your content..."
                                                        defaultValue={''}
                                                    />
                            <input type="hidden" id="commentID" name="commentID"
                                   value={comment.id}/>
                            <PrimaryButton>
                                Comment
                            </PrimaryButton>
                        </form>
                        </div>

                        : <></>}


                    {/*delete modal*/}

                    <Transition.Root show={deleteModalVisibility} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={e => setDeleteModalVisibility(false)}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                            </Transition.Child>

                            <div className="fixed top-0 z-10 overflow-y-auto w-full">>
                                <div
                                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel
                                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                            <div>
                                                <div
                                                    className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                                    <ExclamationCircleIcon className="h-6 w-6 text-red-600"
                                                                           aria-hidden="true"/>
                                                </div>
                                                <div className="mt-3 text-center sm:mt-5">
                                                    <Dialog.Title as="h3"
                                                                  className="text-lg font-medium leading-6 text-gray-900">
                                                        Delete comment?
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            {comment.content}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                                    onClick={e => handleDeleteComment(e, comment.id)}
                                                >
                                                    Delete
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                                    onClick={e => setDeleteModalVisibility(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </div>
            }
        </li>
    </div>
}