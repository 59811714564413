import React, {Fragment, useEffect, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {getInviteForm} from "../../services/invite";
import {inviteUser} from '../../services/signup-user';
import {Dialog, Transition} from "@headlessui/react";

export default function Invite({setErrorMessage}) {
    const [setRedirect] = useState(0);
    const [signup, setSignup] = useState({});
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirm, setConfirm] = useState(false);
    const [agree, setAgree] = useState(false);
    const [showTOC, setShowTOC] = useState(false);
    const [success, setSuccess] = useState(0);

    let {id} = useParams();

    useEffect(() => {
        let mounted = true;
        getInviteForm(id)
            .then(resp => {
                if (resp !== undefined) {
                    if (mounted) {
                        setSignup(resp.body);
                        setFirstName(resp.body.firstName);
                        setLastName(resp.body.lastName);
                        setEmail(resp.body.email);
                    }
                } else {
                    setRedirect(1);
                }
            }).catch(error => setRedirect(1));
        return () => mounted = false;
    }, [id, setRedirect]);

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")

            const {status} = await inviteUser(id, {
                firstName,
                lastName,
                email,
                password,
                confirm,
                agree
            });

            if (status === 201) {
                setSuccess(1);
            } else {
                setErrorMessage("Unable to invite user")
            }
        } catch (error) {
            setErrorMessage("Unable to invite user")
        }
    };

    if (success === 1) {
        return <Navigate to={`/invite/${id}/success`}/>
    }

    return <>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-normal tracking-tight text-gray-900">Hello, {signup.firstName}!</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                                First name
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setFirstName(e.target.value)}
                                    value={firstName}
                                    id="firstName"
                                    name="firstName"
                                    type="firstName"
                                    autoComplete="firstName"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                                Last name
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setLastName(e.target.value)}
                                    value={lastName}
                                    id="lastName"
                                    name="lastName"
                                    type="lastName"
                                    autoComplete="lastName"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    value={email}
                                    disabled={true}
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setPassword(e.target.value)}
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    onChange={e => setConfirm(e.target.checked)}
                                    id="confirm"
                                    name="confirm"
                                    type="checkbox"
                                    required
                                    className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                                />
                                <label htmlFor="confirm" className="ml-2 block text-xs text-gray-900">
                                    I am {signup.role}
                                </label>
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    onChange={e => setAgree(e.target.checked)}
                                    id="agree"
                                    name="agree"
                                    type="checkbox"
                                    required
                                    className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                                />
                                <label htmlFor="agree" className="ml-2 block text-xs text-gray-900">
                                    I agree to the <button onClick={() => setShowTOC(true)} className={"text-orange-700"}>terms and conditions</button>
                                </label>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                            >
                                Sign up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <Transition.Root show={showTOC} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setShowTOC(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 overflow-y-auto">>
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className={"whitespace-pre-wrap"}>
                                    Terms and Conditions for Votria<br/>
                                    <br/>
                                    1. Acceptance of Terms<br/>
                                    <br/>
                                    By using the Votria (the "Service"), you agree to be bound by the following terms and conditions (the "Terms of Service" or "Agreement"). These Terms of Service may be updated from time to time without notice. You can review the most current version of the Terms of Service at any time on this page. If you do not agree with any part of these Terms of Service, you must not use the Service.<br/>
                                    <br/>
                                    2. Description of Service<br/>
                                    <br/>
                                    STDIN LTD ("we", "us", or "our") provides users with access to a property management platform that enables them to manage properties, tenants, and related activities. You are responsible for obtaining access to the Service and for any equipment necessary to use the Service.<br/>
                                    <br/>
                                    3. User Conduct<br/>
                                    <br/>
                                    You agree to use the Service only for lawful purposes and in accordance with these Terms of Service. You are solely responsible for the content of your communications through the Service. You agree not to post, transmit, or otherwise make available any content that:<br/>
                                    <br/>
                                    a. is false, misleading, or inaccurate;<br/>
                                    b. is defamatory, obscene, invasive of another's privacy, or otherwise objectionable;<br/>
                                    c. infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party;<br/>
                                    d. violates any applicable law or regulation; or<br/>
                                    e. contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.<br/>
                                    <br/>
                                    4. No Liability for User Content<br/>
                                    <br/>
                                    We do not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any content or communications posted on or through the Service. You understand that by using the Service, you may be exposed to content that might be offensive, harmful, inaccurate, or otherwise inappropriate, and you agree that we shall not be liable for any damages you allege to incur as a result of such content.<br/>
                                    <br/>
                                    5. Subscription and Cancellation<br/>
                                    <br/>
                                    a. Subscriptions: By signing up for a subscription, you agree to pay the applicable fees for the subscription plan you have chosen. Subscriptions are billed in advance and are non-refundable.<br/>
                                    <br/>
                                    b. Cancellation: You may cancel your subscription at any time. Upon cancellation, you will continue to have access to the Service until the end of your current billing period. No pro-rated refunds will be provided for any unused portion of the billing period.<br/>
                                    <br/>
                                    6. Termination<br/>
                                    <br/>
                                    We reserve the right to terminate your access to the Service, with or without cause or notice, at any time. Upon termination, your right to use the Service will immediately cease.<br/>
                                    <br/>
                                    7. Disclaimer of Warranties<br/>
                                    <br/>
                                    The Service is provided on an "as is" and "as available" basis. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.<br/>
                                    <br/>
                                    8. Limitation of Liability<br/>
                                    <br/>
                                    In no event shall we be liable for any indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses, arising out of or in connection with the Service, whether based on contract, tort, or any other legal theory.<br/>
                                    <br/>
                                    9. Indemnity<br/>
                                    <br/>
                                    You agree to indemnify and hold us, our subsidiaries, affiliates, officers, agents, and employees, harmless from any claim or demand, including reasonable attorney's fees, made by any third party due to or arising out of your breach of these Terms of Service, your use of the Service, or your violation of any law or the rights of a third party.<br/>
                                    <br/>
                                    10. Governing Law and Jurisdiction<br/>
                                    <br/>
                                    These Terms of Service and your use of the Service shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions. You agree to submit to the exclusive jurisdiction of the courts located within the United Kingdom to resolve any dispute arising out of or in connection with these Terms of Service or your use of the Service.<br/>
                                    <br/>
                                    11. Severability<br/>
                                    <br/>
                                    If any provision of these Terms of Service is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms of Service shall remain in full force and effect.<br/>
                                    <br/>
                                    12. Waiver<br/>
                                    <br/>
                                    The failure of STDIN LTD to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.<br/>
                                    <br/>
                                    13. Entire Agreement<br/>
                                    <br/>
                                    These Terms of Service, together with any other legal notices or agreements published by STDIN LTD on the Service, shall constitute the entire agreement between you and STDIN LTD concerning the Service. If any provision of these Terms of Service is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Service, which shall remain in full force and effect.<br/>
                                    <br/>
                                    14. Changes to Terms of Service<br/>
                                    <br/>
                                    We reserve the right to modify or replace these Terms of Service at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you must stop using the Service.<br/>
                                    <br/>
                                    15. Contact Information<br/>
                                    <br/>
                                    If you have any questions or concerns about these Terms of Service, please contact us at fergal@stdin.ltd.<br/>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>


}

