import React, {useState} from "react";
import {BriefcaseIcon} from "@heroicons/react/outline";
import {submitNewCase} from "../../../services/cases";
import {PrimaryButton} from "../../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NewCase({setErrorMessage, setMobileNavigation}) {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    setMobileNavigation("New Case")
    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")

            const {status} = await submitNewCase({
                title,
                overview: content,
                timestamp: new Date().toISOString(),
            });

            if (status === 200) {
                window.location.href = "/cases"
            } else {
                setErrorMessage("Unable to submit new case")
            }
        } catch (error) {
            setErrorMessage("Unable to submit new case")
        }

    };

    return        <> <main className="lg:col-span-12 xl:col-span-6 bg-white shadow p-4 sm:p-6 rounded-lg mx-4 sm:mx-0 order-2 xl:order-1">
        <div id="trending-heading" className="hidden sm:block font-normal text-xl sm:font-medium text-gray-900">
            <BriefcaseIcon
                className={classNames(
                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                )}

                aria-hidden="true"
            />
            <span className="truncate">Open a case</span>
        </div>
        <div className="mt-2 sm:mt-6">
            <form onSubmit={handleSubmit}>
                <textarea
                    rows={1}
                    name="title"
                    required={true}
                    id="title"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    className="shadow-sm mt-4 focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                    placeholder="Add your title..."
                />
                <textarea
                    rows={10}
                    name="content"
                    id="content"
                    required={true}
                    value={content}
                    onChange={e => setContent(e.target.value)}
                    className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                    placeholder="Add your content..."
                />
                <div className="mt-2 flex justify-end">
                    <PrimaryButton>
                        Add case
                    </PrimaryButton>
                </div>
            </form>
        </div>
    </main>
        <aside className="mb-2 xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2">
            <div className="sticky top-4 space-y-4">
                <section aria-labelledby="trending-heading">
                    <div className="bg-white rounded-lg shadow mb-2 sm:mb-4 mx-4 sm:mx-0">
                        <p className={"sm:p-4 p-6"}>
                            Cases provide a timeline of long-running issues related to the development
                        </p>
                    </div>
                </section>
            </div>
        </aside>

    </>
}
