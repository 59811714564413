import React, {useEffect, useState} from 'react';
import {subscribe} from '../../../services/subscribe';
import PropTypes from "prop-types";
import {populateSubscription} from "../../../services/confirmSubscription";
import ScaleLoader from "react-spinners/ScaleLoader";

export default function RenewSubscription({setErrorMessage}) {
    const [shareholders, setShareholders] = useState(0);
    const [loading, setLoading ] =useState(true)

    useEffect(() => {
        let mounted = true;
        populateSubscription()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setShareholders(resp.body.shareholders)
                        setLoading(false);
                    }
                }
            });
        return () => mounted = false;
    }, []);


    const submitMonthly = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {status, body} = await subscribe({
                planType: "monthly",
                quantity: shareholders,
            });

            if (status === 200) {
                window.location.href = body.url;
            } else {
                setErrorMessage("Unable to subscribe")
            }
        } catch (error) {
            setErrorMessage("Unable to subscribe")
        }
    };

    const submitAnnual = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status, body} = await subscribe({
                planType: "annual",
                quantity: shareholders,
            });

            if (status === 200) {
                window.location.href = body.url;
            } else {
                setErrorMessage("Unable to subscribe")
            }
        } catch (error) {
            setErrorMessage("Unable to subscribe")
        }
    };

    const tiers = [
        {
            name: 'Annual',
            href: '#',
            priceMonthly: 0.79,
            description: 'Fully featured, and save 33%',
            includedFeatures: [
                "Posts", "Announcements", "Cases", "Complaints", "Finances", "Meetings", "Free tenant access"
            ],
            fn: submitAnnual,
        },
        {
            name: 'Monthly',
            href: '#',
            priceMonthly: 0.99,
            description: 'All our features at an affordable monthly rate',
            includedFeatures: ["Posts", "Announcements", "Cases", "Complaints", "Finances", "Meetings", "Free tenant access"],
            fn: submitMonthly,
        },

    ];

    return loading ?
        <div className="mx-auto max-w-2xl px-6 lg:px-8 mt-12">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </div>
        :<div className="mx-auto max-w-2xl px-6 lg:px-8 mt-12">
    <h2 className="mb-16 text-center text-3xl font-normal tracking-tight text-gray-900">Let's get re-subscribed!</h2>
            <div>
                <div className="mt-8 text-gray-900 mb-8">
                    The subscription for this management group has expired, a director needs to re-subscribe in order to
                    proceed.
                </div>
                <div
                    className="sm:grid sm:grid-cols-2 sm:gap-2 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-2">

                    {tiers.map(tier => <div key={tier.name}
                                            className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm">
                        <div className="p-6">
                            <h2 className="text-lg font-medium leading-6 text-gray-900">{tier.name}</h2>
                            <p className="mt-8">
                                <span
                                    className="text-4xl font-bold tracking-tight text-gray-900">£{tier.priceMonthly}</span>{' '}
                                <span className="text-base font-medium text-gray-500">/user/mo</span>

                                {
                                    tier.name === 'Monthly'
                                        ?
                                        <div
                                            className="text-sm font-normal tracking-tight text-gray-900">£{(Math.round(Number(shareholders) * tier.priceMonthly * 100) / 100).toFixed(2)} billed
                                            monthly</div>

                                        :
                                        <div
                                            className="text-sm font-normal tracking-tight text-gray-900">£{(Math.round(Number(shareholders) * 12 * tier.priceMonthly * 100) / 100).toFixed(2)} billed
                                            annually</div>

                                }
                            </p>
                            <form className="space-y-6" onSubmit={tier.fn}>
                                <button
                                    type="submit"
                                    className="mt-8 block w-full rounded-md bg-orange-500 py-2 text-center font-normal text-white hover:bg-orange-700"
                                >
                                    Buy {tier.name}
                                </button>
                            </form>
                        </div>
                    </div>)}
                </div>
                <div className="mt-8 text-gray-900 list-disc">
                    All payments are non refundable. In the event of cancellation during a paid term, users will
                        have access to the platform until the end of the paid term
                </div>
                <p className="mt-6 text-center text-sm font-normal tracking-tight text-gray-900">Or, if you do not wish to re-subscribe, you may <a href={"/logout"} className={"text-orange-500"}>logout</a></p>
            </div>
    </div>
}

RenewSubscription.propTypes = {
    setErrorMessage: PropTypes.func.isRequired,
};