export default function Terms() {
    return <div className="m-8">
        <h1 className="text-4xl mb-10 font-bold tracking-tight text-gray-900 sm:text-6xl">Terms and conditions</h1>
        <p className="relative mt-6 text-lg leading-8 text-gray-600">
            Please read carefully before accessing any services or downloading any
            software from this website{" "}
        </p>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                By accepting these terms and conditions you are entering into a licence
                agreement for the use of the services and software defined below (the
                Licence). If you do not wish to be bound by these terms and conditions
                then you may not download, stream or access the services or documents.
            </p>
            <p className="mt-6">
                This Licence is made between (1) You (Licensee or you) and; (2) STDIN LTD
                of Unit 824, Moat House, 54 Bloomfield Avenue, Belfast BT5 5AD (Licensor,
                us or we).
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Background
        </h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We have developed certain software applications and platforms, which we
                will make available to you via the internet on a subscription basis.
            </p>
            <p className="mt-6">
                We have agreed to provide, and you have agreed to take and pay for our
                service, subject to and in accordance with the terms of this Licence.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Interpretation
        </h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">

            <p className="relative mt-6 mb-6 text-lg leading-8 text-gray-600">
                The following defined terms are used in this Licence:
            </p>

            <p><b>Account</b> means your account with us for the Services
            </p><p><b>Applicable Data Protection Laws</b> means a) to the extent the UK GDPR
            applies, the law of the United Kingdom or of a part of the United Kingdom
            which relates to the protection of personal data; and b) to the extent the
            EU GDPR applies, the law of the European Union or any member state of the
            European Union to which the Licensor is subject, which relates to the
            protection of personal data.
        </p><p><b>Applicable Laws</b> means all applicable laws, statutes, regulations
            and codes from time to time in force.
        </p><p><b>Authorised Users</b> means the employees, agents and independent
            contractors of you, your subsidiaries and affiliates, who you authorise to
            use the Services, the Software and the Documents.
        </p><p><b>Business Days</b> a day other than a Saturday, Sunday or public holiday
            in England when banks in London are open for business.
        </p><p><b>Documents</b> means any online documents provided as part of the
            Services.
        </p><p><b>Effective Date</b> means the date of this Licence.
        </p><p><b>EU GDPR</b> means the General Data Protection Regulation ((EU)
            2016/679).
        </p><p><b>Free Trial Period</b> means such period during which we will provide
            the Services to you on a trial basis without charge, subject to and in
            accordance with any such terms for such Free Trial Period as we may
            specify from time to time.
        </p><p><b>Good Industry Practice</b> means the exercise of that degree of skill,
            care, prudence, efficiency, foresight and timeliness as would be expected
            from a leading company within the relevant industry or business sector.
        </p><p><b>Initial Subscription Term</b> means the initial term of this Licence as
            selected by you, and depending on the Services that shall be offered to
            you via our subscription model and may, at our discretion include a Free
            Trial Period.
        </p><p><b>Licensee Data</b> means the data inputted by or on behalf of you, for
            the purpose of using or facilitating your use of the Services, Software or
            Documents and any data generated by, or derived from your use of the
            Services, Software or Documents, whether hosted or stored within the
            Services, Software or Documents or elsewhere.
        </p><p><b>Licensee Personal Data</b> means any personal data which we process in
            connection with this Licence, in the capacity of a processor on behalf of
            you.
        </p><p><b>Purpose</b> means the purposes for which the Licensee Personal Data is
            processed, as set out in clause 4.8.1.
        </p><p><b>Renewal Period</b> means a successive period equivalent in duration to
            the Initial Subscription Term and which commences on the day following
            expiry of the Initial Subscription Term.
        </p><p><b>Services</b> means the web-app services, under the name 'Votria', and
            any data supplied with the services
        </p><p><b>Software</b> means the online software applications provided as part of
            the Services
        </p><p><b>Subscription Fees</b> means the subscription fees payable by you to us
            for the User Subscriptions, as set out in Schedule 1 and which may, at our
            discretion, be discounted during the Initial Contract Term to reflect any
            Free Trial Period that has been agreed by us.
        </p><p><b>UK GDPR</b> has the meaning given to it in the Data Protection Act
            2018.
        </p><p><b>User Subscriptions</b> means the user subscriptions purchased by you,
            or on your behalf, from the Licensor (or an entity authorised by the
            Licensor to provide them), in accordance with the terms of the agreement
            you hold with that entity, which entitle Authorised Users to access and
            use the Services and the Documents in accordance with this Licence.
        </p><p><b>Viruses</b> means any thing or device (including any software, code,
            file or programme) which may prevent, impair or otherwise adversely affect
            the operation of any computer software, hardware or network, any
            telecommunications service, equipment or network or any other service or
            device; prevent, impair or otherwise adversely affect access to or the
            operation of any programme or data, including the reliability of any
            programme or data (whether by rearranging, altering or erasing the
            programme or data in whole or part or otherwise); or adversely affect the
            user experience, including worms, trojan horses, viruses and other similar
            things or devices.
        </p><p><b>Vulnerability</b> means a weakness in the computational logic (for
            example, code) found in software and hardware components that, when
            exploited, results in a negative impact to confidentiality, integrity, or
            availability, and the term Vulnerabilities shall be construed accordingly.
        </p><p><b>Website</b> means the online website via which you may access the
            Services and Software.</p>
        </div>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                In consideration of payment by you of the agreed licence fee and you
                agreeing to abide by the terms of this Licence, we grant to you a limited,
                non-exclusive, non-transferable, revocable licence, without the right to
                sublicense, to access and use the Services (and the Software and Documents
                in connection with the Services) in the UK on the terms of this Licence,
                solely for your internal business operations.
            </p>
            <p className="mt-6">
                We licence use of the Services, Software and Documents to you on the basis
                of this Licence. We do not sell the Services, Software or Documents to
                you. We, or our licensors, remain the owners of the Services, Software and
                Documents at all times.
            </p>
            <p className="mt-6">
                You shall provide us with:</p>
            <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">
                <li>all necessary cooperation in relation to this Licence; and</li>
                <li>
                    all necessary access to such information as may be required by the
                    Licensor,
                </li>
                <li>
                    to the extent required to provide the Services and Documents including
                    but not limited to Licensee Data, security access information and
                    configuration services;
                </li>
                <li>
                    without affecting your other obligations under this Licence, comply
                    with all Applicable Laws and regulations with respect to your
                    activities under this Licence; and
                </li>
                <li>
                    ensure that your network and systems comply with the relevant
                    specifications provided by the Licensor from time to time.
                </li>
            </ul>
            <p className="mt-6">
                You shall have sole responsibility for the legality, reliability,
                integrity, accuracy and quality of all Licensee Data. You hereby licence
                us to use the Licensee Data for:</p>
            <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">
                <li>
                    the proper performance of the Services, including the provision of the
                    Software and the Documents;
                </li>
                <li>
                    the purposes set out in our Privacy Notice as described in clause 4;
                    and
                </li>
                <li>
                    all other purposes relevant to the proper exercise of our rights and
                    obligations under this Licence.
                </li>
            </ul>

            <p className="mt-6">
                Subject to clauses 2.6 and 2.7, you may, from time to time, purchase
                additional User Subscriptions and we shall grant access to the Services,
                Software and the Documentation to such additional Authorised Users in
                accordance with the provisions of this Licence.
            </p>
            <p className="mt-6">
                If you wish to purchase additional User Subscriptions, you may do so via
                the Website. Where we approve the request, we shall activate the
                additional User Subscriptions within a reasonable time following such
                approval and you shall, within [30] days of the date of our invoice for
                the same, pay to us the relevant fees for such additional User
                Subscriptions, as set out in Schedule 1. If such additional User
                Subscriptions are purchased by you part way through the Initial
                Subscription Term or any Renewal Period (as applicable), such fees shall
                be prorated from the date of activation by us for the remainder of the
                Initial Subscription Term or then current Renewal Period (as applicable).
            </p>
            <p className="mt-6">
                You undertake that:
                <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">
                    <li>
                        the maximum number of Authorised Users that you authorise to access
                        and use the Services Software and the Documents shall not exceed the
                        number of User Subscriptions you have purchased from time to time;
                    </li>
                    <li>
                        you will not allow or suffer any User Subscription to be used by more
                        than one individual Authorised User unless it has been reassigned in
                        its entirety to another individual Authorised User, in which case the
                        prior Authorised User shall no longer have any right to access or use
                        the Services, Software and/or Documents;
                    </li>
                    <li>
                        you shall permit the Licensor or the Licensor's designated auditor to
                        audit the Services to verify that your use of the Services, Software
                        and Documents does not exceed the total number of User Subscriptions
                        purchased. This audit may take place at the Licensor's option, and the
                        Licensor may deploy reasonable online audit tools via the Services for
                        these purposes;
                    </li>
                    <li>
                        if any of the audits referred to in clause 2.8.3 reveal that any
                        password has been provided to any individual who is not an Authorised
                        User, then without prejudice to our other rights, you shall promptly
                        disable such passwords and we shall not issue any new passwords to any
                        such individual; and
                    </li>
                    <li>
                        if any of the audits referred to in clause 2.8.3 reveal that the you
                        have underpaid Subscription Fees to us, then without prejudice to our
                        other rights, you shall pay to us an amount equal to such underpayment
                        as calculated in accordance with the prices set out in Schedule 1
                        within [10] Business Days of the date of the relevant audit.
                    </li>
                    <li>
                        you shall pay the regular payments to receive the Services in
                        accordance with schedule 1 of this Licence.
                    </li>
                    <li>
                        you shall supervise and control use of the Services, Software and
                        Documents and ensure they are used by your employees and
                        representatives only in accordance with the terms of this Licence; and
                    </li>
                    <li>
                        you shall comply with all applicable technology control or export laws
                        and regulations.
                    </li>
                </ul>
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Restrictions</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p>
                Except as expressly set out in this Licence or as permitted by any local
                law which is incapable of exclusion by agreement between the parties, you
                shall not:</p>
            <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">
                <li>
                    attempt to copy, modify, duplicate, create derivative works from,
                    frame, mirror, republish, download, display, transmit, or distribute
                    all or any portion of the Software, Services and/or Documents (as
                    applicable) in any form or media or by any means; or
                </li>
                <li>
                    attempt to de-compile, reverse compile, disassemble, reverse engineer
                    or otherwise reduce to human-perceivable form all or any part of the
                    Software or Services;
                </li>
                <li>
                    access all or any part of the Services, Software or Documents to build
                    a product or service which competes with the Services, Software or the
                    Documents;
                </li>
                <li>
                    use the Services, Software or Documents to provide services to third
                    parties;
                </li>
                <li>
                    licence, sell, rent, lease, transfer, assign, distribute, display,
                    disclose, or otherwise commercially exploit, or otherwise make the
                    Services, Software or Documents available to any third party except
                    the Authorised Users; or
                </li>
                <li>
                    attempt to obtain, or assist third parties in obtaining, access to the
                    Services, Software or Documents, other than as provided under this
                    Licence.
                </li>
            </ul>
            <p className="mt-6">
                You shall not use the Services to:</p>
            <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">
                <li>
                    distribute or transmit to the Licensor any Viruses or Vulnerability
                    and shall implement procedures in line with Good Industry Practice to
                    prevent such distribution or transmission;
                </li>
                <li>
                    store, access, publish, disseminate, distribute or transmit any
                    material which:
                    <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">
                        <li>
                            is unlawful, harmful, threatening, defamatory, obscene,
                            infringing, harassing or racially or ethnically offensive;
                        </li>
                        <li>
                            facilitates illegal activity;
                        </li>
                        <li>
                            depicts sexually explicit images;
                        </li>
                        <li>
                            promotes unlawful violence;
                        </li>
                        <li>
                            is discriminatory based on race, gender, colour, religious
                            belief, sexual orientation, disability; or
                        </li>
                        <li>
                            is otherwise illegal or causes damage or injury to any person or
                            property,
                        </li>
                    </ul>
                </li>
            </ul>
            <p className="mt-6">
                and we reserve the right, on no less than thirty (30) days' prior written
                notice to you, such notice specifying the breach of this clause and
                requiring it to be remedied within the thirty (30) day period, to disable
                your access to the Services, Software and Documents for the duration of
                time that the breach remains unremedied.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Data protection</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                For the purposes of this clause 4, the terms controller, processor, data
                subject, personal data, personal data breach and processing shall have the
                meaning given to them in the UK GDPR.
            </p>
            <p className="mt-6">
                Both parties will comply with all applicable requirements of Applicable
                Data Protection Laws. This clause 4 is in addition to, and does not
                relieve, remove or replace, a party's obligations or rights under
                Applicable Data Protection Laws.
            </p>
            <p className="mt-6">
                The parties have determined that, for the purposes of Applicable Data
                Protection Laws, we shall process the personal data set out in schedule 2,
                as a processor on behalf of you in respect of the processing activities
                set out in schedule 2.
            </p>
            <p className="mt-6">
                Should the determination in clause 4.3 change, then each party shall work
                together in good faith to make any changes which are necessary to this
                clause 4 or the related schedules.
            </p>
            <p className="mt-6">
                By entering into this Licence, you consent to (and shall procure all
                required consents, from its personnel, representatives and agents, in
                respect of) all actions taken by us in connection with the processing of
                Licensee Personal Data, provided these are in compliance with the
                then-current version of our privacy policy available at
                votria.social/privacy (Privacy Policy). In the event of any inconsistency
                or conflict between the terms of the Privacy Policy and this Licence, the
                Privacy Policy will take precedence.
            </p>
            <p className="mt-6">
                Without prejudice to the generality of clause 4.2 you will ensure that you
                have all necessary appropriate consents and notices in place to enable
                lawful transfer of the Licensee Personal Data to us for the duration and
                purposes of this Licence.
            </p>
            <p className="mt-6">
                In relation to the Licensee Personal Data, schedule 2 sets out the
                scope, nature and purpose of processing by us, the duration of the
                processing and the types of personal data and categories of data
                subject.
            </p>
            <p className="mt-6">
                Without prejudice to the generality of clause 4.2 , we shall, in
                relation to Licensee Personal Data:
            </p>
            <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">

                <li>
                    process that Licensee Personal Data only on your documented
                    instructions, which shall be for the purposes of providing the
                    Services, unless we are required by Applicable Laws to otherwise
                    process that Licensee Personal Data. Where we are relying on
                    Applicable Laws as the basis for processing Licensee Processor Data,
                    we shall notify you of this before performing the processing required
                    by the Applicable Laws unless those Applicable Laws prohibit us from
                    notifying you on important grounds of public interest. We shall inform
                    you if, in our opinion, the instructions of you infringe Applicable
                    Data Protection Laws;
                </li>
                <li>
                    implement appropriate technical and organisational measures to protect
                    against unauthorised or unlawful processing of Licensee Personal Data
                    and against accidental loss or destruction of, or damage to, Licensee
                    Personal Data;
                </li>
                <li>
                    ensure that any personnel engaged and authorised by us to process
                    Licensee Personal Data have committed themselves to confidentiality or
                    are under an appropriate statutory or common law obligation of
                    confidentiality;
                </li>
                <li>
                    assist you insofar as this is possible (taking into account the nature
                    of the processing and the information available to us), and at your
                    cost and written request, in responding to any request from a data
                    subject and in ensuring your compliance with its obligations under
                    Applicable Data Protection Laws with respect to security, breach
                    notifications, impact assessments and consultations with supervisory
                    authorities or regulators;
                </li>
                <li>
                    notify you without undue delay on becoming aware of a personal data
                    breach involving the Licensee Personal Data;
                </li>
                <li>
                    at your written direction, delete or return Licensee Personal Data and
                    copies thereof to you on termination of the Licence unless we are
                    required by Applicable Law to continue to process that Licensee
                    Personal Data. For the purposes of this clause 4.8.6, Licensee
                    Personal Data shall be considered deleted where it is put beyond
                    further use by us; and
                </li>
                <li>
                    maintain records to demonstrate your compliance with this clause 4.
                </li>
            </ul>
            <p className="mt-6">
                You hereby provide your prior, general authorisation for us to appoint
                processors to process the Licensee Personal Data, provided that we:
            </p>
            <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">
                <li>
                    shall ensure that the terms on which it appoints such processors
                    comply with Applicable Data Protection Laws, and are consistent with
                    the obligations imposed on us in this clause 4;
                </li>
                <li>
                    shall remain responsible for the acts and omission of any such
                    processor as if they were the acts and omissions of us; and
                </li>
                <li>
                    shall inform you of any intended changes concerning the addition or
                    replacement of the processors, thereby giving you the opportunity to
                    object to such changes provided that if you object to the changes and
                    cannot demonstrate, to our reasonable satisfaction, that the objection
                    is due to an actual or likely breach of Applicable Data Protection
                    Law, you shall indemnify us for any losses, damages, costs (including
                    legal fees) and expenses suffered by us in accommodating the
                    objection.
                </li>
            </ul>
            <p className="mt-6">
                Either party may, at any time on not less than 30 days' notice, revise
                this clause 4 by replacing it (in whole or part) with any applicable
                standard clauses approved by the EU Commission or the UK Information
                Commissioner's Office or forming part of an applicable certification
                scheme or code of conduct (Amended Terms). Such Amended Terms shall apply
                when replaced by attachment to this Licence, but only in respect of such
                matters which are within the scope of the Amended Terms.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Charges and payment</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">

            <p className="mt-6">
                You shall pay the Subscription Fees to us for the User Subscriptions in
                accordance with this clause and schedule 1.
            </p>
            <p className="mt-6">
                You shall on the Effective Date provide to us valid, up-to-date and
                complete credit card details or approve purchase order information
                acceptable to us and any other relevant valid, up-to-date and complete
                contact and billing details and, if you provide:
            </p>
            <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">
                <li>
                    your credit card details to us, you hereby authorise us to bill such
                    credit card:
                    <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">
                        <li>
                            on the Effective Date for the Subscription Fees payable in respect
                            of the Initial Subscription Term; and
                        </li>
                        <li>
                            subject to clause 10.1, on the day following expiry of the Initial
                            Subscription Term (or each subsequent Renewal Period as the case
                            may be) for the Subscription Fees payable in respect of the next
                            Renewal Period;
                        </li>
                    </ul>
                </li>
                <li>
                    your approved purchase order information to us, we shall invoice you
                    <ul role="list" className="mt-6 ml-12 space-y-4 list-disc">
                        <li>
                            on the Effective Date for the Subscription Fees payable in respect
                            of the Initial Subscription Term; and
                        </li>
                        <li>
                            subject to clause 10.1, at least 30 days before each anniversary
                            of the Effective Date for the Subscription Fees payable in respect
                            of the next Renewal Period,
                        </li>
                    </ul>
                </li>
                <li>
                    and you shall pay each invoice within 30 days after the date of such
                    invoice.
                </li>
            </ul>

            <p className="mt-6">
                If we have not received payment within 30 days after the due date, and
                without prejudice to any of our other rights and remedies we may, without
                liability to you, disable your Account and password your access to all or
                part of the Services and we shall be under no obligation to provide any or
                all of the Services while the invoice(s) concerned remain unpaid; and
            </p>
            <p className="mt-6">
                All amounts and fees stated or referred to in this Licence:
                <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                    <li>shall be payable in pounds sterling; and</li>
                    <li>
                        are, subject to clause 10.1.2, non-cancellable and non-refundable;
                    </li>
                </ul>
            </p>
            <p className="mt-6">
                We shall be entitled to increase the Subscription Fees, and the fees
                payable in respect of any additional User Subscriptions purchased pursuant
                to clause 2.5 at the start of each Renewal Period upon 30 days' prior
                notice to you and Schedule 1 shall be deemed to have been amended
                accordingly.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Intellectual property rights</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                You acknowledge that all intellectual property rights in the Services,
                Software and Documents anywhere in the world belong to us or our
                licensors, that rights in the Services, Software and Documents are
                licensed (not sold) to you, and that you have no rights in, or to, the
                Services, Software or the Documents other than the right to use them in
                accordance with the terms of this Licence.
            </p>
            <p className="mt-6">
                You acknowledge that you have no right to have access to any Software in
                source code form.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Limited warranty</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We warrant that the Services and Software will, when used properly,
                perform substantially in accordance with the functions described in the
                Documents.
            </p>
            <p className="mt-6">
                If you notify us in writing of any defect or fault in the Services or
                Software as a result of which it fails to perform substantially in
                accordance with the Documents, we will, at our sole option, either repair
                or replace the Services or Software, provided that you make available all
                the information that may be necessary to help us to remedy the defect or
                fault, including sufficient information to enable us to recreate the
                defect or fault
            </p>
            <p className="mt-6">
                The warranty does not apply if the defect or fault in the Services or
                Software results from you having used the Services, Software or Documents
                in breach of the terms of this Licence.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Limitation of liability</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                You accept responsibility for the selection of the Services to achieve
                your intended results and acknowledge that the Services, Software and
                Documents have not been developed or designed to meet or support any
                individual requirements you have, including any particular cybersecurity
                requirements you might be subject to, or any regulated activity that you
                may be engaged in, including the provision of an online intermediation
                service, an online search engine or service that facilitates online
                interaction between users (such as, but not limited to, a social media
                platform) (each a Regulated Activity). If you use the Services for any
                Regulated Activity, you agree to comply with any requirements that apply
                to such Regulated Activity from time to time (including in any
                jurisdiction in which you operate or where the Regulated Activity is
                undertaken) and you shall defend, indemnify and hold us harmless against
                any loss or damage (including regulatory fines or penalties) costs
                (including legal fees) and expenses which we may suffer or incur as a
                result of your breach of this clause 9.1.
            </p>
            <p className="mt-6">
                We only supply the Services, Software and Documents for internal use by
                your business, and you agree not to use the Services, Software or
                Documents for any resale purposes.
            </p>
            <p className="mt-6">
                Nothing in this Licence shall limit or exclude our liability for:
                <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                    <li>death or personal injury resulting from our negligence;</li>
                    <li>fraud or fraudulent misrepresentation; or</li>
                    <li>
                        any other liability that cannot be excluded or limited by English law.
                    </li>
                </ul>
            </p>
            <p className="mt-6">
                We shall not in any circumstances whatever be liable to you, whether in
                contract, tort (including negligence), breach of statutory duty, or
                otherwise, arising under or in connection with the Licence for:
                <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                    <li>loss of profits, sales, business, or revenue;</li>
                    <li>business interruption;</li>
                    <li>loss of anticipated savings;</li>
                    <li>wasted expenditure;</li>
                    <li>loss or corruption of data or information;</li>
                    <li>loss of business opportunity, goodwill or reputation,</li>
                    <li>
                        any special, indirect or consequential loss, damage, charges or
                        expenses.
                    </li>
                </ul>
            </p>
            <p className="mt-6">
                Subject to clauses 9.3 and 9.4, our maximum aggregate liability under or
                in connection with this Licence whether in contract, tort (including
                negligence) or otherwise, shall in all circumstances be limited to the
                total Subscription Fees paid or payable for the User Subscriptions during
                the 12 months immediately preceding the date on which the claim arose.
            </p>
            <p className="mt-6">
                This Licence sets out the full extent of our obligations and liabilities
                in respect of the supply of the Services, Software and Documents. Except
                as expressly stated in this Licence, there are no conditions, warranties,
                representations or other terms, express or implied, that are binding on
                us. Any condition, warranty, representation or other term concerning the
                supply of the Services, Software and Documents which might otherwise be
                implied into, or incorporated in, this Licence whether by statute, common
                law or otherwise, is excluded to the fullest extent permitted by law.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Termination</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                This Licence shall commence on the Effective Date and, unless terminated
                earlier in accordance with this clause 10, shall continue for the Initial
                Subscription Term. Thereafter, this Licence shall be automatically renewed
                for successive Renewal Periods, unless:</p>
            <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                <li>
                    either party notifies the other party of termination via the Website,
                    in which case this Licence shall terminate upon the expiry of the
                    applicable Initial Subscription Term or Renewal Period; or
                </li>
                <li>
                    otherwise terminated in accordance with the provisions of this
                    Licence;
                </li>
            </ul>
            <p className="mt-6">
                and the Initial Subscription Term together with any subsequent Renewal
                Periods shall constitute the Subscription Term.
            </p>
            <p className="mt-6">
                Either party may terminate this Licence immediately by written notice if:</p>
            <ul>
                <li>
                    the other commits a material or persistent breach of this Licence
                    which it fails to remedy (if remediable) within 30 days after the
                    service of written notice requiring it to do so;
                </li>
            </ul>
            <p className="mt-6">
                We may also terminate this Licence if you:</p>
            <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                <li>
                    fail to pay any amount due under this Licence on the due date for
                    payment and remain in default not less than 30 days after being
                    notified in writing to make such payment; or
                </li>
                <li>
                    suspend, or threaten to suspend, payment of your debts or you are
                    unable to pay your debts as they fall due or admit inability to pay
                    your debts or are deemed unable to pay your debts by relevant law,
                </li>
                <li>
                    any of your debts, or makes a proposal for or enters into any
                    compromise or arrangement with any of your creditors, are declared
                    insolvent or bankrupt, has a petition filed, a notice is given, a
                    resolution is passed, or an order is made, for or in connection with
                    your winding up or bankruptcy, or a liquidator, examiner, receiver,
                    receiver manager or trustee is appointed in respect of the whole or
                    any part of your assets or business, or you suspend or cease to,
                    threatens to, suspect or cease to continue your business or a material
                    part of it, or any other action is taken or event occurs, with respect
                    to you in any jurisdiction to which you are subject that has an effect
                    equivalent or similar to any of the events mentioned above.
                </li>
            </ul>
            <p className="mt-6">
                On termination for any reason:</p>
            <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                <li>all rights granted to you under this Licence shall cease;</li>
                <li>
                    you must immediately cease all activities authorised by this Licence;
                    and
                </li>
                <li>
                    you must immediately and permanently delete or disable interfaces to
                    the Services from all computer equipment in your possession, and
                    immediately destroy, delete or return to us (at our option) all copies
                    of the Documents and Software then in your possession, custody or
                    control and, in the case of destruction or deletion, certify to us
                    that you have done so.
                </li>
            </ul>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Communication between us</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We may update the terms of this Licence at any time on notice to you in
                accordance with this clause 11. Your continued use of the Services,
                Software and Documents following the deemed receipt and service of the
                notice under clause 11.3 constitute your acceptance to the terms of this
                Licence, as varied. If you do not wish to accept the terms of the Licence
                (as varied), you must immediately stop using and accessing the Services,
                Software and Documents on the deemed receipt and service of the notice.
            </p>
            <p className="mt-6">
                If we have to contact you, we will do so by email or by pre-paid post to
                the address you provided in accordance with your registration for the
                Services.
            </p>
            <p className="mt-6">
                Any notice:</p>
            <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                <li>
                    given by us to you will be deemed received and properly served 24
                    hours after it is first posted on our Website, 24 hours after an email
                    is sent, or three days after the date of posting of any letter; and
                </li>
                <li>
                    given by you to us will be deemed received and properly served 24
                    hours after an email is sent, or three days after the date of posting
                    of any letter.
                </li>
            </ul>
            <p className="mt-6">
                In proving the service of any notice, it will be sufficient to prove, in
                the case of posting on our Website, that the Website was generally
                accessible to the public for a period of 24 hours after the first posting
                of the notice; in the case of a letter, that such letter was properly
                addressed, stamped and placed in the post to the address of the recipient
                given for these purposes; and, in the case of an email, that such email
                was sent to the email address of the recipient given for these purposes.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Events outside our control</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We will not be liable or responsible for any failure to perform, or delay
                in performance of, any of our obligations under this Licence that is
                caused by an Event Outside Our Control. An Event Outside Our Control is
                defined below this clause.
            </p>
            <p className="mt-6">
                An Event Outside Our Control means any act or event beyond our reasonable
                control, including without limitation failure of public or private
                telecommunications networks.
            </p>
            <p className="mt-6">
                If an Event Outside Our Control takes place that affects the performance
                of our obligations under this Licence:</p>
            <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                <li>
                    our obligations under this Licence will be suspended and the time for
                    performance of our obligations will be extended for the duration of
                    the Event Outside Our Control; and
                </li>
                <li>
                    we will use our reasonable endeavours to find a solution by which our
                    obligations under this Licence may be performed despite the Event
                    Outside Our Control.
                </li>
            </ul>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Other important terms</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                We may transfer our rights and obligations under this Licence to another
                organisation, but this will not affect your rights or our obligations
                under this Licence.
            </p>
            <p className="mt-6">
                You may only transfer your rights or your obligations under this Licence
                to another person if we agree in writing.
            </p>
            <p className="mt-6">
                This Licence and any document expressly referred to in it constitutes the
                entire agreement between us and supersedes and extinguishes all previous
                and contemporaneous agreements, promises, assurances and understandings
                between us, whether written or oral, relating to its subject matter.
            </p>
            <p className="mt-6">
                You acknowledge that in entering into this Licence you do not rely on and
                shall have no remedies in respect of any statement, representation,
                assurance or warranty (whether made innocently or negligently) that is not
                set out in this Licence or any document expressly referred to in it.
            </p>
            <p className="mt-6">
                You agree that you shall have no claim for innocent or negligent
                misrepresentation or negligent misstatement based on any statement in this
                Licence or any document expressly referred to in it.
            </p>
            <p className="mt-6">
                A waiver of any right or remedy is only effective if given in writing and
                shall not be deemed a waiver of any subsequent right or remedy.
            </p>
            <p className="mt-6">
                A delay or failure to exercise, or the single or partial exercise of, any
                right or remedy shall not waive that or any other right or remedy, nor
                shall it prevent or restrict the further exercise of that or any other
                right or remedy.
            </p>
            <p className="mt-6">
                Each of the clauses of this Licence operates separately. If any court or
                competent authority decides that any of them are unlawful or
                unenforceable, the remaining clauses will remain in full force and effect.
            </p>
            <p className="mt-6">
                References to "in writing" in these terms and conditions shall include by
                email.
            </p>
            <p className="mt-6">
                This Licence, its subject matter and formation (and any non-contractual
                disputes or claims) are governed by the laws of Northern Ireland. Each
                party irrevocably agrees that the courts of Northern Ireland shall have
                exclusive jurisdiction to settle any dispute or claim arising out of or in
                connection with this Licence or its subject matter or formation (including
                non-contractual disputes or claims).
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"><span style={{color: "rgb(183, 183, 183)"}}>Schedule 1</span> Subscription fees</h1>
        <h4 className="text-xl mt-6 mb-6 font-bold tracking-tight text-gray-900 sm:text-xl">Subscription Fees</h4>

        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                Annual: The annual subscription fees shall be based on the number of User
                Subscriptions which we have agreed, based on each User Subscription at
                £0.79 per month for annual subscriptions.
            </p>
            <p className="mt-6">
                Monthly: The monthly subscription fees shall be based on the number of
                User Subscriptions chosen by you and agreed by us, based on each User
                Subscription at £0.99 per month for monthly subscriptions.
            </p>
        </div>
        <h4 className="text-xl mt-6 mb-6 font-bold tracking-tight text-gray-900 sm:text-xl">Additional User Subscription Fees</h4>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                Annual: Additional User Subscriptions may be purchased by the Customer in
                accordance with clause 2.6 at £0.79 per User Subscription.
            </p>
            <p className="mt-6">
                Monthly: Additional User Subscriptions may be purchased by the Customer in
                accordance with clause 2.6 at £0.99 per User Subscription.
            </p>
        </div>
        <h4 className="text-xl mt-6 mb-6 font-bold tracking-tight text-gray-900 sm:text-xl">Payment Methods</h4>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">
            <p className="mt-6">
                All Subscription Fees and Additional User Subscriptions must be paid via
                the Stripe payment processing platform.
            </p>
        </div>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"><span style={{color: "rgb(183, 183, 183)"}}>Schedule 2</span> Particulars of the processing</h1>
        <div className="mt-10 mb-10 text-base leading-7 text-gray-700">

            <table className="table-auto border-b">
                <tbody>

                <tr className="border-b">
                    <td>
                        <b>Subject Matter</b>
                    </td>
                    <td>
                        To perform the Service(s) and make the Website available to Licensee
                        pursuant to the Agreement.
                    </td>
                </tr>
                <tr className="border-b">
                    <td>
                        <b>Duration</b>
                    </td>
                    <td>Term</td>
                </tr>
                <tr className="border-b">
                    <td>
                        <b>Nature and Purpose of the Processing</b>
                    </td>
                    <td>
                        Processing of Personal Data<br/>
                        <div className="mt-6">Nature</div>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">

                            <li>Collection: Personal data is
                                collected directly from users during account creation and as they use
                                the software to make posts, comments, and complaints.</li>
                            <li>Storage: Data is
                                stored on secure, encrypted servers in compliance with relevant data
                                protection regulations.</li>
                            <li>Usage: The collected data is used to enable
                                user identification for posts and comments, manage the login process,
                                facilitate the password reset process, distribute company
                                announcements, and manage the complaint system.</li>
                            <li>Disclosure: Personal
                                data is only disclosed within the property management group for
                                complaint resolution and administrative purposes. No data is shared
                                with third parties unless required by law.</li>
                            <li>Deletion: Personal data is
                                retained as long as the user maintains an active subscription. Upon
                                account deletion or a specific request for data deletion, all personal
                                data is erased within a period of 30 days, with exceptions only for
                                data retained in compliance with legal obligations.</li>
                        </ul>
                        <div className="mt-6">Purpose of processing</div>
                        <ul role="list" className="mt-6 ml-6 space-y-4 list-disc">
                            <li>The purpose of processing personal data
                                is to provide, maintain, and improve the software. This includes
                                enabling user interactions through posts and comments, managing the
                                user login and password reset process, distributing company
                                announcements, and operating the complaint system.</li></ul>
                    </td>
                </tr>
                <tr className="border-b">
                    <td>
                        <b>Categories of Data Subjects</b>
                    </td>
                    <td>
                        The categories of data subjects include all registered Users of the
                        software, namely property management group shareholders, directors,
                        agents and tenants.
                    </td>
                </tr>
                <tr className="border-b">
                    <td>
                        <b>
                            Types of Personal Data i.e. any information relating to an
                            identified or identifiable
                        </b>
                    </td>
                    <td>
                        <b>Contact Details</b><br/>
                        Personal Identifiers: User's name, email address, physical home
                        address, and vehicle registration information.Account Data: User's
                        email (used for login), and User’s name associated with posts and
                        comments.<br/>
                        <b>Financial Data</b><br/>
                        Property management group bank details and bank statement(s).
                    </td>
                </tr>
                <tr className="border-b">
                    <td>
                        <b>Subcontracting</b>
                    </td>
                    <td>DigitalOcean EU BV will process customer data</td>
                    <td/>
                </tr>
                <tr className="border-b">
                    <td>
                        <b>Data Transfers</b>
                    </td>
                    <td>
                        File uploads are stored in a data centre based in the Netherlands
                    </td>
                    <td/>
                </tr>
                <tr className="border-b">
                    <td>
                        <b>Security Measures</b>
                    </td>
                    <td>
                        Database is protected with strong encryptionUser sessions are
                        authenticated with session tokensUser passwords are encrypted in a
                        secure database
                    </td>
                    <td/>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
}

