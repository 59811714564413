import getToken from './token'


export function listPreviousMeetings() {
    return fetch(`${process.env.REACT_APP_API}/v1/meetings/previous`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}


export function getMeeting(id) {
    return fetch(`${process.env.REACT_APP_API}/v1/meetings/` + id, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function getMeetingFile(meetingID, fileID, fileName) {
    return fetch(`${process.env.REACT_APP_API}/v1/meetings/` + meetingID + '/files/' + fileID, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(response => {
        // const disposition = response.headers.get("content-disposition");
        // filename = disposition.match(/filename=(.+)/)[1];
        return response.blob()
    })
        .then(blob => {
            const link = document.createElement('a');
            // create a blobURI pointing to our Blob
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        })
}

export function getMeetings() {
    return fetch(`${process.env.REACT_APP_API}/v1/meetings`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitNewAgendaItem(postDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/agenda`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(postDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function uploadFile(uploadDetails) {
    var data = new FormData();
    data.append('file', uploadDetails.file);
    return fetch(`${process.env.REACT_APP_API}/v1/meetings/` + uploadDetails.id + '/files/upload', {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: data,
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function uploadContactSheet(uploadDetails) {
    var data = new FormData();
    data.append('file', uploadDetails.file);
    return fetch(`${process.env.REACT_APP_API}/v1/directory/files/upload`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: data,
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function deleteFile(id, fileID) {
    return fetch(`${process.env.REACT_APP_API}/v1/meetings/` + id + '/files/' + fileID + '/delete', {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function deleteAgendaItem(id) {
    return fetch(`${process.env.REACT_APP_API}/v1/agenda/` + id, {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}


export function voteAgendaItem(id, score) {
    return fetch(`${process.env.REACT_APP_API}/v1/agenda/` + id + '/vote/' + score, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function requestEGM() {
    return fetch(`${process.env.REACT_APP_API}/v1/egm/request`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function scheduleMeeting(meetingDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/meetings/schedule`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(meetingDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function editMeeting(id, meetingDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/meetings/schedule/` + id, {
        method: 'PATCH',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(meetingDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}
