/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useEffect, useState} from "react";
import {deleteCase, editCase, getCase, submitNewCaseUpdate} from "../../../services/cases";
import {useParams} from "react-router-dom";
import {BriefcaseIcon, ExclamationCircleIcon, PencilIcon, SpeakerphoneIcon, TrashIcon} from "@heroicons/react/outline";
import Datepicker from "react-tailwindcss-datepicker";
import {formatWithOptions} from "date-fns/fp";
import Comment from "../Comments/Comment";


export default function ViewCase({setErrorMessage, setMobileNavigation}) {

    const processString = require('react-process-string');
    setMobileNavigation("View Case")
    const [caze, setCaze] = useState({
        case: {
            canCreate: false,
            content: "",
            author: {},
        },
        caseUpdates: [],
    });


    const [updatedPost, setUpdatedPost] = useState("");
    const [editPostVisibility, setEditPostVisibility] = useState(false);
    const [deletePostVisibility, setDeletePostVisibility] = useState(false);

    let {id} = useParams();

    let config = [{
        regex: /([\w.]+)@(\w+)\.(\w+)/gim,
        fn: (key, result) => <span key={key}>
                                 <a className={"text-blue-700"} target="_blank" rel={"noreferrer"}
                                    href={`mailto:${result[1]}@${result[2]}.${result[3]}`}>{result[1]}@{result[2]}.{result[3]}</a>
                             </span>
    },
        {
            regex: /(http|https):\/\/(\S+)/gim,
            fn: (key, result) => result[0].includes("jpg") ?
                <span key={key}>
                      <img alt="" src={result[0]}/>
                    </span>
                : <span key={key}>
                    <a className={"text-blue-700"} target="_blank" rel={"noreferrer"} href={result[0]}>{result[2]}</a>
                </span>

        },
        {
            regex: /(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
            fn: (key, result) => <span key={key}>
                                 <a className={"text-blue-700"} target="_blank" rel={"noreferrer"}
                                    href={`//${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                             </span>
        }];

    useEffect(() => {
        let mounted = true;
        getCase(id)
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setCaze(resp.body);
                    }
                } else {
                    window.location.href = "/"
                }
            });
        return () => mounted = false;
    }, [id]);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await submitNewCaseUpdate(caze.case.id, {
                content: e.target.elements.content.value,
                date: value,
                timestamp: new Date().toISOString(),
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to submit new case update")
            }
        } catch (error) {
            setErrorMessage("Unable to submit new case update")
        }

    };

    const [value, setValue] = useState({});
    const handleValueChange = newValue => {
        const dateToString = formatWithOptions({}, 'd MMMM yyyy');
        const start = new Date(newValue.startDate);
        const end = new Date(newValue.endDate);
        setValue({startDate: dateToString(start), endDate: dateToString(end)});
    };

    const handleUpdate = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await editCase(
                caze.case.id,
                {
                    updatedPost,
                    timestamp: new Date().toISOString()
                }
            );

            if (status === 200) {
                window.location.href = "/cases/" + caze.case.id
            } else {
                setErrorMessage("Unable to edit case")
            }
        } catch (error) {
            setErrorMessage("Unable to edit case")
        }

    };


    const handleDeletePost = async (e, cazeID) => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {status} = await deleteCase(cazeID);

            if (status === 200) {
                setDeletePostVisibility(false);
                window.location.href = "/cases"
            } else{
                setErrorMessage("Unable to delete case")
            }

        } catch (error) {
            setErrorMessage("Unable to delete case")
        }

    };

    return <>
        <main className="lg:col-span-12 xl:col-span-6 order-2 xl:order-1">
            <div className="bg-white shadow p-6 sm:rounded-lg mx-4 sm:mx-0 mt-4 sm:mt-0">

                    <h1 className="text-xl font-bold text-gray-900 mb-6">{caze.case.title}</h1>

                    {
                        editPostVisibility ?
                            <form onSubmit={handleUpdate}>
                            <textarea
                                rows={5}
                                name="updatedPost"
                                required={true}
                                id="updatedPost"
                                defaultValue={caze.case.content}
                                onChange={e => setUpdatedPost(e.target.value)}
                                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                            />
                                <div className="mt-2 flex justify-end">
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                                    >
                                        Update
                                    </button>
                                </div>
                            </form>
                            :

                            <>
                                <p className={"whitespace-pre-wrap"}>{processString(config)(caze.case.content)}</p>

                                {caze.case.canCreate ?
                                    <>
                                                <span className="inline-flex text-xs hover:cursor-pointer mt-3"
                                                      onClick={e => setEditPostVisibility(!editPostVisibility)}>
                                                <PencilIcon className="h-4 w-4 text-gray-500 mr-2"
                                                            aria-hidden="true"/> Edit
                                            </span>
                                        <span className="inline-flex text-xs hover:cursor-pointer mt-3"
                                              onClick={e => setDeletePostVisibility(!deletePostVisibility)}>
                                                <TrashIcon className="h-4 w-4 text-gray-500 mr-2 ml-4"
                                                           aria-hidden="true"/> Delete
                                            </span>
                                    </>
                                    :
                                    <></>}

                            </>
                    }
                    <Transition.Root show={deletePostVisibility} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={e => setDeletePostVisibility(false)}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                            </Transition.Child>

                            <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                                <div
                                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel
                                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                            <div>
                                                <div
                                                    className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                                    <ExclamationCircleIcon className="h-6 w-6 text-red-600"
                                                                           aria-hidden="true"/>
                                                </div>
                                                <div className="mt-3 text-center sm:mt-5">
                                                    <Dialog.Title as="h3"
                                                                  className="text-lg font-medium leading-6 text-gray-900">
                                                        Delete post?
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            {caze.case.content}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                                    onClick={e => handleDeletePost(e, caze.case.id)}
                                                >
                                                    Delete
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                                    onClick={e => setDeletePostVisibility(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>


                    <ol className="relative border-l border-gray-200 dark:border-gray-700 mt-8">
                        {caze.caseUpdates.map(update => <Comment update={update} caseID={id} canCreate={caze.case.canCreate}/>)}

                    </ol>

            </div>
        </main>

        <aside className="sm:mt-4 lg:mt-0 xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2">
            {caze.case.canCreate ?
                <div className="sticky top-4 space-y-4">
                    <section aria-labelledby="trending-heading">
                        <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                            <div className="p-4 sm:p-6">
                                <div id="trending-heading" className="hidden sm:block font-normal text-xl sm:font-medium text-gray-900">
                                    <BriefcaseIcon
                                        className={
                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                    }

                                        aria-hidden="true"
                                    />
                                    <span className="truncate">Add an update</span>
                                </div>
                                <form onSubmit={handleSubmit} className="mt-2 sm:mt-8">
                                    <div className="mb-4 flow-root text-gray-900">
                                        <Datepicker
                                            value={value}
                                            onChange={handleValueChange}
                                            useRange={false}
                                            required={true}
                                            asSingle={true}
                                            displayFormat={"DD MMMM YYYY"}
                                            primaryColor={"orange"}
                                            inputClassName="font-normal text-gray-900 "
                                        />
                                    </div>
                                    <textarea
                                        rows={5}
                                        name="content"
                                        required={true}
                                        id="content"
                                        className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                                        placeholder="Add your comment..."
                                        defaultValue={''}
                                    />
                                    <div className="mt-2 flex justify-end">

                                    <button
                                        type="submit"
                                        className="w-full xl:w-auto xl:float-right flex items-center justify-center px-4 py-2 border border-2 border-orange-500 text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                                    >
                                        Add case update
                                    </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    {/*another section*/}
                </div>
                :
                <></>
            }
        </aside>

    </>
}
