import getToken from './token'

export function getNewComplaintForm() {
    return fetch(`${process.env.REACT_APP_API}/v1/complaints/new`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function getComplaints() {
    return fetch(`${process.env.REACT_APP_API}/v1/complaints`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function getComplaint(id) {
    return fetch(`${process.env.REACT_APP_API}/v1/complaints/` + id, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitNewComplaint(complaintsDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/complaints`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(complaintsDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitNewComment(id, commentDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/complaints/` + id + '/comment', {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(commentDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function editComment(id, commentDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/complaints/` + id + '/comment/' + commentDetails.commentID, {
        method: 'PATCH',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(commentDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function deleteComment(id, commentID) {
    return fetch(`${process.env.REACT_APP_API}/v1/complaints/` + id + '/comment/' + commentID, {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function editComplaint(id, content) {
    return fetch(`${process.env.REACT_APP_API}/v1/complaints/` + id, {
        method: 'PATCH',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(content),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}


export function deleteComplaint(content) {
    return fetch(`${process.env.REACT_APP_API}/v1/complaints/resolve/` + content.complaintID, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(content),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

