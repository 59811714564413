import getToken from './token'


export function getNewUserForm() {
    return fetch(`${process.env.REACT_APP_API}/v1/new-user`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}


export function setupNewUser(userDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/new-user`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(userDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}
