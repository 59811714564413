import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {confirmSubscription} from "../../../services/confirmSubscription"
import {HeartIcon} from "@heroicons/react/outline";

export default function SubscribeSuccess({setWizard, setExpired}) {
    let {session} = useParams();

    useEffect(() => {
        let mounted = true;
        confirmSubscription(session)
            .then(resp => {
                if (mounted) {
                    if (resp.status === 200) {
                        setWizard(false);
                        setExpired(false);
                        window.location.href = "/"
                    }
                }
            });
        return () => mounted = false;
    }, [session, setWizard, setExpired]);

    return <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="text-orange-500 text-center" style={{fontSize: "4em", fontFamily: `'Fortika', sans-serif`}}>
                votria
            </div>

        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md text-center mt-8 text-xl">
            Thanks for subscribing!
        </div>
    </div>
}

