import PropTypes from "prop-types";

export default function Logout({setToken, setGroups, setWizard, setNewUser, setUser, setName, setNavigation, setErrorMessage, setSteps, setRunTour}) {
    setToken("");
    setGroups([]);
    setWizard(false);
    setNewUser(false);
    setUser({});
    setName("");
    setNavigation([]);
    setErrorMessage("");
    setSteps([]);
    setRunTour(false);
    window.location.href = "/"
}

Logout.propTypes = {
    setToken: PropTypes.func.isRequired,
    setGroups: PropTypes.func.isRequired,
    setWizard: PropTypes.func.isRequired,
    setNewUser: PropTypes.func.isRequired,
};