import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getSignupForm} from "../../services/signup";
import {signupUser} from '../../services/signup-user';
import PasswordStrengthBar from 'react-password-strength-bar';
import {Dialog, Transition} from "@headlessui/react";
import ScaleLoader from "react-spinners/ScaleLoader";

export default function Signup({setErrorMessage}) {
    const [signup, setSignup] = useState({});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState(false);
    const [agree, setAgree] = useState(false);
    const [loading, setLoading ] =useState(true)

    let {id} = useParams();

    useEffect(() => {
        let mounted = true;
        getSignupForm(id)
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setSignup(resp.body);
                        setFirstName(resp.body.firstName);
                        setLastName(resp.body.lastName);
                        setLoading(false);
                    }
                }
            });
        return () => mounted = false;
    }, [id]);

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")
            const {status} = await signupUser(id, {
                firstName,
                lastName,
                email,
                password,
                confirm,
                agree,
            });

            if (status === 201) {
                window.location.href = `/signup/` + id + `/success`
            } else {
                setErrorMessage("Unable to sign up")
            }
        } catch (error) {
            setErrorMessage("Unable to sign up")
        }
    };

    const handleEmailChange = e => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(e.target.value)) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }

        setEmail(e.target.value)
    };

    return loading ?
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </div>
        :
        <>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-normal tracking-tight text-gray-900">Hello, {signup.firstName}!</h2>
                <p className="mt-6 text-center text-sm font-normal tracking-tight text-gray-900">To get started with
                    Votria, we just need you to provide an email address and password for logging in. Once we've
                    confirmed your email, you can invite the company shareholders to join!</p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                                First name
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setFirstName(e.target.value)}
                                    value={firstName}
                                    id="firstName"
                                    name="firstName"
                                    type="firstName"
                                    autoComplete="firstName"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                                Last name
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setLastName(e.target.value)}
                                    value={lastName}
                                    id="lastName"
                                    name="lastName"
                                    type="lastName"
                                    autoComplete="lastName"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    // onChange={e => setEmail(e.target.value)}
                                    onChange={handleEmailChange}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setPassword(e.target.value)}
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                />
                            </div>
                            <div className="block text-sm font-medium text-gray-700 mt-4">
                                <PasswordStrengthBar minLength={8} password={password}/>
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    onChange={e => setConfirm(e.target.checked)}
                                    id="confirm"
                                    name="confirm"
                                    type="checkbox"
                                    required
                                    className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                                />
                                <label htmlFor="confirm" className="ml-2 block text-xs text-gray-900">
                                    I am {signup.role}
                                </label>
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    onChange={e => setAgree(e.target.checked)}
                                    id="agree"
                                    name="agree"
                                    type="checkbox"
                                    required
                                    className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                                />
                                <label htmlFor="agree" className="ml-2 block text-xs text-gray-900">
                                    I agree to the <a href={"/terms"} target={"_blank"} className={"text-orange-700"}>terms and conditions</a>
                                </label>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={firstName.length === 0 || lastName.length === 0 || !validEmail || password.length < 8 || !confirm || !agree}
                                className="flex w-full justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:bg-orange-100"
                            >
                                Sign up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>


}

