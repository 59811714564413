import {useState} from 'react';

export default function useName() {
    const getName = () => {
        const nameString = localStorage.getItem('name');
        try {
            const userName = JSON.parse(nameString);
            return userName
        } catch (e) {
            return false
        }
    };

    const [name, setName] = useState(getName());

    const saveName = userName => {
        localStorage.setItem('name', JSON.stringify(userName));
        setName(userName);
    };

    return {
        setName: saveName,
        name
    }
}
