import getToken from './token'


export function subscribe({planType, quantity}) {
    return fetch(`${process.env.REACT_APP_API}/v1/subscribe/` + planType + '/' + quantity, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}
