/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useEffect, useState} from "react";
import {deleteComplaint, editComplaint, getComplaint, submitNewComment} from "../../../services/complaints";
import {useParams} from "react-router-dom";
import {ExclamationCircleIcon, OfficeBuildingIcon, PencilIcon, TruckIcon} from "@heroicons/react/outline";
import Comment from "../Comments/Comment";
import ScaleLoader from "react-spinners/ScaleLoader";
import {PrimaryButton} from "../../PrimaryButton/PrimaryButton";


export default function ViewComplaint({setErrorMessage, setMobileNavigation}) {

    setMobileNavigation("View Complaint")
    const processString = require('react-process-string');

    const [complaint, setComplaint] = useState({
        complaint: {
            author: false,
            content: "",
        },
        comments: [],
    });
    const [loading, setLoading ] =useState(true)

    const [updatedComplaint, setUpdatedComplaint] = useState("");

    const [editComplaintVisibility, setEditComplaintVisibility] = useState(false);
    const [deleteComplaintVisibility, setDeleteComplaintVisibility] = useState(false);
    let {id} = useParams();

    let config = [{
        regex: /([\w.]+)@(\w+)\.(\w+)/gim,
        fn: (key, result) => <span key={key}>
                                 <a className={"text-blue-700"} target="_blank" rel={"noreferrer"}
                                    href={`mailto:${result[1]}@${result[2]}.${result[3]}`}>{result[1]}@{result[2]}.{result[3]}</a>
                             </span>
    },
        {
            regex: /(http|https):\/\/(\S+)/gim,
            fn: (key, result) => result[0].includes("jpg") ?
                <span key={key}>
                      <img alt="" src={result[0]}/>
                    </span>
                : <span key={key}>
                    <a className={"text-blue-700"} target="_blank" href={result[0]} rel={"noreferrer"}>{result[2]}</a>
                </span>

        },
        {
            regex: /(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
            fn: (key, result) => <span key={key}>
                                 <a className={"text-blue-700"} target="_blank" rel={"noreferrer"}
                                    href={`//${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                             </span>
        }];

    useEffect(() => {
        let mounted = true;
        getComplaint(id)
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setComplaint(resp.body);
                        setLoading(false);
                    }
                }
            });
        return () => mounted = false;
    }, [id]);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await submitNewComment(id, {
                commentID: e.target.elements.commentID.value,
                content: e.target.elements.content.value,
                timestamp: new Date().toISOString(),
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to submit comment")
            }
        } catch (error) {
            setErrorMessage("Unable to submit comment")
        }

    };


    const handleUpdate = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await editComplaint(
                complaint.complaint.id,
                {
                    updatedComplaint,
                    timestamp: new Date().toISOString(),
                }
        );

            if (status === 200) {
                // window.location.href = "/complaints/" + complaint.complaint.id
                setComplaint({
                    ...complaint,
                    complaint: {
                        ...complaint.complaint,
                        content: updatedComplaint,
                    }
                })
            } else {
                setErrorMessage("Unable to edit complaint")
            }
        } catch (error) {
            setErrorMessage("Unable to edit complaint")
        }
    };


    const handleDeleteComplaint = async (e, complaintID) => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {status} = await deleteComplaint({
                complaintID: complaintID,
                timestamp: new Date().toISOString(),
            });

            if (status === 200) {
                setDeleteComplaintVisibility(false);
                // window.location.reload()
            } else {
                setErrorMessage("Unable to resolve complaint")
            }

        } catch (error) {
            setErrorMessage("Unable to resolve complaint")

        }
    };

    return loading ?
        <main className="lg:col-span-12 xl:col-span-6 order-2 xl:order-1">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :<>
        <main className="lg:col-span-12 xl:col-span-6 order-2 xl:order-1">
        <div className="bg-white shadow p-4 sm:p-6 rounded-lg mx-4 sm:mx-0">
                    <div className="flex space-x-3">
                        <div className="min-w-0 flex-1">
                            <p className="text-sm text-gray-500">
                                {complaint.complaint.complaintType === "property" ?
                                    <span>Property complaint regarding&nbsp;<b>{complaint.complaint.label}</b>&nbsp;{new Date(complaint.complaint.date).toLocaleString()} {complaint.complaint.lastEditedAt}</span> :
                                    <span>Vehicle complaint regarding&nbsp;<b>{complaint.complaint.label}</b>&nbsp;{new Date(complaint.complaint.date).toLocaleString()} {complaint.complaint.lastEditedAt}</span>}
                            </p>
                        </div>
                    </div>
                    <h1 className="text-xl font-bold text-gray-900 mb-6 mt-8">{complaint.complaint.title}</h1>

                    {complaint.complaint.isDeleted ?
                        <p className="bg-red-100 text-red-900 p-4">{complaint.complaint.content}</p>
                        :
                        editComplaintVisibility && !complaint.complaint.isResolved ?
                            <form onSubmit={handleUpdate}>
                            <textarea
                                rows={5}
                                name="updatedComplaint"
                                required={true}
                                id="updatedComplaint"
                                defaultValue={complaint.complaint.content}
                                onChange={e => setUpdatedComplaint(e.target.value)}
                                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                            />
                                <div className="mt-2 flex justify-end">
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                                    >
                                        Update
                                    </button>
                                </div>
                            </form>
                            :

                            <>
                                <p className={"whitespace-pre-wrap"}>{processString(config)(complaint.complaint.content)}</p>

                                {complaint.complaint.isAuthor && !complaint.complaint.isResolved ?
                                    <>
                                                <span className="inline-flex text-xs hover:cursor-pointer mt-3"
                                                      onClick={e => setEditComplaintVisibility(!editComplaintVisibility)}>
                                                <PencilIcon className="h-4 w-4 text-gray-500 mr-2"
                                                            aria-hidden="true"/> Edit
                                            </span>
                                    </>
                                    :
                                    <></>}

                            </>
                    }
                    <Transition.Root show={deleteComplaintVisibility} as={Fragment}>
                        <Dialog as="div" className="relative z-10"
                                onClose={e => setDeleteComplaintVisibility(false)}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                            </Transition.Child>

                            <div className="fixed top-0 z-10 overflow-y-auto w-full">
                                <div
                                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel
                                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-4 sm:p-6">
                                            <div>
                                                <div
                                                    className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                                    <ExclamationCircleIcon className="h-6 w-6 text-red-600"
                                                                           aria-hidden="true"/>
                                                </div>
                                                <div className="mt-3 text-center sm:mt-5">
                                                    <Dialog.Title as="h3"
                                                                  className="text-lg font-medium leading-6 text-gray-900">
                                                        Resolve complaint?
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            {complaint.complaint.content}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                <PrimaryButton onClick={e => handleDeleteComplaint(e, complaint.complaint.id)}
                                                >
                                                    Resolve
                                                </PrimaryButton>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                                    onClick={e => setDeleteComplaintVisibility(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>


                    {!complaint.complaint.isResolved ?
                        <form onSubmit={handleSubmit} className="mt-8">
                                    <textarea
                                        rows={5}
                                        name="content"
                                        id="content"
                                        required={true}
                                        className="shadow-sm just focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                                        placeholder="Add your comment..."
                                        defaultValue={''}
                                    />
                            <input type="hidden" id="commentID" name="commentID" value=""/>
                            <div className={"justify-items-end mb-12"}>
                                <PrimaryButton>
                                    Comment
                                </PrimaryButton>
                            </div>
                        </form>

                        :
                        <></>
                    }

                    <ul className="space-y-8 mt-8">
                        {complaint.comments.map(comment => <Comment comment={comment} complaintID={complaint.complaint.id}
                                                                    isResolved={complaint.complaint.isResolved}/>)}

                    </ul>

            </div>
        </main>

        <aside className="sm:mt-4 lg:mt-0 xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2 mb-4">
            {complaint.complaint.canResolve ?
                <div className="sticky top-4 space-y-4">
                    <section aria-labelledby="trending-heading">
                        <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                            <div className="p-4 sm:p-6">
                                {
                                    complaint.complaint.isResolved ?
                                        <div>
                                            This complaint was lodged by <b>{complaint.complaint.author.firstName} {complaint.complaint.author.lastName}</b> and has now been resolved.
                                        </div>
                                        :
                                        <button
                                            onClick={e => setDeleteComplaintVisibility(!deleteComplaintVisibility)}
                                            className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700"
                                        >
                                            Resolve complaint
                                        </button>
                                }
                            </div>
                        </div>
                    </section>
                    {/*another section*/}
                </div>
                :  complaint.complaint.isResolved ?
                <div className="sticky top-4 space-y-4">
                    <section aria-labelledby="trending-heading">
                        <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                            <div className="p-4 sm:p-6">
                                {
                                    <div>
                                        This complaint has now been resolved.
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                    {/*another section*/}
                </div> :
                    <></>
            }
        </aside>

    </>
}
