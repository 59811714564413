import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {confirmSignup} from "../../../services/confirm-signup";
import {BadgeCheckIcon} from "@heroicons/react/outline";

export default function SignupConfirmation() {
    let {id, hash} = useParams();

    useEffect(() => {
        let mounted = true;
        confirmSignup(id, hash)
            .then(resp => {
                if (mounted) {
                }
            });
        return () => mounted = false;
    }, [id, hash]);

    return <>
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="text-orange-500 text-center" style={{fontSize: "4em", fontFamily: `'Fortika', sans-serif`}}>
                    votria
                </div>

            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md text-center mt-8 text-xl">
                Thanks for confirming your email address!
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md text-center mt-8">
                You can now <a href={"/"} className={"text-orange-500 underline"}>login</a>!
            </div>
        </div>
    </>


}

