import React, {useEffect, useState} from "react";
import {getMeeting, uploadFile} from "../../../services/meetings";
import {DocumentTextIcon, UploadIcon, UserGroupIcon} from "@heroicons/react/outline";
import {useParams} from "react-router-dom";
import FileListing from "./FileListing";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ViewMeeting({setErrorMessage, setMobileNavigation}) {
    const [meeting, setMeeting] = useState({
        agenda: [],
        files: [],
        canUpload: false,
    });
    const [selectedFile, setSelectedFile] = useState(null);

    let {id} = useParams();
    setMobileNavigation("View Meeting")
    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {status} = await uploadFile({
                id,
                file: selectedFile,
            });

            if (status === 200) {
                window.location.href = "/meetings/view/" + id
            } else {
                setErrorMessage("Unable to upload file")
            }
        } catch (error) {
            setErrorMessage("Unable to upload file")
        }
    };

    useEffect(() => {
        let mounted = true;
        getMeeting(id)
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setMeeting(resp.body);
                    }
                } else {
                    window.location.href = "/"
                }
            });
        return () => mounted = false;
    }, [id]);

    return <>
        <main className="lg:col-span-8 xl:col-span-6 mb-4">
            <div className="sticky top-4 space-y-4 ">
                <section aria-labelledby="trending-heading">
                    <div className="bg-white rounded-lg shadow">
                        <div className="p-6">
                            <div id="trending-heading" className="text-base font-medium text-gray-900">
                                <UserGroupIcon
                                    className={classNames(
                                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                    )}

                                    aria-hidden="true"
                                />
                                <span className="truncate">Meeting on {meeting.date}</span>
                            </div>
                            <div id="trending-heading" className="text-base font-medium text-gray-900 mt-10">
                                <span className="truncate">Agenda</span>
                            </div>
                            <div className="mt-6 flow-root">
                                <ul className="space-y-4">
                                    {meeting.agenda.map(item => <li key={item.id}>
                                        {item.score > 0 ?
                                            <h1 id={item.id}
                                                className="text-md mt-2 font-medium text-gray-900 mb-4">
                                                {item.title} <span
                                                className={"font-light"}>(+{item.score})</span>
                                            </h1> :
                                            <h1 id={item.id}
                                                className="text-md mt-2 font-medium text-gray-900 mb-4">
                                                {item.title} <span
                                                className={"font-light"}>({item.score})</span>
                                            </h1>
                                        }
                                        <p className="mt-4 text-md leading-8 text-gray-800">
                                            {item.content}
                                        </p>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
        <aside className="lg:mt-0 xl:block lg:col-span-4 xl:col-span-4">
            <div className="sticky">
                <section aria-labelledby="trending-heading">
                    <div className="bg-white rounded-lg shadow mb-4">
                        <div className="p-6">
                            <div id="trending-heading" className="text-base font-medium text-gray-900">
                                <DocumentTextIcon
                                    className={classNames(
                                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                    )}

                                    aria-hidden="true"
                                />
                                <span className="truncate">Files</span>
                            </div>
                            <div className="mt-6 flow-root">
                                <ul className="-my-4 divide-y">
                                    {meeting.files.map((file, idx) => <FileListing key={idx} id={id} file={file} canUpload={meeting.canUpload}/>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {meeting.canUpload ?
                    <section>
                        <div className="bg-white rounded-lg shadow">
                            <div className="p-6">
                                <div id="trending-heading" className="text-base font-medium text-gray-900">
                                    <UploadIcon
                                        className={classNames(
                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                        )}

                                        aria-hidden="true"
                                    />
                                    <span className="truncate">Upload File</span>
                                </div>
                            </div>
                            <div className="pl-6 pr-6 pb-6">
                                <form onSubmit={handleSubmit}>
                                    <input onChange={e => setSelectedFile(e.target.files[0])} type="file"
                                           className="file:mr-4 file:py-2 mb-4 file:px-4 bg-white border rounded-br-md rounded-tr-md file:rounded-bl-md file:rounded-tl-md file:border-0 file:font-medium file:bg-orange-500 file:text-white file:hover:cursor-pointer hover:file:bg-orange-600 file-input file-input-bordered file-input-accent w-full max-w-xs"/>
                                    <button disabled={selectedFile === null} type="submit"
                                            className="w-full flex items-center justify-center px-4 py-2 border border-2 border-orange-500 text-base font-medium rounded-md shadow-sm text-orange-600 xl:text-white xl:bg-orange-500 xl:hover:bg-orange-700">
                                        Upload
                                    </button>
                                </form>
                            </div>

                        </div>
                    </section>
                    :
                    <></>
                }
                {/*another section*/}
            </div>
        </aside>

    </>
}
