import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useEffect, useState} from "react";
import {deletePost, editPost, getPost, submitNewComment} from "../../../services/posts";
import {NavLink, useParams} from "react-router-dom";
import {ExclamationCircleIcon, PencilIcon, TrashIcon} from "@heroicons/react/outline";
import Comment from "../Comments/Comment";
import ScaleLoader from "react-spinners/ScaleLoader";
import {PrimaryButton} from "../../PrimaryButton/PrimaryButton";

export default function ViewPost({setErrorMessage, setMobileNavigation}) {

    const processString = require('react-process-string');

    setMobileNavigation("View Post")
    const [post, setPost] = useState({
        post: {
            author: {},
            content: "",
        },
        comments: [],
    });
    const [loading, setLoading ] =useState(true)

    const [updatedPost, setUpdatedPost] = useState("");

    const [editPostVisibility, setEditPostVisibility] = useState(false);
    const [deletePostVisibility, setDeletePostVisibility] = useState(false);
    let {id} = useParams();


    let config = [{
        regex: /([\w.]+)@(\w+)\.(\w+)/gim,
        fn: (key, result) => <span key={key}>
                                 <a className={"text-blue-700"} target="_blank" rel={"noreferrer"}
                                    href={`mailto:${result[1]}@${result[2]}.${result[3]}`}>{result[1]}@{result[2]}.{result[3]}</a>
                             </span>
    },
        {
            regex: /(http|https):\/\/(\S+)/gim,
            fn: (key, result) => result[0].includes("jpg") ?
                <span key={key}>
                      <img alt={""} src={result[0]}/>
                    </span>
                : <span key={key}>
                    <a className={"text-blue-700"} target="_blank" href={result[0]} rel={"noreferrer"}>{result[2]}</a>
                </span>

        },
        {
            regex: /(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
            fn: (key, result) => <span key={key}>
                                 <a className={"text-blue-700"} target="_blank" rel={"noreferrer"}
                                    href={`//${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                             </span>
        }];

    useEffect(() => {
        let mounted = true;
        getPost(id)
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setPost(resp.body);
                        setLoading(false);
                    }
                }
            });
        return () => mounted = false;
    }, [id]);

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")

            const {status} = await submitNewComment(post.post.id, {
                commentID: e.target.elements.commentID.value,
                content: e.target.elements.content.value,
                timestamp: new Date().toISOString(),
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to submit comment")
            }
        } catch (error) {
            setErrorMessage("Unable to submit comment")
        }
    };


    const handleUpdate = async e => {
        e.preventDefault();
        const {status} = await editPost(
            post.post.id,
            {
                content: updatedPost,
                timestamp: new Date().toISOString(),
            }
    );

        if (status === 200) {
            window.location.href = "/posts/" + post.post.id
        }
    };


    const handleDeletePost = async (e, postID) => {
        e.preventDefault();
        const {status} = await deletePost(postID);

        if (status === 200) {
            setDeletePostVisibility(false);
            window.location.reload()
        }
    };

    const localDate = new Date(post.post.date);

    return  loading ?
        <main className="lg:col-span-10 xl:col-span-7">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :<>
            <main className="lg:col-span-12 xl:col-span-8">
                <div className={" bg-white shadow p-4 sm:p-6 rounded-lg mx-4 sm:mx-0"}>
                <h1 className="text-xl font-bold text-gray-900 mb-4">{post.post.title}</h1>
                <NavLink to={`/users/${post.post.author.id}`}>

                    <div className="flex space-x-3 mb-6">

                <div className="flex-shrink-0">
                            {post.post.author.imageURL !== "" ?
                                <img className="h-10 w-10 rounded-full  ring-1 ring-offset-1 ring-gray-500" src={post.post.author.imageURL} alt=""/>
                                :
                                <span className="h-10 w-10 overflow-hidden rounded-full bg-gray-100">
                                                    <svg className="h-10 w-10 rounded-full text-gray-300  ring-1 ring-offset-1 ring-gray-500"
                                                         fill="currentColor" viewBox="0 0 24 24">
                                                      <path
                                                          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                    </svg>
                                                  </span>
                            }
                        </div>
                        <div className="min-w-0 flex-1">
                            <p className="text-sm font-light text-gray-900">
                                <b>{post.post.author.firstName} {post.post.author.lastName}</b>
                            </p>
                            <p className="text-sm text-gray-500">
                                {localDate.toLocaleString()}
                            </p>
                        </div>

                    </div>
            </NavLink>


                    {post.post.isDeleted ?
                        <p className="bg-red-100 text-red-900 p-4">{post.post.content}</p>
                        :
                        editPostVisibility ?
                            <form onSubmit={handleUpdate}>
                            <textarea
                                rows={5}
                                name="updatedPost"
                                id="updatedPost"
                                required={true}
                                defaultValue={post.post.content}
                                onChange={e => setUpdatedPost(e.target.value)}
                                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                            />
                                <div className="mt-2 flex justify-end">
                                    <PrimaryButton>
                                        Update
                                    </PrimaryButton>
                                </div>
                            </form>
                            :

                            <>
                                <p className={"whitespace-pre-wrap"}>{processString(config)(post.post.content)}</p>

                                {post.post.isAuthor ?
                                    <>
                                                <span className="inline-flex text-xs hover:cursor-pointer mt-3"
                                                      onClick={e => setEditPostVisibility(!editPostVisibility)}>
                                                <PencilIcon className="h-4 w-4 text-gray-500 mr-2"
                                                            aria-hidden="true"/> Edit
                                            </span>
                                        <span className="inline-flex text-xs hover:cursor-pointer mt-3"
                                              onClick={e => setDeletePostVisibility(!deletePostVisibility)}>
                                                <TrashIcon className="h-4 w-4 text-gray-500 mr-2 ml-4"
                                                           aria-hidden="true"/> Delete
                                            </span>
                                    </>
                                    :
                                    <></>}

                            </>
                    }
                    <Transition.Root show={deletePostVisibility} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={e => setDeletePostVisibility(false)}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                            </Transition.Child>

                            <div className="fixed top-0 z-10 overflow-y-auto w-full">
                                <div
                                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel
                                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                            <div>
                                                <div
                                                    className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                                    <ExclamationCircleIcon className="h-6 w-6 text-red-600"
                                                                           aria-hidden="true"/>
                                                </div>
                                                <div className="mt-3 text-center sm:mt-5">
                                                    <Dialog.Title as="h3"
                                                                  className="text-lg font-medium leading-6 text-gray-900">
                                                        Delete post?
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            {post.post.content}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                <PrimaryButton
                                                    onClick={e => handleDeletePost(e, post.post.id)}
                                                >
                                                    Delete
                                                </PrimaryButton>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                                    onClick={e => setDeletePostVisibility(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>


                    <form onSubmit={handleSubmit} className="mt-8">
                                    <textarea
                                        rows={5}
                                        name="content"
                                        required={true}
                                        id="content"
                                        className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                                        placeholder="Add your comment..."
                                        defaultValue={''}
                                    />
                        <input type="hidden" id="commentID" name="commentID" value=""/>
                        <div className="mt-2 flex justify-end">
                        <PrimaryButton>
                            Comment
                        </PrimaryButton>
                        </div>
                    </form>


                </div>
                <ul className="space-y-4 mt-8">
                    {post.comments.map(comment => <Comment comment={comment} postID={post.post.id}/>)}

                </ul>
        </main>
    </>
}
