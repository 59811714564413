import getToken from './token'

export function getCases() {
    return fetch(`${process.env.REACT_APP_API}/v1/cases`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function getCase(id) {
    return fetch(`${process.env.REACT_APP_API}/v1/cases/` + id, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitNewCase(casesDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/cases`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(casesDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function submitNewCaseUpdate(id, caseUpdateDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/cases/` + id + '/update', {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(caseUpdateDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function editCaseUpdate(id, caseUpdateDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/cases/` + id + '/update/' + caseUpdateDetails.caseUpdateID, {
        method: 'PATCH',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(caseUpdateDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function deleteCaseUpdate(id, caseUpdateID) {
    return fetch(`${process.env.REACT_APP_API}/v1/cases/` + id + '/update/' + caseUpdateID, {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function editCase(id, overview) {
    return fetch(`${process.env.REACT_APP_API}/v1/cases/` + id, {
        method: 'PATCH',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(overview),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}


export function deleteCase(id) {
    return fetch(`${process.env.REACT_APP_API}/v1/cases/` + id, {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

