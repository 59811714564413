import React, {useState} from 'react';
import {useParams} from "react-router-dom";

async function resetPassword(credentials) {
    return fetch(`${process.env.REACT_APP_API}/v1/reset-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
}

export default function ResetPassword({setErrorMessage}) {
    const [success, setSuccess] = useState(false);
    const [password1, setPassword1] = useState();
    const [password2, setPassword2] = useState();

    let {token} = useParams();

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await resetPassword({
                password1,
                password2,
                token,
            });
            if (status === 200) {
                setSuccess(true);
            } else {
                setErrorMessage("Unable to reset password")
            }
        } catch (error) {
            setErrorMessage("Unable to reset password")
        }
    };

    return <>
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="text-orange-500 text-center" style={{fontSize: "4em", fontFamily: `'Fortika', sans-serif`}}>
                    votria
                </div>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="password1" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setPassword1(e.target.value)}
                                    id="password1"
                                    name="password1"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password2" className="block text-sm font-medium text-gray-700">
                                Repeat password
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setPassword2(e.target.value)}
                                    id="password2"
                                    name="password2"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={success}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                            >
                                Reset password
                            </button>
                        </div>

                        {success ?
                            <div
                                className="flex items-center rounded-md border border-blue-900 text-blue-900 bg-blue-100 text-sm px-4 py-3"
                                role="alert">
                                <p>Your password has been reset, you can now <a href="/login">login</a>!</p>
                            </div> : <div></div>}
                    </form>

                </div>
            </div>
        </div>
    </>
}

