import getToken from './token'


export function getDirectory() {
    return fetch(`${process.env.REACT_APP_API}/v1/directory`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function getSubscription() {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/subscription`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function updateSubscription(subscriptionDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/subscription`, {
        method: 'PATCH',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
        body: JSON.stringify(subscriptionDetails),
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function cancelSubscription() {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/subscription`, {
        method: 'DELETE',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}

export function reactivateSubscription() {
    return fetch(`${process.env.REACT_APP_API}/v1/directory/subscription`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}