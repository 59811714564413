import getToken from './token'

export function getAnnouncements() {
    return fetch(`${process.env.REACT_APP_API}/v1/announcements`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function populateAnnouncements() {
    return fetch(`${process.env.REACT_APP_API}/v1/announcements/new`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))
}