import React, {Fragment, useEffect, useRef, useState} from 'react';
import {
    CogIcon, ExclamationCircleIcon,
    LockClosedIcon,
    OfficeBuildingIcon, PhoneIcon,
    PresentationChartLineIcon,
    TruckIcon,
    UserIcon
} from "@heroicons/react/outline";
import {NavLink, useParams} from "react-router-dom";
import {deleteAccount, getProfile, leaveGroup, promote, revoke, stepDown, submitProfile, requestMFA, doConfirmMFA, disableMFA} from "../../services/profile";
import {Dialog, Transition} from "@headlessui/react";
import bcrypt from "bcryptjs-react";
import {revokeFinances} from "../../services/finances";
import {inviteTenant, removeShareholder, removeTenant} from "../../services/invite";
import ScaleLoader from "react-spinners/ScaleLoader";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Profile({user, setUser, setErrorMessage, setMobileNavigation}) {

    setMobileNavigation("Profile")
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [emailVisible, setEmailVisible] = useState(false);
    const [imageURL, setImageURL] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPasswordHash, setConfirmPasswordHash] = useState(false);
    const [self, setSelf] = useState(false);
    const [localImage, setLocalImage] = useState(null);
    const [properties, setProperties] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [updateProfileVisibility, setUpdateProfileVisibility] = useState(false);
    const [revokeFinancesVisibility, setRevokeFinancesVisibility] = useState(false);
    const [mfaVisibility, setMFAVisibility] = useState(false);
    const [disableMFAVisibility, setDisableMFAVisibility] = useState(false);
    const [deleteAccountVisibility, setDeleteAccountVisibility] = useState(false);
    const [leaveGroupVisibility, setLeaveGroupVisibility] = useState(false);
    const [stepDownVisibility, setStepDownVisibility] = useState(false);
    const [bePromotedVisibility, setBePromotedVisibility] = useState(false);
    const [beRevokedVisibility, setBeRevokedVisibility] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [canRevokeFinancialAccess, setCanRevokeFinancialAccess] = useState(false);
    const [canDeleteAccount, setCanDeleteAccount] = useState(false);
    const [canLeaveGroup, setCanLeaveGroup] = useState(false);
    const [canStepDown, setCanStepDown] = useState(false);
    const [canBePromoted, setCanBePromoted] = useState(false);
    const [canBeRevoked, setCanBeRevoked] = useState(false);
    const [canBeRemoved, setCanBeRemoved] = useState(false);
    const [tenantFirstName, setTenantFirstName] = useState("");
    const [tenantLastName, setTenantLastName] = useState("");
    const [tenantEmail, setTenantEmail] = useState("");
    const [tenantProperty, setTenantProperty] = useState("");
    const [tenants, setTenants] = useState([]);
    const [canInviteTenants, setCanInviteTenants] = useState(false);  // needs to be renamed, this is a check for is this a trial account
    const [qrCode, setQRCode] = useState("");
    const [recoveryPhrase, setRecoveryPhrase] = useState("");
    const [confirmMFA, setConfirmMFA] = useState("");
    const [canConfirmMFA, setCanConfirmMFA] = useState(false);
    const [hasMFA, setHasMFA] = useState(false);
    const [loading, setLoading ] =useState(true)
    const [removeUserVisibility, setRemoveUserVisibility] = useState(false);

    const removeUser = async (e, id) => {
        e.preventDefault();
        const {status} = await removeShareholder({
            id,
        });

        if (status === 200) {
            setRemoveUserVisibility(false);
            window.location.href='/dashboard';
        }
    };

    const handleFileChange = e => {
        setSelectedFile(e.target.files[0]);
        setLocalImage(URL.createObjectURL(e.target.files[0]));
    };
    let {id} = useParams();

    useEffect(() => {
        let mounted = true;
        getProfile(id)
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setFirstName(resp.body.firstName);
                        setLastName(resp.body.lastName);
                        setEmail(resp.body.email);
                        setSelf(resp.body.self);
                        setPassword(resp.body.password);
                        setImageURL(resp.body.imageURL);
                        setProperties(resp.body.properties);
                        setVehicles(resp.body.vehicles);
                        setTenants(resp.body.tenants);
                        setCanRevokeFinancialAccess(resp.body.canRevokeFinancialAccess);
                        setCanDeleteAccount(resp.body.canDeleteAccount);
                        setCanLeaveGroup(resp.body.canLeaveGroup);
                        setCanBePromoted(resp.body.canBePromoted);
                        setCanBeRevoked(resp.body.canBeRevoked);
                        setCanBeRemoved(resp.body.canBeRemoved);
                        setCanStepDown(resp.body.canStepDown);
                        setHasMFA(resp.body.hasMFA);
                        setEmailVisible(resp.body.emailVisibility)
                        setCanInviteTenants(resp.body.canInviteTenants)
                        if (resp.body.self && (user.name !== resp.body.firstName + ' ' + resp.body.lastName || user.email !== resp.body.email || user.imageURL !== resp.body.imageURL)) {
                            setUser({
                                "name": resp.body.firstName + ' ' + resp.body.lastName,
                                "email": resp.body.email,
                                "imageURL": resp.body.imageURL,
                            })
                        }
                        setLoading(false)
                    }
                }
            });
        return () => mounted = false;
    }, [id, setUser]);

    const inputFile = useRef(null);

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click()
    };

    const handlePasswordChange = async e => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordHash(bcrypt.compareSync(e.target.value, password))
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {status} = await submitProfile(id, {
                firstName,
                lastName,
                email,
                selectedFile,
                confirmPassword,
                emailVisible,
            });

            if (status === 200) {
                setConfirmPassword("");
                window.location.href = "/users/" + id
            } else {
                setErrorMessage("Unable to update profile")
            }
        } catch (error) {
            setErrorMessage("Unable to update profile")
        }

    };

    const handleRevokeFinances = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await revokeFinances({
                confirmPassword,
            });

            if (status === 200) {
                setConfirmPassword("");
                window.location.href = "/users/" + id
            } else {
                setErrorMessage("Unable to revoke financial access")
            }
        } catch (error) {
            setErrorMessage("Unable to revoke financial access")
        }

    };

    const handleDeleteAccount = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await deleteAccount({
                confirmPassword,
            });

            if (status === 200) {
                setConfirmPassword("");
                window.location.href = "/logout"
            } else {
                setErrorMessage("Unable to delete account")
            }
        } catch (error) {
            setErrorMessage("Unable to delete account")
        }

    };

    const handleLeaveGroup = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await leaveGroup({
                confirmPassword,
            });

            if (status === 200) {
                setConfirmPassword("");
                window.location.href = "/logout"
            } else {
                setErrorMessage("Unable to leave group")
            }
        } catch (error) {
            setErrorMessage("Unable to leave group")
        }

    };

    const handleStepDown = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await stepDown({
                confirmPassword,
            });

            if (status === 200) {
                setConfirmPassword("");
                window.location.href = "/"
            } else {
                setErrorMessage("Unable to step down")
            }
        } catch (error) {
            setErrorMessage("Unable to step down")
        }

    };

    const handlePromotion = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await promote({
                confirmPassword,
                id,
            });

            if (status === 200) {
                setConfirmPassword("");
                window.location.href = "/"
            } else {
                setErrorMessage("Unable to promote user")
            }
        } catch (error) {
            setErrorMessage("Unable to promote user")
        }

    };

    const handleRevocation = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await revoke({
                confirmPassword,
                id,
            });

            if (status === 200) {
                setConfirmPassword("");
                window.location.href = "/"
            } else {
                setErrorMessage("Unable to revoke user")
            }
        } catch (error) {
            setErrorMessage("Unable to revoke user")
        }

    };

    const handleRemove = async (e, id) => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await removeTenant({
                id,
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to remove tenant")
            }
        } catch (error) {
            setErrorMessage("Unable to remove tenant")
        }

    };

    const handleRemoveUser = async (e, id) => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await removeUser({
                id,
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to remove tenant")
            }
        } catch (error) {
            setErrorMessage("Unable to remove tenant")
        }

    };

    const handleInviteTenant = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await inviteTenant({
                tenantFirstName,
                tenantLastName,
                tenantEmail,
                tenantProperty,
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to invite tenant")
            }
        } catch (error) {
            setErrorMessage("Unable to invite tenant")
        }
    };

    const handleRequestMFA = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {status, body} = await requestMFA({
                password: confirmPassword,
            });

            if (status === 200) {
                setQRCode(body.qrCode)
                setRecoveryPhrase(body.recoveryPhrase)
            } else {
                setErrorMessage("Unable to request MFA")
            }
        } catch (error) {
            setErrorMessage("Unable to request MFA")
        }


    }

    const handleDisableMFA = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {status} = await disableMFA({
                password: confirmPassword,
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to disable MFA")
            }
        } catch (error) {
            setErrorMessage("Unable to disable MFA")
        }

    }

    const handleMFAConfirm = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await doConfirmMFA({
                confirmMFA: confirmMFA,
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to confirm MFA")
            }
        } catch (error) {
            setErrorMessage("Unable to confirm MFA")
        }

    }

    const handleConfirmMFAChange = async e => {
        setConfirmMFA(e.target.value);
        setCanConfirmMFA(e.target.value.length === 6)
    };

    return loading ?
        <main className="lg:col-span-10 xl:col-span-7">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :<main className="lg:col-span-12 xl:col-span-10">
        <div className="sticky top-4 space-y-4 ">


                        {self ?
                            <>
                            <section aria-labelledby="trending-heading">
                                <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                    <div className="p-4 sm:p-6">
                                {/*CURRENT USER PROFILE*/}

                                        <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                    <UserIcon
                                        className={classNames(
                                            'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                        )}

                                        aria-hidden="true"
                                    />
                                    <span className="truncate">My Profile</span>
                                    <div className="space-y-6 sm:space-y-5 mt-4">
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                First name
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <input
                                                    onChange={e => setFirstName(e.target.value)}
                                                    value={firstName}
                                                    type="text"
                                                    required={true}
                                                    name="first-name"
                                                    id="first-name"
                                                    autoComplete="given-name"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="last-name"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                Last name
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <input
                                                    onChange={e => setLastName(e.target.value)}
                                                    value={lastName}
                                                    type="text"
                                                    name="last-name"
                                                    id="last-name"
                                                    required={true}
                                                    autoComplete="given-name"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="photo"
                                                   className="block text-sm font-medium text-gray-700  mb-4">
                                                Photo
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <div className="flex items-center">
                                                    {localImage !== null ?
                                                        <span
                                                            className="h-12 w-12 overflow-hidden rounded-full bg-gray-100 ring-1 ring-offset-1 ring-gray-500">
                                                        <img alt="" src={localImage}/>
                                                  </span>
                                                        :
                                                        imageURL !== "" ?
                                                            <img className="h-12 w-12 rounded-full ring-1 ring-offset-1 ring-gray-500" src={imageURL}
                                                                 alt=""/>
                                                            :
                                                            <span
                                                                className="h-12 w-12 overflow-hidden rounded-full bg-gray-100 ring-1 ring-offset-1 ring-gray-500">
                                                    <svg className="h-full w-full text-gray-300" fill="currentColor"
                                                         viewBox="0 0 24 24">
                                                      <path
                                                          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                    </svg>
                                                  </span>
                                                    }

                                                    <input type='file' id='file' ref={inputFile}
                                                           onChange={handleFileChange} style={{display: 'none'}}/>
                                                    <div className="ml-4 group flex grid-cols-2 gap-2 mt-2">

                                                    <PrimaryButton
                                                        onClick={onButtonClick}>
                                                        Change
                                                    </PrimaryButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                Email
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <input
                                                    onChange={e => setEmail(e.target.value)}
                                                    value={email}
                                                    type="text"
                                                    required={true}
                                                    name="email"
                                                    id="email"
                                                    autoComplete="email"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-4">
                                                Email visibility
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <input
                                                    onChange={e => setEmailVisible(e.target.checked)}
                                                    id="email"
                                                    name="email"
                                                    type="checkbox"
                                                    required={true}
                                                    checked={emailVisible}
                                                    className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                                                />
                                                <label htmlFor="email" className="ml-2 text-sm text-gray-900">
                                                    Make my email address visible to members of this management group
                                                </label>
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="photo"
                                                   className="block text-sm font-medium text-gray-700  mb-4">
                                                Password
                                            </label>
                                            <div className="group flex grid-cols-2 gap-2 mt-2">
                                                <PrimaryButton to={"/change-password"}>
                                                    Change Password
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="photo"
                                                   className="block text-sm font-medium text-gray-700  mb-4">
                                                Multi Factor Auth
                                            </label>
                                            <div className="group flex grid-cols-2 gap-2 mt-2">
                                                {hasMFA ?
                                                    <PrimaryButton onClick={e => setDisableMFAVisibility(!disableMFAVisibility)}>
                                                        Disable MFA
                                                    </PrimaryButton>
                                                    :
                                                    <PrimaryButton onClick={e => setMFAVisibility(!mfaVisibility)}>
                                                        Enable MFA
                                                    </PrimaryButton>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="submit"
                                                   className="block text-sm font-medium text-gray-700  mb-4">
                                                    Save changes
                                            </label>
                                            <div className="group flex grid-cols-2 gap-2 mt-2">
                                                <PrimaryButton onClick={e => setUpdateProfileVisibility(!updateProfileVisibility)}>
                                                    Save
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                </div>
                            </section>

                                {vehicles.length > 0 ?
                                    <section aria-labelledby="trending-heading">
                                        <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                            <div className="p-4 sm:p-6">
                                                <div className="space-y-6 sm:space-y-5 mt-4">
                                                    <TruckIcon
                                                        className={classNames(
                                                            'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                        )}

                                                        aria-hidden="true"
                                                    />
                                                    <span className="truncate">My Vehicles</span>
                                                    <div className="space-y-6 sm:space-y-5 mt-4">
                                                        <ul className={"p-4"}>
                                                            {vehicles.map((p, i) =>
                                                                <li>{p}</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    :
                                    <></>
                                }
                                    {properties.length > 0 ?

                                        <section aria-labelledby="trending-heading">
                                            <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                                <div className="p-4 sm:p-6">
                                        <div className="space-y-6 sm:space-y-5 mt-4 mb-4">

                                            <OfficeBuildingIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">My Properties</span>
                                            <div className="space-y-6 sm:space-y-5 mt-4">
                                                <ul className={"p-4 list-disc"}>
                                                    {properties.map((p, i) =>
                                                        <li
                                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-4">
                                                    {p.name}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>

                                                    {properties.length > 0 && canInviteTenants && tenants.length > 0 ?
                                                        <div className="space-y-6 sm:space-y-5 mt-4">

                                                            <UserIcon
                                                                className={classNames(
                                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                                )}

                                                                aria-hidden="true"
                                                            />
                                                            <span className="truncate">Tenants</span>

                                                            <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5">

                                                            {tenants.map(tenant =>
                                                                <>
                                                                    <label
                                                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-4">
                                                                        {tenant.firstName} {tenant.lastName} ({tenant.propertyName})
                                                                    </label>
                                                                    <PrimaryButton
                                                                            onClick={e => handleRemove(e, tenant.id)}
                                                                   >
                                                                        Remove
                                                                    </PrimaryButton>
                                                                </>
                                                            )}
                                                            </div>



                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                        :
                                        <></>
                                    }

                                {properties.length > 0 && canInviteTenants ?
                                    <section aria-labelledby="trending-heading">
                                        <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                            <div className="p-4 sm:p-6">
                                    <div className="space-y-6 sm:space-y-5 mt-4">
                                        <UserIcon
                                            className={classNames(
                                                'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                            )}

                                            aria-hidden="true"
                                        />
                                        <span className="truncate">Invite tenants</span>

                                        <form onSubmit={handleInviteTenant}>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="last-name"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                    Tenant's first name
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-4">                                                <input
                                                    onChange={e => setTenantFirstName(e.target.value)}
                                                    value={tenantFirstName}
                                                    type="text"
                                                    name="email"
                                                    required={true}
                                                    placeholder="First name"
                                                    id="email"
                                                    autoComplete="email"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                />
                                                </div>
                                            </div>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="last-name"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                    Tenant's last name
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0  mb-4">                                                <input
                                                    onChange={e => setTenantLastName(e.target.value)}
                                                    value={tenantLastName}
                                                    type="text"
                                                    required={true}
                                                    name="email"
                                                    placeholder="Last name"
                                                    id="email"
                                                    autoComplete="email"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                />
                                            </div>
                                            </div>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="last-name"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                    Tenant's email
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0  mb-4">                                                <input
                                                    onChange={e => setTenantEmail(e.target.value)}
                                                    value={tenantEmail}
                                                    type="text"
                                                    name="email"
                                                    placeholder="Email"
                                                    required={true}
                                                    id="email"
                                                    autoComplete="email"
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                />
                                            </div>
                                            </div>
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="last-name"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                    Tenant's property
                                                </label>
                                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-4">                                                <select
                                                    id="tenantProperty"
                                                    name="tenantProperty"
                                                    value={tenantProperty}
                                                    required={true}
                                                    onChange={e => setTenantProperty(e.target.value)}
                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                    defaultValue=""
                                                >
                                                    <option value=""></option>
                                                    {properties.map(property =>
                                                        <option value={property.id}>{property.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                            </div>

                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                <label htmlFor="submit"
                                                       className="block text-sm font-medium text-gray-700">

                                                </label>
                                                <div className="group flex grid-cols-2 gap-2 mt-2">
                                                    <PrimaryButton>
                                                        Invite
                                                    </PrimaryButton>
                                                </div>
                                            </div>



                                        </form>
                                    </div>
                                            </div>
                                        </div>
                                    </section>
                                    :
                                    <></>
                                }




                                    {canRevokeFinancialAccess && canInviteTenants ?
                                        <>
                                        <section aria-labelledby="trending-heading">
                                            <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                                <div className="p-4 sm:p-6">
                                            <PresentationChartLineIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Third party access</span>
                                            <div className="space-y-6 sm:space-y-5 mt-4">
                                                <div
                                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                    <label htmlFor="first-name"
                                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                        Revoke financial access
                                                    </label>
                                                    <div className="group flex grid-cols-2 gap-2 mt-2">
                                                        <PrimaryButton
                                                            onClick={e => setRevokeFinancesVisibility(!revokeFinancesVisibility)}
                                                        >
                                                            Revoke
                                                        </PrimaryButton>
                                                    </div>
                                                </div>
                                            </div>
                                                </div>
                                            </div>
                                        </section>
                                        </>

                                        :
                                        <></>
                                    }

                                    {canLeaveGroup || canDeleteAccount || canStepDown ?
                                        <section aria-labelledby="trending-heading">
                                            <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                                <div className="p-4 sm:p-6">
                                        <div className="space-y-6 sm:space-y-5 mb-4">
                                            <CogIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Account settings</span>
                                            {canStepDown ?
                                                <div className="space-y-6 sm:space-y-5 mt-4">
                                                    <div
                                                        className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                        <label htmlFor="first-name"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                            Step down as director
                                                        </label>
                                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                                            <PrimaryButton
                                                                onClick={e => setStepDownVisibility(!deleteAccountVisibility)}
                                                            >
                                                                Step down
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }

                                            {canLeaveGroup ?
                                                <div className="space-y-6 sm:space-y-5 mt-4">
                                                    <div
                                                        className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                        <label htmlFor="first-name"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                            Leave this group
                                                        </label>
                                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                                            <PrimaryButton
                                                                onClick={e => setLeaveGroupVisibility(!leaveGroupVisibility)}
                                                            >
                                                                Leave
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                            {canDeleteAccount ?
                                                <div className="space-y-6 sm:space-y-5 mt-4">
                                                    <div
                                                        className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                        <label htmlFor="first-name"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2  mb-4">
                                                            Delete my account
                                                        </label>
                                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                                            <PrimaryButton
                                                                onClick={e => setDeleteAccountVisibility(!deleteAccountVisibility)}>
                                                                Delete my account
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }

                                        </div>
                                                </div>
                                            </div>
                                        </section>
                                        :
                                        <></>
                                    }
                            </>
                            :

                            <>
                                <section aria-labelledby="trending-heading">
                                    <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                        <div className="p-4 sm:p-6">
                                {/*OTHER USER PROFILE*/}
                                <div id="trending-heading" className="text-base font-medium text-gray-900">
                                    <UserIcon
                                        className={classNames(
                                            'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                        )}

                                        aria-hidden="true"
                                    />
                                    <span className="truncate">User Profile</span>
                                    <div className="space-y-6 sm:space-y-5 mt-4 mb-8">
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                First name
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                  {firstName}
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="last-name"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Last name
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    {lastName}
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="photo"
                                                   className="block text-sm font-medium text-gray-700 mb-4">
                                                Photo
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <div className="flex items-center">
                                                    {
                                                        imageURL !== "" ?
                                                            <img className="h-12 w-12 rounded-full ring-1 ring-offset-1 ring-gray-500" src={imageURL}
                                                                 alt=""/>
                                                            :
                                                            <span
                                                                className="h-12 w-12 overflow-hidden rounded-full bg-gray-100 ring-1 ring-offset-1 ring-gray-500">
                                                    <svg className="h-full w-full text-gray-300 ring-1 ring-offset-1 ring-gray-500" fill="currentColor"
                                                         viewBox="0 0 24 24">
                                                      <path
                                                          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                    </svg>
                                                  </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Email
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                    {email === "" ? "Email not public": email}
                                            </div>
                                        </div>
                                    </div>


                                    {properties.length > 0 ?

                                        <div className="space-y-6 sm:space-y-5 mt-4 mb-8">

                                            <OfficeBuildingIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Owned Properties</span>
                                            <div className="space-y-6 sm:space-y-5 mt-4">
                                                <ul className={"p-4"}>
                                                    {properties.map((p, i) =>
                                                        <li>{p.name}</li>
                                                    )}
                                                </ul>
                                            </div>

                                        </div>
                                        :
                                        <></>
                                    }

                                    {vehicles.length > 0 ?
                                        <div className="space-y-6 sm:space-y-5 mt-4 mb-8">
                                            <TruckIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Owned Vehicles</span>
                                            <div className="space-y-6 sm:space-y-5 mt-4">
                                                <ul className={"p-4"}>
                                                    {vehicles.map((p, i) =>
                                                        <li>{p}</li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }

                                    {tenants.length > 0 ?
                                        <div className="space-y-6 sm:space-y-5 mt-4">
                                            <UserIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Tenants</span>

                                            {tenants.map(tenant =>
                                                <div className="mt-4 flex grid-cols-1">
                                                    <div>{tenant.firstName} {tenant.lastName} - {tenant.propertyName}</div>
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <></>
                                    }


                                    {canBePromoted || canBeRemoved ?

                                        <div className="space-y-6 sm:space-y-5 mt-4">

                                            <CogIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Account settings</span>
                                            {canBePromoted ?
                                                <div className="space-y-6 sm:space-y-5 mt-4">
                                                    <div
                                                        className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                        <label htmlFor="first-name"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                            Promote shareholder to director
                                                        </label>
                                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                                            <PrimaryButton
                                                                onClick={e => setBePromotedVisibility(!bePromotedVisibility)}>
                                                                Promote
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                            {canBeRevoked ?
                                                <div className="space-y-6 sm:space-y-5 mt-4">
                                                    <div
                                                        className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                        <label htmlFor="first-name"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                            Revoke ownerships
                                                        </label>
                                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                                            <PrimaryButton
                                                                onClick={e => setBeRevokedVisibility(!beRevokedVisibility)}>
                                                                Revoke
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                            {canBeRemoved ?
                                                <div className="space-y-6 sm:space-y-5 mt-4">
                                                    <div
                                                        className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                                                        <label htmlFor="first-name"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                            Remove user
                                                        </label>
                                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                                            <PrimaryButton
                                                                onClick={e => setRemoveUserVisibility(!removeUserVisibility)}>
                                                                Remove
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                        </div>
                                    </div>
                                </section>
                            </>

                        }


            {/*another section*/}
        </div>

        <Transition.Root show={updateProfileVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setUpdateProfileVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                        <LockClosedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-lg font-medium leading-6 text-gray-900">
                                            Enter your password
                                        </Dialog.Title>
                                        <div className="mt-2 m-auto">
                                            <input
                                                onChange={handlePasswordChange}
                                                value={confirmPassword}
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                autoComplete="current-password"
                                                className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <PrimaryButton
                                        disabled={!confirmPasswordHash}
                                        onClick={e => handleSubmit(e)}
                                    >
                                        Update
                                    </PrimaryButton>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={e => setUpdateProfileVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

        <Transition.Root show={revokeFinancesVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setRevokeFinancesVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                        <LockClosedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-lg font-medium leading-6 text-gray-900">
                                            Enter your password
                                        </Dialog.Title>
                                        <div className="mt-2 m-auto">
                                            <input
                                                onChange={handlePasswordChange}
                                                value={confirmPassword}
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                autoComplete="current-password"
                                                className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <PrimaryButton
                                        disabled={!confirmPasswordHash}
                                        onClick={e => handleRevokeFinances(e)}
                                    >
                                        Revoke
                                    </PrimaryButton>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={e => setRevokeFinancesVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>


        <Transition.Root show={deleteAccountVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setDeleteAccountVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                        <LockClosedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-lg font-medium leading-6 text-gray-900">
                                            Enter your password
                                        </Dialog.Title>
                                        <div className="mt-2 m-auto">
                                            <input
                                                onChange={handlePasswordChange}
                                                value={confirmPassword}
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                autoComplete="current-password"
                                                className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <PrimaryButton
                                        disabled={!confirmPasswordHash}
                                        onClick={e => handleDeleteAccount(e)}
                                    >
                                        Delete account
                                    </PrimaryButton>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={e => setDeleteAccountVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>


        <Transition.Root show={leaveGroupVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setLeaveGroupVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                        <LockClosedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-lg font-medium leading-6 text-gray-900">
                                            Enter your password
                                        </Dialog.Title>
                                        <div className="mt-2 m-auto">
                                            <input
                                                onChange={handlePasswordChange}
                                                value={confirmPassword}
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                autoComplete="current-password"
                                                className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <PrimaryButton
                                        disabled={!confirmPasswordHash}
                                        onClick={e => handleLeaveGroup(e)}
                                    >
                                        Leave group
                                    </PrimaryButton>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={e => setLeaveGroupVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>


        <Transition.Root show={bePromotedVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setBePromotedVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                        <LockClosedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-lg font-medium leading-6 text-gray-900">
                                            Enter your password
                                        </Dialog.Title>
                                        <div className="mt-2 m-auto">
                                            <input
                                                onChange={handlePasswordChange}
                                                value={confirmPassword}
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                autoComplete="current-password"
                                                className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <PrimaryButton
                                        disabled={!confirmPasswordHash}
                                        onClick={e => handlePromotion(e)}
                                    >
                                        Promote user to director
                                    </PrimaryButton>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={e => setBePromotedVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>



            <Transition.Root show={beRevokedVisibility} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={e => setBeRevokedVisibility(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <div
                                            className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                            <LockClosedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                        </div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-gray-900">
                                                Enter your password
                                            </Dialog.Title>
                                            <div className="mt-2 m-auto">
                                                <input
                                                    onChange={handlePasswordChange}
                                                    value={confirmPassword}
                                                    type="password"
                                                    name="confirmPassword"
                                                    id="confirmPassword"
                                                    autoComplete="current-password"
                                                    className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <PrimaryButton
                                            disabled={!confirmPasswordHash}
                                            onClick={e => handleRevocation(e)}
                                        >
                                            Revoke property and vehicle ownerships for this user
                                        </PrimaryButton>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={e => setBeRevokedVisibility(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>



        <Transition.Root show={stepDownVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setStepDownVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                        <LockClosedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-lg font-medium leading-6 text-gray-900">
                                            Enter your password
                                        </Dialog.Title>
                                        <div className="mt-2 m-auto">
                                            <input
                                                onChange={handlePasswordChange}
                                                value={confirmPassword}
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                autoComplete="current-password"
                                                className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <PrimaryButton
                                        disabled={!confirmPasswordHash}
                                        onClick={e => handleStepDown(e)}
                                    >
                                        Step down as director
                                    </PrimaryButton>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={e => setStepDownVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>


            <Transition.Root show={removeUserVisibility} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={e => setRemoveUserVisibility(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed top-0 z-10 overflow-y-auto w-full">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <div
                                            className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                            <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                                        </div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Remove user?
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    {firstName} {lastName}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <PrimaryButton
                                            onClick={e => removeUser(e, id)}
                                        >
                                            Delete
                                        </PrimaryButton>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={e => setRemoveUserVisibility(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>


        <Transition.Root show={mfaVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setMFAVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                {qrCode === "" ?
                                    <>
                                    <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                        <PhoneIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-lg font-medium leading-6 text-gray-900">
                                            Enter your password
                                        </Dialog.Title>
                                        <div className="mt-2 m-auto">
                                            <input
                                                onChange={handlePasswordChange}
                                                value={confirmPassword}
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                autoComplete="current-password"
                                                className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                    <div
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <PrimaryButton
                                            disabled={!confirmPasswordHash}
                                            onClick={e => handleRequestMFA(e)}
                                        >
                                            Enable MFA
                                        </PrimaryButton>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={e => setMFAVisibility(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    </>
                                    :
                                    <>
                                        <div
                                            className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                            <PhoneIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                        </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3">
                                        <div className={"m-2"}>Write down this recovery phrase, you will need if you lose access to your authenticator:</div>

                                        <div className={"mt-4 mb-4 ml-2 mr-2 font-bold"}>{recoveryPhrase}</div>
                                        <div className={"m-2"}>Scan the QR code below into your authenticator app:</div>
                                        <img alt="" className={"m-auto mt-4 mb-4"} src={`data:image/png;base64,${qrCode}`}></img>
                                        <div className={"m-2"}>Enter the TOTP from your authenticator app to enable MFA:</div>
                                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3">
                                            <input
                                                onChange={handleConfirmMFAChange}
                                                value={confirmMFA}
                                                type="text"
                                                className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
                                            />
                                        </div>
                                        <div
                                            className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                            <PrimaryButton
                                                disabled={!canConfirmMFA}
                                                onClick={e => handleMFAConfirm(e)}
                                            >
                                                Confirm
                                            </PrimaryButton>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0"
                                                onClick={e => setMFAVisibility(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                        </>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>


        <Transition.Root show={disableMFAVisibility} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={e => setDisableMFAVisibility(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                        <LockClosedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-lg font-medium leading-6 text-gray-900">
                                            Enter your password
                                        </Dialog.Title>
                                        <div className="mt-2 m-auto">
                                            <input
                                                onChange={handlePasswordChange}
                                                value={confirmPassword}
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                autoComplete="current-password"
                                                className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <PrimaryButton
                                        disabled={!confirmPasswordHash}
                                        onClick={e => handleDisableMFA(e)}
                                    >
                                        Disable MFA
                                    </PrimaryButton>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={e => setDisableMFAVisibility(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </main>;
}