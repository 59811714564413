import React, {useEffect, useState} from 'react';
import {getCases} from '../../services/cases';
import {NavLink} from 'react-router-dom';
import {BriefcaseIcon, ChatAltIcon, DotsHorizontalIcon, ExclamationCircleIcon} from "@heroicons/react/outline";
import ScaleLoader from "react-spinners/ScaleLoader";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Cases({setMobileNavigation}) {
    const [cases, setCases] = useState({
        canCreate: false,
        cases: [],
    });
    const [loading, setLoading] = useState(true)

    setMobileNavigation("Cases")
    useEffect(() => {
        let mounted = true;
        getCases()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setCases(resp.body);
                    }
                }
                setLoading(false)
            });
        return () => mounted = false;
    }, []);


    return loading ?
        <main className="lg:col-span-6 xl:col-span-7 mb-4">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :
        cases.cases.length > 0 ?
        <>
            <main className="lg:col-span-12 xl:col-span-6 order-2 xl:order-1">
                <div>
                    <ul className="space-y-4">
                        {cases.cases.map(c => <li key={c.id} className="bg-white px-4 py-6 shadow sm:p-6 rounded-lg mx-4 sm:mx-0">
                            <NavLink to={"/cases/" + c.id}
                            >
                                <article aria-labelledby={'question-title-' + c.id}>
                                    <div>

                                        <NavLink to={`/users/${c.author.id}`}>

                                        <div className="flex space-x-3">
                                            <div className="flex-shrink-0">
                                                {c.author.imageURL !== "" ?
                                                    <img className="h-10 w-10 rounded-full ring-1 ring-offset-1 ring-gray-500"
                                                         src={c.author.imageURL}
                                                         alt=""/>
                                                    :
                                                    <span
                                                        className="h-10 w-10 overflow-hidden rounded-full bg-gray-100 ">
                                                <svg className="h-10 w-10 rounded-full text-gray-300 ring-1 ring-offset-1 ring-gray-500"
                                                     fill="currentColor" viewBox="0 0 24 24">
                                                  <path
                                                      d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                </svg>
                                              </span>
                                                }
                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <p className="text-sm font-medium text-gray-900">
                                                    {c.author.firstName} {c.author.lastName}
                                                </p>
                                                <p className="text-sm text-gray-500">
                                                   {new Date(c.date).toLocaleString()}
                                                </p>
                                            </div>
                                        </div>
                                        </NavLink>


                                        <h2 id={'question-title-' + c.id}
                                            className="mt-4 text-base font-medium text-gray-900">
                                            {c.title}
                                        </h2>
                                    </div>
                                    <div
                                        className="mt-2 text-sm text-gray-700 space-y-4"
                                        dangerouslySetInnerHTML={{__html: c.content}}
                                    />
                                    <div className="mt-6 flex justify-between space-x-8">
                                        <div className="flex space-x-6">

                                    <span className="inline-flex items-center text-sm">
                        <ChatAltIcon className="h-5 w-5 text-gray-500 mr-2" aria-hidden="true"/>
                        <span className="font-medium text-gray-500">{c.caseUpdateCount}</span>
                    </span>
                                        </div>
                                    </div>
                                </article>
                            </NavLink>
                        </li>)}
                    </ul>
                </div>
            </main>

            {cases.canCreate ?
                <aside className="mb-4 lg:mt-0 xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2">
                    <div className="sticky top-4 space-y-4">
                        <section aria-labelledby="trending-heading">
                            <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                                <div className="p-4 sm:p-6">
                                    <PrimaryButton to={"/cases/new"}>
                                        Open a case
                                    </PrimaryButton>
                                </div>
                            </div>
                        </section>
                        {/*another section*/}
                    </div>
                </aside>

                :

                <></>
            }

        </>
        :
            <main className="lg:col-span-12 xl:col-span-8">
                <div className="w-2/3 m-auto text-center items-center text-4xl mt-12">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="text-gray-300 w-32 m-auto">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div className={"mt-16 mb-32 text-gray-300 font-medium"}>
                        Nothing to see here
                    </div>
                {cases.canCreate ?
                    <PrimaryButton to={"/cases/new"}>
                        Open a case
                    </PrimaryButton>
                    :
                    <></>
                }
            </div>
        </main>
}

