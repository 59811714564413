import React, {useEffect, useState} from 'react';
import {editMeeting, getMeetings, requestEGM, scheduleMeeting} from '../../services/meetings';
import {NavLink} from 'react-router-dom';
import {CalendarIcon, ClockIcon, DotsHorizontalIcon, PencilIcon, UserGroupIcon} from "@heroicons/react/outline";
import AgendaItem from "./AgendaItem";
import Datepicker from "react-tailwindcss-datepicker";
import {formatWithOptions} from 'date-fns/fp';
import moment from 'moment-timezone';
import ScaleLoader from "react-spinners/ScaleLoader";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Meetings({setErrorMessage, setMobileNavigation}) {
    const [meetings, setMeetings] = useState({
        agenda: [],
        nextMeeting: {
            date: "",
            time: "",
            hour: 0,
            minute: 0,
            location: "",
            url: "",
            datePlaceholder: "",
            meetingType: "",
            id: "",
        },
        timeUntilNextMeeting: "",
        canRequestEGM: true,
        canScheduleMeetings: false,
        meetingScheduled: false,
        previousMeetings: [],
        egmRequests: 0,
    });
    const [timezone, setTimezone] = useState(moment.tz.guess());
    const [loading, setLoading] = useState(true);
    setMobileNavigation("Meeting Agenda")
    const handleTimezoneChange = (event) => {
        setTimezone(event.target.value);
    };

    useEffect(() => {
        let mounted = true;
        getMeetings()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        setMeetings(resp.body);
                        if (resp.body.nextMeeting.date !== "") {
                            setValue({startDate: resp.body.nextMeeting.datePlaceholder, endDate: resp.body.nextMeeting.datePlaceholder})
                        }
                        if (resp.body.nextMeeting.hour > 0) {
                            setHour(resp.body.nextMeeting.hour)
                        }
                        if (resp.body.nextMeeting.minute > 0) {
                            setMinute(resp.body.nextMeeting.minute)
                        }
                        if (resp.body.nextMeeting.location !== "") {
                            setLocation(resp.body.nextMeeting.location)
                        }
                        if (resp.body.nextMeeting.url !== "") {
                            setURL(resp.body.nextMeeting.url)
                        }
                        if (resp.body.nextMeeting.meetingType !== "") {
                            setMeetingType(resp.body.nextMeeting.meetingType)
                        }
                        setLoading(false)
                    }
                } else {
                    window.location.href = "/"
                }
            });
        return () => mounted = false;
    }, []);

    const handleRequestEGM = async e => {
        e.preventDefault();

        try {
            setErrorMessage("")

            const {status} = await requestEGM();

            if (status === 200 ){
                setMeetings({...meetings, canRequestEGM: false})
            } else {
                setErrorMessage("Unable to request meeting")
            }
        } catch (error) {
            setErrorMessage("Unable to request meeting")
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
            const {status} = await scheduleMeeting({value, minute, hour, location, url, meetingType, timeZone});

            if( status === 200 ) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to schedule meeting X")
            }
        } catch (error) {
            setErrorMessage("Unable to schedule meeting Y")
        }
    };

    const handleEdit = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")
            const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
            const {status} = await editMeeting(meetings.nextMeeting.id, {
                value,
                minute: minute.toString(),
                hour: hour.toString(),
                location,
                url,
                meetingType,
                timeZone
            });

            if (status === 200) {
                window.location.reload()
            } else {
                setErrorMessage("Unable to edit meeting")
            }
        } catch (error) {
            setErrorMessage("Unable to edit meeting")
        }
    };

    const [value, setValue] = useState({});
    const [minute, setMinute] = useState(0);
    const [hour, setHour] = useState(0);
    const [location, setLocation] = useState("");
    const [url, setURL] = useState("");
    const [meetingType, setMeetingType] = useState("AGM");
    const [editing, setEditing] = useState(false);

    const handleValueChange = newValue => {
        // const dateToString = formatWithOptions({}, 'd MMMM yyyy');
        // const start = new Date(newValue.startDate);
        // const end = new Date(newValue.endDate);
        // setValue({startDate: dateToString(start), endDate: dateToString(end)});
        setValue(newValue);
    };

    return loading ?
        <main className="lg:col-span-7 xl:col-span-7">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center mt-16">
                    <ScaleLoader
                        color={"#ff6600"}
                        loading={loading}
                        size={100}
                    />
                </div>
            </div>
        </main>
        :<>

            {meetings.agenda.length > 0 ?
                <main className="lg:col-span-12 xl:col-span-6  order-2 xl:order-1">
                    <div className="sticky top-4 space-y-4 mb-4">
                        <section aria-labelledby="trending-heading">
                            <div className="flow-root">
                                <ul className="space-y-4">
                                    {meetings.agenda.map((post, idx) => <li key={idx} className={" bg-white rounded-lg shadow mx-4 sm:mx-0 px-4 py-2 rounded"}>
                                        <AgendaItem post={post}/>
                                    </li>)}
                                </ul>
                            </div>
                        </section>
                    </div>
                </main>
                :
                <main className="lg:col-span-12 xl:col-span-6">
                    <div className="w-2/3 m-auto text-center items-center text-4xl mt-12 hidden xl:block">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="text-gray-300 w-32 m-auto">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div className={"mt-16 mb-32 text-gray-300 font-medium "}>
                            Nothing to see here
                        </div>
                    </div>
                </main>            }
        <aside className="sm:mt-4 lg:mt-0 xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2 mb-4">
            <div className="sticky top-4 space-y-4">
                <section aria-labelledby="trending-heading">
                    <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                        <div className="p-4 sm:p-6">
                            <PrimaryButton to={"/meetings/new-agenda-item"}>
                                New agenda item
                            </PrimaryButton>
                        </div>
                    </div>
                </section>
                <section aria-labelledby="trending-heading">
                    <div className="bg-white rounded-lg shadow mx-4 sm:mx-0">
                        <div className="p-4 sm:p-6">
                            {meetings.canScheduleMeetings ?

                                meetings.meetingScheduled ?

                                    editing ?
                                        <form onSubmit={handleEdit}>
                                            <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                            <CalendarIcon
                                                    className={classNames(
                                                        'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                    )}

                                                    aria-hidden="true"
                                                />
                                                <span className="truncate">Schedule a meeting</span>
                                            </div>
                                            <div className="mt-6 flow-root text-gray-900">
                                                <Datepicker
                                                    value={value}
                                                    onChange={handleValueChange}
                                                    useRange={false}
                                                    asSingle={true}
                                                    displayFormat={"DD MMMM YYYY"}
                                                    primaryColor={"orange"}
                                                    inputClassName="font-normal text-gray-900 "
                                                    minDate={new Date()}
                                                    required={true}
                                                    placeholder={meetings.nextMeeting.datePlaceholder}
                                                />
                                            </div>
                                            <div className="group flex grid-cols-2 gap-2 mt-2">
                                                <select
                                                    id="hour"
                                                    name="hour"
                                                    value={hour}
                                                    placeholder={"Hour"}
                                                    required={true}
                                                    onChange={e => setHour(e.target.value)}
                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                                    defaultValue={meetings.nextMeeting.hour}
                                                >
                                                    <option value={0}>00</option>
                                                    <option value={1}>01</option>
                                                    <option value={2}>02</option>
                                                    <option value={3}>03</option>
                                                    <option value={4}>04</option>
                                                    <option value={5}>05</option>
                                                    <option value={6}>06</option>
                                                    <option value={7}>07</option>
                                                    <option value={8}>08</option>
                                                    <option value={9}>09</option>
                                                    <option value={10}>10</option>
                                                    <option value={11}>11</option>
                                                    <option value={12}>12</option>
                                                    <option value={13}>13</option>
                                                    <option value={14}>14</option>
                                                    <option value={15}>15</option>
                                                    <option value={16}>16</option>
                                                    <option value={17}>17</option>
                                                    <option value={18}>18</option>
                                                    <option value={19}>19</option>
                                                    <option value={20}>20</option>
                                                    <option value={21}>21</option>
                                                    <option value={22}>22</option>
                                                    <option value={23}>23</option>
                                                </select>
                                                <select
                                                    id="minute"
                                                    name="minute"
                                                    value={minute}
                                                    required={true}
                                                    onChange={e => setMinute(e.target.value)}
                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                                    defaultValue={meetings.nextMeeting.minute}
                                                >
                                                    <option value={0}>00</option>
                                                    <option value={5}>05</option>
                                                    <option value={10}>10</option>
                                                    <option value={15}>15</option>
                                                    <option value={20}>20</option>
                                                    <option value={25}>25</option>
                                                    <option value={30}>30</option>
                                                    <option value={35}>35</option>
                                                    <option value={40}>40</option>
                                                    <option value={45}>45</option>
                                                    <option value={50}>50</option>
                                                    <option value={55}>55</option>
                                                </select>
                                            </div>
                                            <div className="group flex grid-cols-1 gap-2 mt-2">
                                                <select value={timezone} onChange={handleTimezoneChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm">
                                                    {moment.tz.names().map((tz) => (
                                                        <option key={tz} value={tz}>
                                                            {tz}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <select
                                                id="meeting"
                                                name="meeting"
                                                value={meetingType}
                                                required={true}
                                                onChange={e => setMeetingType(e.target.value)}
                                                className="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                                defaultValue="AGM"
                                            >
                                                <option>AGM</option>
                                                <option>EGM</option>
                                                <option>Meeting</option>
                                            </select>
                                            <textarea
                                                rows={3}
                                                name="location"
                                                id="location"
                                                value={location}
                                                required={true}
                                                onChange={e => setLocation(e.target.value)}
                                                className="mt-4 shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                                                placeholder="Meeting location"
                                            />
                                            <textarea
                                                rows={1}
                                                name="url"
                                                id="url"
                                                value={url}
                                                onChange={e => setURL(e.target.value)}
                                                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                                                placeholder="Web conferencing URL"
                                            />
                                            <div className="mt-4">
                                                <div className={"mb-4"}>
                                                <PrimaryButton onClick={e => setEditing(!editing)}>
                                                    Cancel edit
                                                </PrimaryButton>
                                                </div>
                                                <PrimaryButton>
                                                    Update schedule
                                                </PrimaryButton>
                                            </div>
                                        </form>
                                        :
                                        <>
                                            <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                            <CalendarIcon
                                                    className={classNames(
                                                        'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                    )}

                                                    aria-hidden="true"
                                                />
                                                <span className="truncate">Next meeting</span>
                                            </div>
                                            <div className="mt-6 flow-root">
                                                {meetings.nextMeeting.date}
                                            </div>
                                            <div className="mt-2 flow-root">
                                                {meetings.nextMeeting.time}
                                            </div>
                                            <div className="mt-2 flow-root">
                                                {meetings.nextMeeting.location}
                                            </div>
                                            <div className="mt-2 flow-root">
                                                <a href={meetings.nextMeeting.url} target="_blank" rel={"noreferrer"}
                                                   className="text-blue-700">{meetings.nextMeeting.url}</a>
                                            </div>
                                            <span className="inline-flex text-xs hover:cursor-pointer mt-6"
                                                  onClick={e => setEditing(!editing)}>
                                            <PencilIcon className="h-4 w-4 text-gray-500 mr-2" aria-hidden="true"/> Edit
                                        </span>
                                        </>
                                    :
                                    <form onSubmit={handleSubmit}>
                                        <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                            <CalendarIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Schedule a meeting</span>
                                        </div>
                                        {meetings.egmRequests > 0 ?
                                            <div className={`text-red-600 hover:bg-red-200 overflow-x-hidden group flex items-center px-3 py-2 text-sm font-medium rounded-md  bg-red-100 rounded-md mt-6 flow-root border-red-200`}>{meetings.egmRequests} shareholders have requested an EGM</div>
                                            :
                                            <></>
                                        }
                                        <div className="mt-6 flow-root">
                                            <Datepicker
                                                value={value}
                                                onChange={handleValueChange}
                                                useRange={false}
                                                asSingle={true}
                                                required={true}
                                                displayFormat={"DD/MM/YYYY"}
                                                primaryColor={"orange"}
                                                inputClassName="font-normal text-gray-900 "
                                                minDate={new Date()}
                                            />
                                        </div>
                                        <div className="group flex grid-cols-2 gap-2 mt-2">
                                            <select
                                                id="hour"
                                                name="hour"
                                                value={hour}
                                                required={true}
                                                placeholder={"hour"}
                                                onChange={e => setHour(e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                                defaultValue={0}
                                            >
                                                <option value={0}>00</option>
                                                <option value={1}>01</option>
                                                <option value={2}>02</option>
                                                <option value={3}>03</option>
                                                <option value={4}>04</option>
                                                <option value={5}>05</option>
                                                <option value={6}>06</option>
                                                <option value={7}>07</option>
                                                <option value={8}>08</option>
                                                <option value={9}>09</option>
                                                <option value={10}>10</option>
                                                <option value={11}>11</option>
                                                <option value={12}>12</option>
                                                <option value={13}>13</option>
                                                <option value={14}>14</option>
                                                <option value={15}>15</option>
                                                <option value={16}>16</option>
                                                <option value={17}>17</option>
                                                <option value={18}>18</option>
                                                <option value={19}>19</option>
                                                <option value={20}>20</option>
                                                <option value={21}>21</option>
                                                <option value={22}>22</option>
                                                <option value={23}>23</option>
                                            </select>
                                            <select
                                                id="minute"
                                                name="minute"
                                                value={minute}
                                                required={true}
                                                onChange={e => setMinute(e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                                defaultValue={0}
                                            >
                                                <option value={0}>00</option>
                                                <option value={5}>05</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                                <option value={25}>25</option>
                                                <option value={30}>30</option>
                                                <option value={35}>35</option>
                                                <option value={40}>40</option>
                                                <option value={45}>45</option>
                                                <option value={50}>50</option>
                                                <option value={55}>55</option>
                                            </select>
                                        </div>
                                        <select
                                            id="meeting"
                                            name="meeting"
                                            value={meetingType}
                                            required={true}
                                            onChange={e => setMeetingType(e.target.value)}
                                            className="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                                            defaultValue="AGM"
                                        >
                                            <option>AGM</option>
                                            <option>EGM</option>
                                            <option>Meeting</option>
                                        </select>
                                        <textarea
                                            rows={3}
                                            name="location"
                                            id="location"
                                            value={location}
                                            required={true}
                                            onChange={e => setLocation(e.target.value)}
                                            className="mt-4 shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                                            placeholder="Meeting location"
                                        />
                                        <textarea
                                            rows={1}
                                            name="url"
                                            id="url"
                                            value={url}
                                            onChange={e => setURL(e.target.value)}
                                            className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                                            placeholder="Web conferencing URL"
                                        />
                                        <div className="mt-4">
                                            <PrimaryButton>
                                                Schedule
                                            </PrimaryButton>
                                        </div>
                                    </form>
                                :

                                meetings.meetingScheduled ?
                                    <>
                                        <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                            <CalendarIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Next meeting</span>
                                        </div>
                                        <div className="mt-6 flow-root">
                                            {meetings.nextMeeting.date}
                                        </div>
                                        <div className="mt-2 flow-root">
                                            {meetings.nextMeeting.time}
                                        </div>
                                        <div className="mt-2 flow-root">
                                            {meetings.nextMeeting.location}
                                        </div>
                                        <div className="mt-2 flow-root">
                                            <a href={meetings.nextMeeting.url} target="_blank" rel={"noreferrer"}
                                               className="text-blue-700">{meetings.nextMeeting.url}</a>
                                        </div>
                                    </>

                                    :
                                    <>
                                        <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                            <CalendarIcon
                                                className={classNames(
                                                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                                )}

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">Next meeting</span>
                                        </div>
                                        <div className="mt-6 flow-root">
                                            {meetings.timeUntilNextMeeting}
                                        </div>

                                        {meetings.canRequestEGM ?
                                            <div className="mt-6">
                                                <PrimaryButton onClick={e => handleRequestEGM(e)}>
                                                    Request an EGM
                                                </PrimaryButton>
                                            </div>
                                            :
                                            <div className="mt-6">
                                                <PrimaryButton disabled={true}>
                                                    EGM requested
                                                </PrimaryButton>
                                            </div>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </section>

                {meetings.previousMeetings.length > 0 ?
                    <section aria-labelledby="trending-heading">
                        <div className="bg-white rounded-lg shadow">
                            <div className="p-4 sm:p-6">
                                <div id="trending-heading" className="font-normal text-xl sm:font-medium text-gray-900">
                                    <ClockIcon
                                        className={classNames(
                                            'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                                        )}

                                        aria-hidden="true"
                                    />
                                    <span className="truncate">Previous meetings</span>
                                </div>
                                <div>
                                    <div className="mt-6 flow-root">
                                        <ul className="-my-4 divide-y">
                                            {meetings.previousMeetings.map((previousMeeting, idx) => <li key={idx} className="flex py-4 space-x-3">
                                                <NavLink to={`/meetings/view/${previousMeeting.id}`}>
                                                    <div className="min-w-0 flex-1">
                                                        <p className="text-sm font-medium text-gray-800">{new Date(previousMeeting.date).toLocaleString()}</p>
                                                    </div>
                                                </NavLink>
                                            </li>)}
                                        </ul>
                                    </div>
                                    <div className="mt-6">
                                        <PrimaryButton to={"/meetings/view-all"}>
                                            View all
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <></>
                }
            </div>
        </aside>
    </>
}

