import getToken from './token'

export function confirmSubscription(session) {
    return fetch(`${process.env.REACT_APP_API}/v1/subscribe/confirm/` + session, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

export function populateSubscription() {
    return fetch(`${process.env.REACT_APP_API}/v1/subscribe`, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}

