import {useEffect} from 'react';
import {getMeetingFile} from '../../services/meetings';
import {useParams} from 'react-router-dom';

export default function FileViewer() {
    let {meetingID, fileID, fileName} = useParams();

    useEffect(() => {
        let mounted = true;
        getMeetingFile(meetingID, fileID, fileName)
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        // setFile(resp.body);
                    }
                }
            });
        return () => mounted = false;
    }, [meetingID, fileID, fileName])


}

