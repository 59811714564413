export function confirmSignup(id, hash) {
    return fetch(`${process.env.REACT_APP_API}/v1/signup/confirm/` + id + '/' + hash, {
        method: 'GET',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(r => r.json().then(data => ({status: r.status, body: data})))

}
