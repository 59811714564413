import React from 'react';
import { NavLink } from 'react-router-dom';

export function PrimaryButton({ to, onClick, children, disabled }) {
    // If onClick is provided, render a button, otherwise render a NavLink
    if (onClick) {
        return (
            <button
                onClick={onClick}
                disabled={disabled}
                className="w-full flex items-center justify-center px-4 py-2 border border-2 border-orange-500 text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700 disabled:bg-orange-300 disabled:border-orange-300"
            >
                {children}
            </button>
        );
    }

    if (to) {
       return ( <NavLink
            to={to}
            disabled={disabled}
            className="w-full flex items-center justify-center px-4 py-2 border border-2 border-orange-500 text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700 disabled:bg-orange-300 disabled:border-orange-300"
        >
            {children}
        </NavLink>)
    }

    return (<button
        type="submit"
        disabled={disabled}
        className="w-full flex items-center justify-center px-4 py-2 border border-2 border-orange-500 text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700 disabled:bg-orange-300 disabled:border-orange-300"
    >
        {children}
    </button>
    );
}
