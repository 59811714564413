import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useEffect, useState} from "react";
import {submitNewAnnouncement} from "../../../services/posts";
import {LockClosedIcon, NewspaperIcon, SpeakerphoneIcon} from "@heroicons/react/outline";
import {populateAnnouncements} from "../../../services/announcements";
import bcrypt from "bcryptjs-react";
import {PrimaryButton} from "../../PrimaryButton/PrimaryButton";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NewAnnouncement({setErrorMessage, setMobileNavigation}) {
    const [title, setTitle] = useState("");
    const [audience, setAudience] = useState("restricted");
    const [content, setContent] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPasswordHash, setConfirmPasswordHash] = useState(false);
    const [sendEmailVisibility, setSendEmailVisibility] = useState(false);
    setMobileNavigation("New Announcement")
    useEffect(() => {
        let mounted = true;
        populateAnnouncements()
            .then(resp => {
                if (resp.status === 200) {
                    if (mounted) {
                        if (resp.body.canAnnounce === false) {
                            window.location.href = '/announcements'
                        }
                        setPassword(resp.body.password)
                    }
                }
            });
        return () => mounted = false;
    }, []);

    const handlePasswordChange = async e => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordHash(bcrypt.compareSync(e.target.value, password));
    };

    const handleSubmit2 = async e => {
        e.preventDefault();
        setSendEmailVisibility(true)
    }
    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setErrorMessage("")

            const {status} = await submitNewAnnouncement({
                title,
                audience,
                content,
                password: confirmPassword,
                timestamp: new Date().toISOString(),
            });

            if (status === 200) {
                setConfirmPassword("");
                window.location.href = "/announcements"
            } else {
                setErrorMessage("Unable to create announcement")
            }
        } catch (error) {
            setErrorMessage("Unable to create announcement")
        }

    };

    return <>
        <main className="lg:col-span-12 xl:col-span-6 bg-white shadow p-4 sm:p-6 rounded-lg mx-4 sm:mx-0  order-2 xl:order-1">
            <div id="trending-heading" className="hidden sm:block font-normal text-xl sm:font-medium text-gray-900">
            <NewspaperIcon
                className={classNames(
                    'hidden sm:block flex-shrink-0 -ml-1 mr-3 h-6 w-6 float-left'
                )}

                aria-hidden="true"
            />
            <span className="truncate">Make an announcement</span>
        </div>
        <div className="mt-2 sm:mt-6">
            <form onSubmit={handleSubmit2}>
            <textarea
                rows={1}
                name="title"
                id="title"
                value={title}
                required={true}
                onChange={e => setTitle(e.target.value)}
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                placeholder="Add your title..."
            />
            <select
                id="audience"
                name="audience"
                value={audience}
                required={true}
                onChange={e => setAudience(e.target.value)}
                className="mb-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
            >
                <option value={"restricted"}>To shareholders only</option>
                <option value={"everyone"}>To shareholders and tenants</option>
            </select>
            <textarea
                rows={10}
                name="content"
                id="content"
                value={content}
                required={true}
                onChange={e => setContent(e.target.value)}
                className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                placeholder="Add your content..."
            />
            <div className="mt-2 flex justify-end">
                <PrimaryButton>
                    Make announcement
                </PrimaryButton>
            </div>
            </form>
        </div>

    </main>
    <aside className="mb-2 xl:block lg:col-span-12 xl:col-span-4 order-1 xl:order-2">
        <div className="sticky top-4 space-y-4">
            <section aria-labelledby="trending-heading">
                <div className="bg-white rounded-lg shadow mb-2 sm:mb-4 mx-4 sm:mx-0">
                        <p className={"sm:p-4 p-6"}>
                            Members of this management group will be notified of announcements via email
                        </p>
                </div>
            </section>
        </div>
    </aside>
    <Transition.Root show={sendEmailVisibility} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={e => setSendEmailVisibility(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </Transition.Child>

            <div className="fixed top-0 z-10 w-full m-auto overflow-y-auto">
                <div
                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div>
                                <div
                                    className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                    <LockClosedIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3"
                                                  className="font-medium leading-6 text-gray-900">
                                        Enter your password to broadcast this announcement
                                    </Dialog.Title>
                                    <div className="mt-2 m-auto">
                                        <input
                                            onChange={handlePasswordChange}
                                            value={confirmPassword}
                                            type="password"
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            autoComplete="current-password"
                                            className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="button"
                                    disabled={!confirmPasswordHash}
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-smxl: xl:hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm disabled:bg-orange-300"
                                    onClick={e => handleSubmit(e)}
                                >
                                    Send
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                    onClick={e => setSendEmailVisibility(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>
    </>
}
